import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
    getStudentSessionsAPI,
    getTutorSessionsAPI,
    cancelSessionAPI,
    createSessionAPI
} from "../../services/session";

export const createSession = createAsyncThunk(
    "session/createSession",
    async (sessionData, thunkAPI) => {
        try {
            let response = await createSessionAPI(sessionData);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getStudentSessions = createAsyncThunk(
    "session/getStudentSessions",
    async (tutorId, thunkAPI) => {
        try {
            let response = await getStudentSessionsAPI(tutorId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const getTutorSessions = createAsyncThunk(
    "session/getTutorSessions",
    async (studentId, thunkAPI) => {
        try {
            let response = await getTutorSessionsAPI(studentId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const cancelSession = createAsyncThunk(
    "session/cancelSession",
    async (sessionId, thunkAPI) => {
        try {
            let response = await cancelSessionAPI(sessionId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

const sessionSlice = createSlice({
    name: "session",
    initialState: {
        sessions: [],
        session: null,
        error: null,
        loading: false,
        success: null,
    },

    reducers: {
        clearSession: (state, action) => {
            state.error = null;
            state.loading = false;
            state.success = null;
            state.session = null;
            
        }
    },
    extraReducers: {
        [createSession.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [createSession.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.session = action.payload.session;
            state.success = "Session created successfully";
        },
        [createSession.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.msg;
            state.success = null;
        },
        [getStudentSessions.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [getStudentSessions.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "Sessions fetched successfully";
            state.sessions = action.payload.sessions
        },
        [getStudentSessions.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.msg;
            state.success = null;
        },
        [getTutorSessions.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [getTutorSessions.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "Sessions fetched successfully";
            state.sessions = action.payload.sessions
        },
        [getTutorSessions.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.msg;
            state.success = null;
        },
        [cancelSession.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [cancelSession.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "Session canceled successfully"
            state.session = action.payload.msg;
        },
        [cancelSession.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.msg;
            state.success = null;
        },
    },
});

export const { clearSession } = sessionSlice.actions;

export default sessionSlice.reducer;

