import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { SchoolRounded } from "@mui/icons-material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import CancelIcon from "@mui/icons-material/Cancel";
import { RequestPage } from "@mui/icons-material";
import { Collapse, Typography } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";

const newData = [
  {
    title: "Tutors",
    icon: <SchoolRounded />,
    subItems: [
      { title: "Pending", count: 200, icon: <HourglassEmptyIcon /> , link: "/tutor/pending" },
      { title: "Approved", count: 800, icon: <CheckCircleIcon /> , link: "/tutor/Approved" },
      { title: "Recjected", count: 700, icon: <CancelIcon /> , link: "/tutor/rejected"},
    ],
  },
  {
    title: "Requests",
    icon: <RequestPage />,
    subItems: [
      { title: "Pending", count: 200, icon: <HourglassEmptyIcon /> , link: "/request/pending"},
      { title: "Approved", count: 800, icon: <CheckCircleIcon /> , link: "/request/Approved" },
      { title: "Recjected", count: 700, icon: <CancelIcon />  , link: "/request/rejected"},
    ],
  },
];

export default function TemporaryDrawer() {
    const navigate = useNavigate();
  const [state, setState] = React.useState({
    left: false,
  });
  const [openRequest, setOpenRequest] = React.useState(false);
  const [openTutor, setOpenTutor] = React.useState(false);

  const handleRequestClick = () => {
    setOpenRequest(!openRequest);
  };

  const handleTutorClick = () => {
    setOpenTutor(!openTutor);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, left: open });
  };

  const list = (
    <Box sx={{ width: '100%' }} role="presentation">

      <Box
        bgcolor="#FEF0D6"
        marginTop={5}
        justifyContent="center"
        textAlign="center"
      >
        <Typography variant="h1" color="#123455" fontSize={40} fontWeight={600} >
          Admin Dashboard
        </Typography>
      </Box>

      <List>
        <ListItem button onClick={handleRequestClick}>
          <ListItemIcon>
            <RequestPage />
          </ListItemIcon>
          <ListItemText primary="Requests" />
          {openRequest ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openRequest} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {newData
              .find((item) => item.title === "Requests")
              .subItems.map((subItem, index) => (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={`${subItem.link}`}
                >
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <ListItemText primary={subItem.title} />
                </ListItem>
              ))}
          </List>
        </Collapse>
        <ListItem button onClick={handleTutorClick}>
          <ListItemIcon>
            <SchoolRounded />
          </ListItemIcon>
          <ListItemText primary="Tutors" />
          {openTutor ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={openTutor} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {newData
              .find((item) => item.title === "Tutors")
              .subItems.map((subItem, index) => (
                <ListItem
                  button
                  key={index}
                  component={Link}
                  to={`${subItem.link}`}
                >
                  <ListItemIcon>{subItem.icon}</ListItemIcon>
                  <ListItemText primary={subItem.title} />
                </ListItem>
              ))}
          </List>
        </Collapse>
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "80vh",
        position: "relative", // Add this line
      }}
    >
      <Drawer
        variant="permanent"
        sx={{
          width: '100%',
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            height: "100%",
            position: "absolute", // Add this line
            bottom: 0, // Add this line
            top: 0, // Add this line
            overflow: "auto", // Add this line
          },
        }}
      >
        {list}
      </Drawer>
    </Box>
  );
  
}
