import { Alert, Box, Button, CircularProgress, Dialog, DialogContent, Stack, TextField, Typography, useTheme } from '@mui/material'
import axios from 'axios';
import React, { useState } from 'react'

function ContactUs() {
    const theme = useTheme();
    const intialFormState = {
        loading: false,
        successMsg: '',
        errorMsg: '',
        email: {
            value: '',
            errorMsg: ''
        },
        subject: {
            value: '',
            errorMsg: ''
        },
        message: {
            value: '',
            errorMsg: ''
        }
    }
    const [contactFormData, setContactFormData] = useState(intialFormState)
    const [dialogOpen, setDialogOpen] = useState(false);

    const validateForm = () => {
        let isValid = true;

        // Validate email
        if (!contactFormData.email.value) {
            setContactFormData(prevState => ({
                ...prevState,
                email: {
                    ...prevState.email,
                    errorMsg: 'Email is required'
                }
            }));
            isValid = false;
        } else if (!/\S+@\S+\.\S+/.test(contactFormData.email.value)) {
            setContactFormData(prevState => ({
                ...prevState,
                email: {
                    ...prevState.email,
                    errorMsg: 'Invalid email address'
                }
            }));
            isValid = false;
        }

        // Validate subject
        if (!contactFormData.subject.value) {
            setContactFormData(prevState => ({
                ...prevState,
                subject: {
                    ...prevState.subject,
                    errorMsg: 'Subject is required'
                }
            }));
            isValid = false;
        }

        // Validate message
        if (!contactFormData.message.value) {
            setContactFormData(prevState => ({
                ...prevState,
                message: {
                    ...prevState.message,
                    errorMsg: 'Message is required'
                }
            }));
            isValid = false;
        }

        return isValid;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (validateForm()) {
            // Submit the form data
            setContactFormData(prevState => ({ ...prevState, loading: true }));

            try {
                const response = await axios.post(
                    'https://formsubmit.co/astengeapp@gmail.com',
                    // 'https://formsubmit.co/6f5656d0ac46017f8a89eef547ff33cf',
                    {
                        email: contactFormData.email.value,
                        message: contactFormData.message.value,
                        name: '',
                        subject: contactFormData.subject.value,
                        _captcha: "false"
                    });

                if (response.status === 200) {
                    setContactFormData(prevState => ({
                        ...prevState,
                        loading: false,
                        successMsg: 'We have received your message. We will get back to you soon.',
                        errorMsg: ''
                    }));
                    setDialogOpen(true);
                } else {
                    setContactFormData(prevState => ({
                        ...prevState,
                        loading: false,
                        successMsg: '',
                        errorMsg: 'Your message is not sent. Please try again.'
                    }));
                    setDialogOpen(true);
                }
            } catch (error) {
                setContactFormData(prevState => ({
                    ...prevState,
                    loading: false,
                    successMsg: '',
                    errorMsg: 'Your message is not sent. Please try again.'
                }));
                setDialogOpen(true);
            }
        }
    };

    const handleChange = (field) => (event) => {
        const value = event.target.value;
        setContactFormData(prevState => ({
            ...prevState,
            [field]: {
                value,
                errorMsg: ''
            }
        }));
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
        setContactFormData(intialFormState);
    };

    return (
        <form onSubmit={handleSubmit}>
            <Stack p={2} rowGap={2} justifyContent='center' alignItems={'center'}>
                <TextField
                    variant="standard"
                    size="medium"
                    sx={{
                        background: 'white',
                        borderRadius: '0.4em',
                        padding: '0.5em',
                        border: 'none',
                        width: { xs: '80vw', md: '30vw' },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    placeholder="Email"
                    value={contactFormData.email.value}
                    onChange={handleChange('email')}
                    error={!!contactFormData.email.errorMsg}
                    helperText={contactFormData.email.errorMsg}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
                <TextField
                    variant="standard"
                    size="medium"
                    sx={{
                        background: 'white',
                        borderRadius: '0.4em',
                        padding: '0.5em',
                        border: 'none',
                        width: { xs: '80vw', md: '30vw' },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    placeholder="Subject"
                    value={contactFormData.subject.value}
                    onChange={handleChange('subject')}
                    error={!!contactFormData.subject.errorMsg}
                    helperText={contactFormData.subject.errorMsg}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
                <TextField
                    variant="standard"
                    size="medium"
                    minRows={5}
                    sx={{
                        background: 'white',
                        borderRadius: '0.4em',
                        padding: '0.5em',
                        border: 'none',
                        width: { xs: '80vw', md: '30vw' },
                        '& .MuiOutlinedInput-notchedOutline': {
                            border: 'none',
                        },
                    }}
                    placeholder="Message"
                    value={contactFormData.message.value}
                    onChange={handleChange('message')}
                    error={!!contactFormData.message.errorMsg}
                    helperText={contactFormData.message.errorMsg}
                    InputProps={{
                        disableUnderline: true,
                    }}
                />
                <Button
                    type='submit'
                    disabled={contactFormData.loading}
                    sx={{
                        background: theme.palette.secondary.main,
                        minWidth: '120px',
                        color: theme.palette.primary.main,
                        textTransform: 'capitalize',
                        "&:hover": {
                            background: theme.palette.secondary.dark,
                        }
                    }}
                    disableElevation
                    variant='contained'
                >
                    {contactFormData.loading ? (
                        <CircularProgress color='secondary' size={25} />
                    ) : (
                        <Typography fontWeight={600}>
                            Send Message
                        </Typography>
                    )}
                </Button>
            </Stack>

            <Dialog open={dialogOpen} onClose={handleCloseDialog}>
                <DialogContent>
                    {contactFormData.successMsg && (
                        <Alert severity='success'>
                            {contactFormData.successMsg}
                        </Alert>
                    )}
                    {contactFormData.errorMsg && (
                        <Alert severity='error'>
                            {contactFormData.errorMsg}
                        </Alert>
                    )}
                </DialogContent>
            </Dialog>
        </form>
    );
}

export default ContactUs;
