import React from "react";
import { Modal, Box, Grid, Typography, Avatar, Button, Chip } from "@mui/material";
import { useTheme } from "@emotion/react";

const TutorDetailPage = ({ open, handleClose, data }) => {
  const theme = useTheme();
  console.log(data);
  if (!data) {
    return null;
  }
    return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "white",
          borderRadius: 4,
          maxWidth: 1000,
          mx: "auto",
          mt: "10vh",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height={1}
            >
              <Avatar src={data.userId.profileImage} sx={{ width: 200, height: 200 }} />
            </Box>
          </Grid>
          <Grid item xs={12} md={8}>
            <Typography variant="h4">
              {data.userId.firstName} {data.userId.lastName}
            </Typography>
            <Typography color={"primary"} fontSize={30}>
              University Name
            </Typography>
            <Typography variant="subtitle1">{data.education.universityName}</Typography>
            <Typography color={"primary"} fontSize={30}>
              Experience
            </Typography>
            <Typography variant="body1">{data.experience}</Typography>
            <Typography color={"primary"} fontSize={30}>
              Location
            </Typography>
            <Typography variant="body2">Location: {data.location}</Typography>
            <Typography color={"primary"} fontSize={30}>
              Tutoring Type
            </Typography>
            <Typography variant="body2">
              Tutoring Type: {data.tutoringType.join(", ")}
            </Typography>
            <Typography color={"primary"} fontSize={30}>
              Rate
            </Typography>
            <Typography variant="body2">Rate: ${data.rate}/hour</Typography>
            <Typography color={"primary"} fontSize={30}>
              Availability
            </Typography>
            <Typography variant="body2">Availability: {data.availability}</Typography>
            <Typography color={"primary"} fontSize={30}>
              Current Status
            </Typography>
            <Typography variant="body2">Current Status: {data.currentStatus}</Typography>
            <Typography color={"primary"} fontSize={30}>
              Approved
            </Typography>
            <Typography variant="body2">Approved: {data.approved}</Typography>
            <Typography color={"primary"} fontSize={30}>
              Subjects
            </Typography>
            <Typography variant="body2">Subjects:</Typography>
            <Box sx={{ display: "flex", flexWrap: "wrap" }}>
              {data.subjects.map((subject) => (
                <Chip
                  key={subject}
                  label={subject}
                  sx={{
                    m: 0.5,
                    background: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    fontWeight: 600,
                    fontSize: 15,
                  }}
                />
              ))}
            </Box>
           
            <Button variant="contained" onClick={() => { window.open(data.educationalQualification); }} sx={{ mt: 2 }}>
              Download Educational Qualification
            </Button>
            
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
};

export default TutorDetailPage;
