import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Chip,
  Avatar,
  Modal,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Sidebar from "../sideBar";
import { allPendingRequests } from "../../../state/slices/admin";
import { adminApproveRequest, adminRejectRequest, clearState } from "../../../state/slices/request";
import { useSelector, useDispatch } from "react-redux";
import { GridOverlay } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import Loading from "../../../components/loading/loading";
import RequestDetailPage from "../RequestDetailPage";
import { useNavigate } from "react-router-dom";


const PendingRequestPage = () => {
  const nav = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const { pendingRequests, loading } = useSelector((state) => state.admin);
  const {success,loading:loadingRequest} = useSelector((state) => state.request);
  if(success){
    dispatch(clearState());
    dispatch(allPendingRequests());
  }
  useEffect(() => {
    dispatch(allPendingRequests());
  }, [dispatch]);

  const columns = [
    { field: "studentName", headerName: "Student Name", width: 150 },
    { field: "tutorName", headerName: "Tutor Name", width: 150 },
    { field: "studentPhone", headerName: "Student Phone", width: 150 },
    { field: "tutorPhone", headerName: "Tutor Phone", width: 150 },
    { field: "studentEmail", headerName: "Student Email", width: 200 },
    { field: "tutorEmail", headerName: "Tutor Email", width: 200 },
    { field: "startingTime", headerName: "Starting Time", width: 150 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "tutorRate", headerName: "Tutor Rate", width: 150 },
    { field: "tutoringType",
     headerName: "Tutoring Type",
      width: 200,
      renderCell: (params) => (
        params.row.tutoringType.map((type) => (
          <Chip
            label={type == "IN_PERSON" ? "In Person" : "Online"}
            variant="outlined"
            color="primary"
            size="small"
            sx={{ m: 0.5 }}
          />
      )))
    },
    { field: "adminStatus", headerName: "Admin Status", width: 120 },
    { field: "tutorStatus", headerName: "Tutor Status", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  console.log("Approve");
                  dispatch(adminApproveRequest(params.row.id));
                  
                }}
                disabled={!selectedRow || selectedRow.id !== params.row.id}
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  console.log("Reject");
                  dispatch(adminRejectRequest(params.row.id));
                }}
                disabled={!selectedRow || selectedRow.id !== params.row.id}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  const rows = pendingRequests.map((request) => ({
    id: request._id,
    studentName: `${request.studentId.userId.firstName} ${request.studentId.userId.lastName}`,
    tutorName: `${request.tutorId.userId.firstName} ${request.tutorId.userId.lastName}`,
    studentPhone: request.studentId.userId.phoneNumber,
    tutorPhone: request.tutorId.userId.phoneNumber,
    studentEmail: request.studentId.userId.email,
    tutorEmail: request.tutorId.userId.email,
    startingTime: request.startingTime,
    message: request.message,
    tutorRate: request.tutorId.rate,
    tutoringType: request.tutoringType,
    adminStatus: request.adminApproved,
    tutorStatus: request.status,
  }));


  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          color: "secondary",
        }}
      >
        <Typography variant="h6">No Pending Requests</Typography>
      </Box>
    </GridOverlay>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box p={3}>
          <Typography variant="h4" mb={3}>
            Request Details
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div style={{ height: 600, width: "100%" }}>
              {loading || loadingRequest ? (
                <Loading />
              ) : (
                <DataGrid
                  columns={columns}
                  rows={rows}
                  columnHeaderHeight={60}
                  onCellClick={(params) => {
                    const disabledColumns = ["actions"];
                    if (disabledColumns.includes(params.field)) {
                      return;
                    }
                   
                    setSelectedRow(params.row);
                    handleOpen();
                  }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              )}
            </div>
          </Paper>
        </Box>
      </Grid>
      {selectedRow && <RequestDetailPage open={open} handleClose={handleClose} data={pendingRequests && pendingRequests.find((request) => request._id === selectedRow.id)} />}
    </Grid>
  );
};

export default PendingRequestPage;
