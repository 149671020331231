
export let extractId = (url) => {
    // Check if the URL contains 'drive.google.com'
    if (url.includes('drive.google.com')) {
        // Split the URL by '=' character
        let parts = url.split('=');

        // If the URL has multiple parts separated by '=', take the second part
        let idPart = parts.length > 1 ? parts[1] : url;

        // Split the ID part by '&' character to isolate the ID
        let id = idPart.split('&')[0];

        return id;
    } else {
        // If the URL doesn't contain 'drive.google.com', return it as it is
        return url;
    }
}