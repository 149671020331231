import { DateRange, LocationOn, Search } from '@mui/icons-material';
import { Box, Button, Grid, InputAdornment, MenuItem, Stack, TextField, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { searchTutors , setFilter } from '../../state/slices/search';
import { useDispatch } from 'react-redux';



function Home() {
  let theme = useTheme()
  let navigate = useNavigate()
  let dispatch = useDispatch()
  const images = ['bg1.jpg', 'bg3.jpg', 'bg4.jpeg'];
  const [position, setPosition] = useState(0);
  const [formData, setFormData] = useState({
    subject: [],
    date: '',
    tutoringType: ['REMOTE'],
  });

  let searchHandler = (e) => {
    e.preventDefault()
    console.log(formData)
    dispatch(setFilter({subjects: formData.subject,tutoringOptions:formData.tutoringType }))
    dispatch(searchTutors({subjects: formData.subject,tutoringOptions:formData.tutoringType }))
    navigate('/result')
  }

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setPosition((prev) => (prev + 1) % 3);
  //   }, 10000);
  //   return () => clearInterval(interval);
  // }, []);

  return (
    <Box>

      <Box
        sx={{
          height: '100vh',
          width: '100vw',
          position: 'relative',
          overflow: 'hidden',
          mt: "-4em"
        }}
      >
        {images.map((image, index) => (
          <Box
            key={index}
            sx={{
              // background: `rgba(0,0,0,0.2)`,
              // background: `url('./bg4.jpeg')`,
              filter: 'brightness(60%)',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover',
              backgroundAttachment: 'fixed',
              height: '100vh',
              width: '100vw',
              position: 'absolute',
              top: 0,
              left: 0,
              opacity: position === index ? 1 : 0,
              transition: 'opacity 2s ease-in-out',
            }}
          />
        ))}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            height: '100%',
          }}
        >
          <Grid container justifyContent='center'>
            <Grid item md={6} xs={11}>
              <TextField
                variant="outlined"
                size="medium"
                fullWidth
                value={formData.subject}
                onChange={(e) => setFormData({ ...formData, subject: [e.target.value] })}
                // onKeyDown={searchHandler}
                sx={{
                  // textAlign:'end',
                  background: 'white',
                  borderRadius: '2em',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `2px solid ${theme.palette.secondary.main}`,
                    borderRadius: '2em',
                  },
                }}
                placeholder={" What can we help you learn today? "}
                hintStyle={{ width: '600px', textAlign: 'center' }}
                inputProps={{ style: { textAlign: 'center' } }}
                // InputProps={{
                //   style: { textAlign: 'center' },
                //   disableUnderline: true,
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <Search />
                //     </InputAdornment>
                //   ),
                // }}
              />
            </Grid>
            <Grid xs={12}>
              <Box height={20}></Box>
            </Grid>
            <Grid xs={11} md={3}>
              <TextField
                variant="outlined"
                size="medium"
                fullWidth
                value={formData.date}
                onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                // onKeyDown={searchHandler}
                // type={'date'}
                placeholder='When'
                inputProps={{ style: { textAlign: 'center' } }}
                sx={{
                  border: 'none',
                  background: 'white',
                  borderRadius: '2em',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `2px solid ${theme.palette.secondary.main}`,
                    borderRadius: '2em'
                  },
                }}
                // InputProps={{
                //   disableUnderline: true,
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <DateRange />
                //     </InputAdornment>
                //   ),
                //   // placeholder: "Select date"
                // }}
              />
            </Grid>
            <Grid xs={12}>
              <Box height={20}></Box>
            </Grid>

            <Grid md={3} xs={11}>

              <TextField
                select
                variant="outlined"
                value={formData.tutoringType}
                onChange={(e) => setFormData({ ...formData, tutoringType: [e.target.value]})}
                // onChange={handleOptionChange}
                size="medium"
                fullWidth
                // defaultValue={'REMOTE'}
                sx={{
                  background: 'white',
                  border: 'none',
                  textAlign: "center",
                  borderRadius: '2em',
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: `2px solid ${theme.palette.secondary.main}`,
                    borderRadius: '2em'
                  },
                }}
                placeholder="Where"
                InputProps={{
                  disableUnderline: true,
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocationOn />
                    </InputAdornment>
                  ),
                }}
              >
                <MenuItem value="REMOTE">Online</MenuItem>
                <MenuItem value="IN_PERSON">In Person</MenuItem>
              </TextField>
            </Grid>
            <Grid xs={12}>
              <Box height={20}></Box>
            </Grid>
            <Grid xs={11} md={3}>
              <Stack mt={2} justifyContent={'center'} >
                <Button
                  onClick={searchHandler}
                  // disabled={userState.loading}
                  sx={{
                    background: theme.palette.primary.main,
                    minWidth: '120px',
                    px: 8,
                    py: '0.7em',
                    mx: 10,
                    borderRadius: 10,
                    color: theme.palette.secondary.main,
                    textTransform: 'capitalize',
                    "&:hover": {
                      background: theme.palette.primary.dark,
                    }
                  }} disableElevation variant='contained'>
                  {
                    <Typography fontWeight={600}>
                      Search
                    </Typography>
                  }
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}

export default Home;