let validatePassword = (password)=> {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@$!%*?&^#_+-./\\]{8,}$/;

    if (password.length < 8) {
      return "Password must be at least 8 characters.";
    }
    if (!/\d/.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!regex.test(password)) {
      return "Password must contain at least one letter and one number.";
    }
    return "";
  }

  export default validatePassword