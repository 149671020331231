import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { updateToken } from "../../config/config";
import { signupAPI, loginAPI, updateProfileAPI, updatePasswordAPI, forgotPasswordAPI, resetPasswordAPI, getCurrentUserLocationNameAPI } from "../../services/user";


export let signup = createAsyncThunk(
    'user/signup',
    async (userData, thunkApi) => {
        try {
            let response = await signupAPI(userData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let login = createAsyncThunk(
    'user/login',
    async (userData, thunkApi) => {
        try {
            let response = await loginAPI(userData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let updateProfile = createAsyncThunk(
    'user/update',
    async (userData, thunkApi) => {
        try {
            let response = await updateProfileAPI(userData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let logout = createAsyncThunk(
    'user/logout',
    async (thunkApi) => {
        try {
            console.log("sdfgf")
            localStorage.removeItem('token')
            localStorage.removeItem('user')
            localStorage.removeItem('tutor')
            return null
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)



export let updatePassword = createAsyncThunk(
    'user/update-password',
    async (userData, thunkApi) => {
        try {
            let response = await updatePasswordAPI(userData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let forgotPassword = createAsyncThunk(
    'user/forgot-password',
    async (userData, thunkApi) => {
        try {
            let response = await forgotPasswordAPI(userData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let resetPassword = createAsyncThunk(
    'user/reset-password/:token',
    async (userData, thunkApi) => {
        try {
            let response = await resetPasswordAPI(userData.token, userData.formData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let getCurrentUserLocationName = createAsyncThunk(
    'user/get-current-user-location-name',
    async (coordinate, thunkApi) => {
        try {
            let response = await getCurrentUserLocationNameAPI(coordinate)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

let initialState = {
    loading: false,
    errorMsg: '',
    successMsg: '',
    user: JSON.parse(localStorage.getItem('user')) || null,
    currentLocation:{
        name:'',
        errorMsg:''
    }
}

let userSlice = createSlice(
    {
        name: 'user',
        initialState,
        reducers: {
            clearUserState: (state) => {
                state.errorMsg = ''
                state.loading = false
                state.successMsg = ''
            },
            setUser: (state, action) => {
                state.user = action.payload
            },
            setProfileCompleted: (state, action) => {
                if (state.user) {
                    state.user.profileStatus='COMPLETED'
                    localStorage.setItem('user', JSON.stringify(state.user))
                }
            },
            clearLocationInfromation:(state,action)=>{
                state.currentLocation = {
                    name:'',
                    errorMsg:''
                }
            }
        },
        extraReducers: (builder) => {

            builder.addCase(signup.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })

            builder.addCase(getCurrentUserLocationName.fulfilled,(state,action)=>{
                state.currentLocation.name=action.payload.display_name
            })
            builder.addCase(getCurrentUserLocationName.rejected,(state,action)=>{
                state.currentLocation.name=''
                state.currentLocation.errorMsg="Current location can not be determined"
            })

            builder.addCase(signup.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = "Sign up successful"
                state.waitlistRank = action.payload.totalCount
            })
            builder.addCase(signup.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })

            builder.addCase(login.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })

            builder.addCase(logout.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })
            builder.addCase(logout.fulfilled, (state) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = "Logout successful"
                state.user = null
                updateToken('')
            })
            builder.addCase(logout.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })

            builder.addCase(login.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = "Login successful"
                state.waitlistRank = action.payload.totalCount
                state.user = action.payload.user
                localStorage.setItem('token', action.payload.token)
                localStorage.setItem('user', JSON.stringify(action.payload.user))
                updateToken(action.payload.token)
            })
            builder.addCase(login.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })
            builder.addCase(updateProfile.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })
            builder.addCase(updateProfile.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = "Profile updated successfully"
                state.user = action.payload.user
                localStorage.setItem('user', JSON.stringify(action.payload.user))
            })
            builder.addCase(updateProfile.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })
            builder.addCase(updatePassword.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })
            builder.addCase(updatePassword.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = action.payload.msg
            })
            builder.addCase(updatePassword.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })
            builder.addCase(forgotPassword.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })
            builder.addCase(forgotPassword.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = action.payload.message
            })
            builder.addCase(forgotPassword.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })
            builder.addCase(resetPassword.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })
            builder.addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = action.payload.message
            })
            builder.addCase(resetPassword.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })

        }

    }
)

let userReducer = userSlice.reducer
export let { clearUserState, setUser,setProfileCompleted,clearLocationInfromation } = userSlice.actions

export default userReducer