import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import styled from '@emotion/styled';
import NavItem from '../navItem/navItem';
import { Button, Grid, IconButton, SwipeableDrawer, List, ListItem, ListItemText, Stack, Divider, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { useSelector , useDispatch } from 'react-redux';
import { AccountCircle, Home as HomeIcon, Contacts } from '@mui/icons-material';
import {setUser} from '../../state/slices/user';






function HideOnScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
    });

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
};

export default function NavBar(props) {
    let userState = useSelector(state => state.user).user
    let theme = useTheme()
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [mainLinks, setMainLinks] = React.useState([])
    


    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    

   React.useEffect(() => {
    if (userState) {
        if (userState.roles.includes("ADMIN")){
            setMainLinks([
                { name: 'Home', path: '/' },
                {name: 'Dashboard', path: '/dashboard'},
                { name: `${userState.firstName} ${userState.lastName}`, path: '/profile' },
            ])
        } else if (userState.roles.includes("TUTOR")){
            setMainLinks([
                { name: 'Requests', path: '/request' },
                {name : 'Class Room', path: '/tutor-class-room'},
                { name: `${userState.firstName} ${userState.lastName}`, path: '/profile' },
            ])
        }else {
        
            setMainLinks([
                { name: ' Home', path: '/' },
                { name: ' Requests', path: '/student-request' },
                { name: ' Class Room', path: '/student-class-room' },
                { name: `${userState.firstName} ${userState.lastName}`, path: '/profile' },
            ])
        
        }
      } 
      else {
        setMainLinks([
          { name: 'Home', path: '/' },
          { name: 'Tutors', path: '/signup/Tutor' },
          { name: 'Students', path: '/signup/Student' },
          { name: 'Parents', path: '/signup/Parent' },
          { name: 'Login', path: '/login' }
        ]);
      }
    }, [userState]);

    return (
        <React.Fragment>
            <CssBaseline />
            <HideOnScroll {...props}>
                <AppBar elevation={0} sx={{ bgcolor: 'white' }}>
                    <Toolbar sx={{ bgcolor: 'transparent' }}>
                        <Grid container columns={24} alignItems='center'>
                            <Grid item md={8} xs={8}>
                                <Link
                                    to="/"
                                    style={{
                                        textDecoration: 'none',
                                        color: 'white',
                                    }}
                                >
                                    <Stack direction={'row'} alignItems='center'>
                                    <img style={{height:'50px'}} src='/white_text-modified.png' alt='logo'></img>
                                        <Typography color="primary" variant="h5">
                                            Astenge.com
                                        </Typography>
                                    </Stack>
                                </Link>
                            </Grid>
                            <Grid md={0} lg={6} xs={0}></Grid>
                            <Grid item md={16} lg={10} xs={16}>
                                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <IconButton
                                        edge="end"
                                        color="inherit"
                                        size='large'
                                        aria-label="menu"
                                        onClick={handleDrawerOpen}
                                        sx={{
                                            display: { md: 'none' },
                                            color: theme.palette.primary.main,
                                            '&:hover': {
                                                backgroundColor: 'transparent',
                                            },
                                        }}
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Box>
                                <Box sx={{ display: { md: 'flex', sm: 'none' , xs:'none' }, justifyContent: 'space-between' }}>
                                    {
                                        mainLinks.map(link => {
                                            return (
                                                <Link to={link.path}
                                                    style={{
                                                        textDecoration: "none",
                                                        color: 'white'

                                                    }}
                                                >
                                                    <Button size='large' sx={{
                                                        background: theme.palette.primary.main,
                                                        color: theme.palette.secondary.main,
                                                        textTransform: 'capitalize',
                                                        borderRadius: 2,
                                                        px: 3,
                                                        "&:hover": {}
                                                    }} disableElevation variant='contained'>
                                                        {/* <link.icon/> */}
                                                        <Typography fontWeight={600}>
                                                            {link.name}
                                                        </Typography>
                                                    </Button>
                                                </Link>
                                            )
                                        })
                                    }

                                </Box>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
            </HideOnScroll>
            <SwipeableDrawer
                anchor="right"
                open={drawerOpen}
                onClose={handleDrawerClose}
                onOpen={handleDrawerOpen}
            >
                <Box py={4} sx={{ width: 250 }} role="presentation" onClick={handleDrawerClose} onKeyDown={handleDrawerClose}>
                    <Typography fontSize={25} textAlign={'center'} color='primary' fontWeight={600}>Astenge</Typography>
                    <Divider></Divider>
                    <Divider></Divider>
                    <Divider></Divider>
                    <Divider></Divider>

                    <Stack m={1} direction="column" rowGap={1}>
                        {mainLinks.map((link) => (
                            <ListItem sx={{ borderRadius: 3, color: theme.palette.secondary.main, bgcolor: theme.palette.primary.main, boxSizing: 'border-box', '&:hover': { color: theme.palette.primary.main, background: theme.palette.secondary.main } }} key={link.path} button component={Link} to={link.path}>
                                <ListItemText primary={link.name} />
                            </ListItem>
                        ))}
                    </Stack>
                </Box>
            </SwipeableDrawer>
            <Toolbar />
        </React.Fragment>
    );
}
