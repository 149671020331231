import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  createRequestAPI,
  adminApproveRequestAPI,
  adminRejectRequestAPI,
  getStudentsRequestsAPI,
  getSudentApprovedRequestsAPI,
  deleteRequestAPI,
  pendingStudentRequestsAPI,
  pendingStudentRequestsNotApprovedAPI
} from "../../services/request";

export const createRequest = createAsyncThunk(
  "request/createRequest",
  async (request, thunkAPI) => {
    try {
      let response = await createRequestAPI(request);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const adminApproveRequest = createAsyncThunk(
  "request/adminApproveRequest",
  async (requestId, thunkAPI) => {
    try {
      let response = await adminApproveRequestAPI(requestId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const adminRejectRequest = createAsyncThunk(
  "request/adminRejectRequest",
  async (requestId, thunkAPI) => {
    try {
      let response = await adminRejectRequestAPI(requestId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getStudentsRequests = createAsyncThunk(
  "request/getStudentsRequests",
  async (requestId, thunkAPI) => {
    try {
      let response = await getStudentsRequestsAPI();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getSudentApprovedRequests = createAsyncThunk(
  "request/getSudentApprovedRequests",
  async (requestId, thunkAPI) => {
    try {
      let response = await getSudentApprovedRequestsAPI();
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const deleteRequest = createAsyncThunk(
    "request/deleteRequest",
    async (requestId, thunkAPI) => {
        try {
            let response = await deleteRequestAPI(requestId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const pendingStudentRequests = createAsyncThunk(
    "request/pendingStudentRequests",
    async (requestId, thunkAPI) => {
        try {
            let response = await pendingStudentRequestsAPI();
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);

export const pendingStudentRequestsNotApproved = createAsyncThunk(
    "request/pendingStudentRequestsNotApproved",
    async (requestId, thunkAPI) => {
        try {
            let response = await pendingStudentRequestsNotApprovedAPI();
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);


let initialState = {
  loading: false,
  error: null,
  success: null,
  studentRequests: [],
};

let requestSlice = createSlice({
  name: "request",
  initialState,
  reducers: {
    clearState: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = null;
    }
  },
  extraReducers: {
    [createRequest.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [createRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload;
    },
    [createRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [adminApproveRequest.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [adminApproveRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = "Request approved successfully"
    },
    [adminApproveRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Request could not be approved"
      state.success = null;
    },
    [adminRejectRequest.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [adminRejectRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = "Request rejected successfully"
    },
    [adminRejectRequest.rejected]: (state, action) => {
      state.loading = false;
      state.error = "Request could not be rejected"
      state.success = null;
    },
    [getStudentsRequests.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [getStudentsRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload;
      state.studentRequests = action.payload.requests;
    },
    [getStudentsRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [getSudentApprovedRequests.pending]: (state, action) => {
      state.loading = true;
      state.error = null;
      state.success = null;
    },
    [getSudentApprovedRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.error = null;
      state.success = action.payload;
      state.studentRequests = action.payload.requests;
    },
    [getSudentApprovedRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.success = null;
    },
    [deleteRequest.pending]: (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
    },
    [deleteRequest.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = action.payload;
    },
    [deleteRequest.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = null;
    },
    [pendingStudentRequests.pending]: (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
    },
    [pendingStudentRequests.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = action.payload;
        state.studentRequests = action.payload.requests;
    },
    [pendingStudentRequests.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = null;
    },
    [pendingStudentRequestsNotApproved.pending]: (state, action) => {
        state.loading = true;
        state.error = null;
        state.success = null;
    },
    [pendingStudentRequestsNotApproved.fulfilled]: (state, action) => {
        state.loading = false;
        state.error = null;
        state.success = action.payload;
        state.studentRequests = action.payload.requests;
    },
    [pendingStudentRequestsNotApproved.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload;
        state.success = null;
    },
    
  },
});

export const { clearState } = requestSlice.actions;

export default requestSlice.reducer;
