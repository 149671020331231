import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addToWaitlistAPI } from "../../services/waitlist";


export let addToWaitlist = createAsyncThunk(
    'waitlist/add',
    async ({ email }, thunkApi) => {

        try {
            let response = await addToWaitlistAPI(email)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)


let initialState = {
    loading: false,
    errorMsg: '',
    successMsg: '',
    waitlistRank: 0,
}

let waitlistSlice = createSlice(
    {
        name: 'waitlist',
        initialState,
        reducers: {},
        extraReducers: (builder) => {

            builder.addCase(addToWaitlist.pending, (state) => {
                state.loading = true
                state.errorMsg = ''
                state.successMsg = ''
            })

            builder.addCase(addToWaitlist.fulfilled, (state, action) => {
                state.loading = false
                state.errorMsg = ''
                state.successMsg = "Your email has been saved! We'll be in touch soon."
                state.waitlistRank = action.payload.totalCount
            })
            builder.addCase(addToWaitlist.rejected, (state, action) => {
                state.loading = false
                state.errorMsg = action.payload
                state.successMsg = ''
            })

        }
    }
)


let waitlistReducer = waitlistSlice.reducer

export default waitlistReducer