import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Chip,
  Avatar,
  Modal,
  Divider,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Sidebar from "../sideBar";
import { allApprovedRequests } from "../../../state/slices/admin";

import { useSelector, useDispatch } from "react-redux";
import { GridOverlay } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import Loading from "../../../components/loading/loading";
import RequestDetailPage from "../RequestDetailPage";

const ApprovedRequestPage = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const {approvedRequests,loading} = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(allApprovedRequests());
  }, [dispatch]);

  const columns = [
    { field: "studentName", headerName: "Student Name", width: 150 },
    { field: "tutorName", headerName: "Tutor Name", width: 150 },
    { field: "studentPhone", headerName: "Student Phone", width: 150 },
    { field: "tutorPhone", headerName: "Tutor Phone", width: 150 },
    { field: "studentEmail", headerName: "Student Email", width: 200 },
    { field: "tutorEmail", headerName: "Tutor Email", width: 200 },
    { field: "startingTime", headerName: "Starting Time", width: 150 },
    { field: "message", headerName: "Message", width: 200 },
    { field: "tutorRate", headerName: "Tutor Rate", width: 150 },
    { field: "tutoringType", headerName: "Tutoring Type", width: 150 },
    { field: "adminStatus", headerName: "Admin Status", width: 120 },
    { field: "tutorStatus", headerName: "Tutor Status", width: 120 },
  ];

  const rows = approvedRequests.map((request) => ({
    id: request._id,
    studentName: `${request.studentId.userId.firstName} ${request.studentId.userId.lastName}`,
    tutorName: `${request.tutorId.userId.firstName} ${request.tutorId.userId.lastName}`,
    studentPhone: request.studentId.userId.phoneNumber,
    tutorPhone: request.tutorId.userId.phoneNumber,
    studentEmail: request.studentId.userId.email,
    tutorEmail: request.tutorId.userId.email,
    startingTime: request.startingTime,
    message: request.message,
    tutorRate: request.tutorId.rate,
    tutoringType: request.tutoringType,
    adminStatus: request.adminApproved,
    tutorStatus: request.status,
  }));

  

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          color: "secondary",
        }}
      >
        <Typography variant="h6">No Approved Requests</Typography>
      </Box>
    </GridOverlay>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box p={3}>
          <Typography variant="h4" mb={3}>
            Request Details
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div style={{ height: 600, width: "100%" }}>
              {loading ? (
                <Loading />
              ) : (
                <DataGrid
                  columns={columns}
                  rows={rows}
                  columnHeaderHeight={60}
                  onCellClick={(e) => {
                    const request = approvedRequests.find(
                      (request) => request._id === e.row.id
                    );
                    setSelectedRequest(request);
                    handleOpen();
                  }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              )}
            </div>
          </Paper>
        </Box>
      </Grid>
      {selectedRequest && <RequestDetailPage open={open} handleClose={handleClose} data={approvedRequests.find((request) => request._id === selectedRequest._id)} />}
    </Grid>
  );
};

export default ApprovedRequestPage;
