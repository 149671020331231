import { clientInstance } from "../config/config";

export let getTutorByUserAPI = async (tutorId) => {
    return clientInstance.get(`/tutor/user/${tutorId}`);
}
export let updateTutorAPI = async (tutorData) => {
    return clientInstance.put(`/tutor/update`, tutorData);
}

export let addTutorAPI = async (tutorData) => {
    return clientInstance.post(`/tutor`, tutorData);
}

export let getPendingRequestsAPI = async (tutorId) => {
    return clientInstance.get(`/request/tutor/pending`);
}

export let getApprovedRequestsAPI = async (tutorId) => {
    return clientInstance.get(`/request/tutor/approved`);
}

export let getRejectedRequestsAPI = async (tutorId) => {
    return clientInstance.get(`/request/tutor/rejected`);
}

export let approveRequestAPI = async (requestId) => {
    return clientInstance.put(`/request/accept/${requestId}`);
}

export let rejectRequestAPI = async (requestId) => {
    return clientInstance.put(`/request/decline/${requestId}`);
}

export let getStudentsAPI = async () => {
    return clientInstance.get(`/student/tutor`);
}