import { clientInstance } from "../config/config";

export let allCollectionsAPI = async () => {
    return clientInstance.get(`/admin/allCollections`);
}

export let allApprovedRequestsAPI = async () => {
    return clientInstance.get(`/admin/approved`);
}

export let allPendingRequestsAPI = async () => {
    return clientInstance.get(`/admin/pending`);
}

export let allRejectedRequestsAPI = async () => {
    return clientInstance.get(`/admin/declined`);
}

export let allApprovedTutorsAPI = async () => {
    return clientInstance.get(`/admin/approvedTutors`);
}

export let allPendingTutorsAPI = async () => {
    return clientInstance.get(`/admin/pendingTutors`);
}

export let allRejectedTutorsAPI = async () => {
    return clientInstance.get(`/admin/rejectedTutors`);
}

export let approveTutorAPI = async (id) => {
    return clientInstance.put(`/tutor/approve/${id}`);
}

export let rejectTutorAPI = async (id) => {
    return clientInstance.put(`/tutor/reject/${id}`);
}

export let approveRequestAPI = async (id) => {
    return clientInstance.put(`/request/admin/approve/${id}`);
}

export let rejectRequestAPI = async (id) => {
    return clientInstance.put(`/request/admin/reject/${id}`);
}

