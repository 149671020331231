import { Link } from 'react-router-dom';

import { 

    Box, 
    Typography, 

    Paper,
    Grid,
    Button
} from '@mui/material';
import React, { useState , useEffect } from 'react';
import Sidebar from './sideBar';
import { useDispatch , useSelector } from 'react-redux';
import { allCollections } from '../../state/slices/admin';
import Loading from '../../components/loading/loading';


function Dashboard() {
  const dispatch = useDispatch()
  const allCollection = useSelector(state => state.admin)
  
  useEffect(() => {
    dispatch(allCollections())
  }, [])

  return (
    <>
    {allCollection.loading ? <Loading/>:    <Grid container>
      <Grid item xs={12} md={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box sx={{ margin: 8 }}>

          <Grid container spacing={3}>
            {allCollection.collections.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Paper
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    height: 300,
                    justifyContent: 'center',
                    backgroundColor: '#98EDBD'
                  }}
                >
                  <Typography variant="h5" gutterBottom color={"primary"} fontSize={30} fontWeight={600}>
                    {item.collectionName}
                  </Typography>
                  <Typography variant="h3">
                    {item.collectionSize}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Grid>
    </Grid>
}
    </>
  );
}


export default Dashboard;