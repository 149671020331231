import React from "react";
import {
  Modal,
  Box,
  Grid,
  Typography,
  Avatar,
  Button,
  Chip,
  Divider,
} from "@mui/material";
import { useTheme } from "@emotion/react";

const RequestDetailPage = ({ data, open, handleClose }) => {
  const theme = useTheme();
  if (!data) {
    return null;
  }
  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          p: 3,
          backgroundColor: "#f5f5f5",
          borderRadius: 4,
          maxWidth: 600,
          mx: "auto",
          mt: "10vh",
          maxHeight: "80vh",
          overflowY: "auto",
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="#333" fontWeight={600}>
              Student Details
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Name
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {`${data.studentId.userId.firstName} ${data.studentId.userId.lastName}`}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Email
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.studentId.userId.email}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Phone Number
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.studentId.userId.phoneNumber}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="#333" fontWeight={600}>
              Tutor Details
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Name
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {`${data.tutorId.userId.firstName} ${data.tutorId.userId.lastName}`}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Email
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.tutorId.userId.email}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Phone Number
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.tutorId.userId.phoneNumber}
            </Typography>
          </Grid>
          <Box sx={{ width: "100%" }}>
            <Divider />
            <Divider />
            <Divider />
          </Box>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="#333" fontWeight={600}>
              Tutoring Details
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Starting Time
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.startingTime}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Tutoring Type
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.tutoringType.map((type) => (
                <Chip
                  sx={{ mr: 1 }}
                  key={type}
                  label={type == "IN_PERSON" ? "In Person" : "Online"}
                  variant="outlined"
                  color="primary"
                />
              ))}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Tutor Rate
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.tutorId.rate}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h4" color="#333" fontWeight={600}>
              Additional Information
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Message
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.message}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Admin Status
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.adminApproved}
            </Typography>
            <Typography variant="body1" color="#333" fontWeight={400}>
              Tutor Status
            </Typography>
            <Typography variant="body2" color="#666" fontWeight={400}>
              {data.status}
            </Typography>
          </Grid>
        
        </Grid>
      </Box>
    </Modal>
  );
};

export default RequestDetailPage;