import { configureStore } from '@reduxjs/toolkit'
import userReducer from '../slices/user'
import waitlistReducer from '../slices/waitlist'
import tutorReducer from '../slices/tutor'
import searchTutor from '../slices/search'
import admin from '../slices/admin'
import request from '../slices/request'
import studnent from '../slices/studnent'
import earlyAcessRedcuer from '../slices/earylyAccess'
import session from '../slices/session'

export const store = configureStore({
    reducer: {
        waitlist: waitlistReducer,
        user: userReducer,
        tutor: tutorReducer,
        search: searchTutor,
        admin: admin,
        request: request,
        student: studnent,
        earlyAccess:earlyAcessRedcuer,
        session:session
    }
})

export default store