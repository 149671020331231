import { Outlet, Navigate } from 'react-router-dom';

const ProtectedLayout = ({ authStatus }) => {
  if (!authStatus) {
    return <Navigate to="/early-access" replace />;
  }

  return <Outlet />;
};

export default ProtectedLayout;