import { Route, Routes } from "react-router-dom";
import Layout from "./components/layout/layout";
import Confirmation from "./pages/confirmation/confirmation";
import Home from "./pages/home/home";
import Result from "./pages/result/result";
import Signup from "./pages/signup/signup";
import Login from "./pages/login/login";
import UserProfile from "./pages/profile/UserProfile";
import TutorProfle from "./pages/profile/TutorProfile";
import ForgotPassword from "./pages/forgotPassword/ForgotPassword";
import ResetPassword from "./pages/resetPassword/resetPassword";
import Waitlist from "./pages/waitlist/waitlist";
import './styles/style.global.css';
import { useSelector } from "react-redux";
import DetailResult from "./pages/result/detailResult";
import AdminDashboardPage from "./pages/dashboard/dashboard";
import ApprovedRequestsPage from "./pages/dashboard/Approved/request";
import RejectedRequestsPage from "./pages/dashboard/Rejected/request";
import PendingRequestsPage from "./pages/dashboard/Pending/request";
import AprovedTutorsPage from "./pages/dashboard/Approved/tutor";
import RejectedTutorsPage from "./pages/dashboard/Rejected/tutor";
import PendingTutorsPage from "./pages/dashboard/Pending/tutor";
import Request from "./pages/tutor-request/request";
import StudentRequest from "./pages/student-parent-request/request";
import EarlyAccess from "./pages/earlyAccess/earlyAccess";
import ProtectedLayout from "./components/protectedLayout/protectedLayout";
import TutorClassRoom from "./pages/class-room/tutorClassRoom";
import Detail_class_room from "./pages/class-room/detail_class_room";
import StudentClassRoom from "./pages/class-room/studentClassRoom";
import Student_Detail_class_room from "./pages/class-room/student-detail-class-room";
import ScrollToTop from "./components/scrollToTop/scrollToTop";






function App() {

  // const isAuth = localStorage.getItem('token') ? true : false
  let isAuth = useSelector(state => state.user)
  let earlyAccessGranted = useSelector(state => state.earlyAccess.accessGranted)
  return (
    <ScrollToTop>
      <Routes>
        <Route path="/early-access" element={<EarlyAccess></EarlyAccess>} />

        <Route path="/" element={<ProtectedLayout authStatus={earlyAccessGranted} />}>
          <Route index element={<Layout><Home /></Layout>} />
          <Route path="/signup/:role" element={<Layout> <Signup></Signup></Layout>} />
          <Route path="/login" element={<Layout> <Login></Login></Layout>} />
          <Route path="/confirmation" element={<Layout> <Confirmation></Confirmation></Layout>} />
          <Route path="/result" element={<Layout> <Result></Result></Layout>} />
          <Route path="/profile" element={!isAuth ? <Layout> <Login></Login></Layout> : <Layout> <UserProfile></UserProfile></Layout>} />
          <Route path="/tutor-profile" element={!isAuth ? <Layout> <Login></Login></Layout> : <Layout> <TutorProfle></TutorProfle></Layout>} />
          <Route path="/forgot-password" element={<Layout> <ForgotPassword></ForgotPassword></Layout>} />
          <Route path="/reset-password/:token" element={<Layout> <ResetPassword></ResetPassword></Layout>} />
          <Route path="/detail-result" element={<Layout> <DetailResult></DetailResult></Layout>} />
          <Route path="/waitlist" element={<Layout> <Waitlist></Waitlist></Layout>} />
          <Route path="/dashboard" element={<Layout> <AdminDashboardPage></AdminDashboardPage></Layout>} />
          <Route path="/request/approved" element={<Layout> <ApprovedRequestsPage></ApprovedRequestsPage></Layout>} />
          <Route path="/request/rejected" element={<Layout> <RejectedRequestsPage></RejectedRequestsPage></Layout>} />
          <Route path="/request/pending" element={<Layout> <PendingRequestsPage></PendingRequestsPage></Layout>} />

          <Route path="/tutor/approved" element={<Layout> <AprovedTutorsPage></AprovedTutorsPage></Layout>} />
          <Route path="/tutor/rejected" element={<Layout> <RejectedTutorsPage></RejectedTutorsPage></Layout>} />
          <Route path="/tutor/pending" element={<Layout> <PendingTutorsPage></PendingTutorsPage></Layout>} />
          <Route path="/student-request" element={<Layout> <StudentRequest></StudentRequest></Layout>} />

          <Route path="/request" element={<Layout> <Request></Request></Layout>} />
          <Route path="/tutor-class-room" element={<Layout> <TutorClassRoom></TutorClassRoom></Layout>} />
          <Route path="/detail-class-room" element={<Layout> <Detail_class_room></Detail_class_room></Layout>} />
          <Route path="/student-class-room" element={<Layout> <StudentClassRoom></StudentClassRoom></Layout>} />
          <Route path="/student-detail-class-room" element={<Layout> <Student_Detail_class_room></Student_Detail_class_room></Layout>} />

        </Route>

      </Routes>
    </ScrollToTop>

  );
}

export default App;