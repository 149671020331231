import * as React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Avatar,
     Card, 
     Grid,
     useTheme as Theme,
      Snackbar,
     } from '@mui/material';
import { getRequests , getApprovedRequests , getRejectedRequests , clearTutor } from '../../state/slices/tutor';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../../components/loading/loading';
import { Person } from '@mui/icons-material';
import CustomCard from './customCard';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

export default function Request() {
  const theme = useTheme();
  const theme2 = Theme();
  const [value, setValue] = React.useState(0);
    const dispatch = useDispatch();
    const { requests, successMsg , errorMsg} = useSelector((state) => state.tutor);

  React.useEffect(() => {
    dispatch(getRequests());
    }, [dispatch]);

    console.log(requests);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    if (newValue === 0) {
        dispatch(getRequests());
    }
    if (newValue === 1) {
        dispatch(getApprovedRequests());
    }
    if (newValue === 2) {
        dispatch(getRejectedRequests());
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };



  return (
    <>
     <Snackbar
        open={Boolean(successMsg || errorMsg)}
        autoHideDuration={3000}
        onClose={() => {
          dispatch(getRequests());
          dispatch(clearTutor());
        }}
        message={
          <Typography fontWeight={600} fontSize={20} color="secondary">
            {successMsg || errorMsg}
        </Typography>
        }
      />
    <Box mr={5} sx={{ 
      bgcolor: 'background.paper', 
        width: "100%" ,
        minHeight: "100vh",
        }}>
      <Box 
        sx={{   
          borderBottom: 1, 
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          marginRight: 5,
            }}
            >
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="grey"
          variant="fullWidth"
          aria-label="full width tabs example"
          >
          <Tab label="Pending" {...a11yProps(0)} />
          <Tab label="Approved" {...a11yProps(1)} />
          <Tab label="Rejected" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      </Box>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CustomCard requests={requests} theme2={theme2} value={value} />
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
            <CustomCard requests={requests} theme2={theme2} value={value} />
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
            <CustomCard requests={requests} theme2={theme2} value={value} />
        </TabPanel>
      </SwipeableViews>
    </Box>
        </>
  );
}