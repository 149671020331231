import React, { useEffect, useState, useRef } from "react";
import { styled } from "@mui/system";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  Grid,
  MenuItem,
  Snackbar,
  Stack,
  Switch,
  TextField,
  Typography,
  useTheme,
  Input,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Avatar,
  IconButton,
  Badge,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import TagsInput from "./TagsInput";
import { updateTutor, clearTutor } from "../../state/slices/tutor";
import { LOCATIONAPITOKEN, updateContentType } from "../../config/config";
import { CheckBox, LocationCity, LocationOn, Person } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import axios from "axios";
import { clearLocationInfromation, getCurrentUserLocationName } from "../../state/slices/user";

const ACTIVE_STATUS = ["Active", "In Active"];
const STARTING_TIME = [
  "Right away",
  "Within a few days",
  "Within a few weeks",
  "Not sure",
];

const TutorProfile = () => {
  const dispatch = useDispatch();
  let [locationSet, setLocationSet] = useState(false)
  const theme = useTheme();
  const navigate = useNavigate();
  const tutorState = useSelector((state) => state.tutor);
  const currentLocationState = useSelector(state => state.user.currentLocation)
  const [file, setFile] = useState(null);

  let [dataFetched, setDataFetched] = useState(false);
  const [formData, setFormData] = useState({
    education: {
      universityName: "",
      department: "",
      year: 1,
      graduated: false,
    },
    subjects: [],
    tutoringType: [],
    currentStatus: "",
    availability: "",
    experience: "",
    location: "",
    rate: 100,
  });


  if (tutorState.tutor && !dataFetched) {
    setDataFetched(true);
    setFormData({
      education: {
        universityName: tutorState.tutor.education.universityName,
        department: tutorState.tutor.education.department,
        year: tutorState.tutor.education.year,
        graduated: tutorState.tutor.education.graduated,
      },
      currentStatus: tutorState.tutor.currentStatus,
      availability: tutorState.tutor.availability,
      experience: tutorState.tutor.experience,
      subjects: tutorState.tutor.subjects,
      location: tutorState.tutor.location,
      tutoringType: tutorState.tutor.tutoringType,
      rate: tutorState.tutor.rate,
    });
  }

  const [openSnackbar, setOpenSnackbar] = useState(false);

  const [edit, setEdit] = useState(false);
  const [submitStatus, setSubmitStatus] = useState("");
  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleEducationChange = (e) => {
    setFormData({
      ...formData,
      education: {
        ...formData.education,
        [e.target.name]: e.target.value,
      },
    });
  };
  function getCoordintes() {
    var options = {
      // enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0
    };

    function success(pos) {
      var crd = pos.coords;
      var lat = crd.latitude.toString();
      var lng = crd.longitude.toString();
      dispatch(getCurrentUserLocationName({ longitude: lng, latitude: lat }))
      setLocationSet(false)
      return;
    }

    function error(err) {
      setErrors(prev => {
        return {
          ...prev,
          location: 'Current location can not be determined'

        }
      })
      console.warn(`ERROR(${err.code}): ${err.message}`);
    }

    navigator.geolocation.getCurrentPosition(success, error, options);
  }

  if (currentLocationState.name && !locationSet) {
    let name = currentLocationState.name
    setFormData({
      ...formData,
      location: name
    });
    setLocationSet(true)
  }
  if (currentLocationState.errorMsg && !locationSet) {
    setErrors(prev => {
      return {
        ...prev,
        location: 'Current location can not be determined'
      }
    })
    setLocationSet(true)

  }

  const formValidation = () => {
    const validationErrors = {};

    if (!formData.education.universityName.trim()) {
      validationErrors.universityName = "University name is required";
    }

    if (!formData.education.department.trim()) {
      validationErrors.department = "Department is required";
    }

    if (!isGraduated && !isStudent) {
      validationErrors.graduated = "Level of education is required";
    }

    if (!formData.education.year && isStudent) {
      validationErrors.year = "Year is required";
    }

    if (
      tutorState.tutor &&
      !file &&
      !tutorState.tutor.educationalQualification
    ) {
      validationErrors.educationalQualification =
        "Educational qualification is required";
    }

    if (!formData.currentStatus.trim()) {
      validationErrors.currentStatus = "Current status is required";
    }

    if (!formData.availability.trim()) {
      validationErrors.availability = "Availability is required";
    }

    if (!formData.experience.trim()) {
      validationErrors.experience = "Experience is required";
    }

    if (!formData.subjects.length) {
      validationErrors.subjects = "Subjects is required";
    }

    if (!formData.location.trim()) {
      validationErrors.location = "Location is required";
    }

    if (!formData.rate) {
      validationErrors.rate = "Rate is required";
    }

    if (formData.rate < 100) {
      validationErrors.rate = "Rate should be greater than 100";
    }


    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleUpdateProfile = () => {
    if (formValidation()) {

      const data = new FormData();
      data.set("education", JSON.stringify(formData.education));
      data.set("currentStatus", JSON.stringify(formData.currentStatus));
      data.set("availability", JSON.stringify(formData.availability));
      data.set("experience", JSON.stringify(formData.experience));
      data.set("subjects", JSON.stringify(formData.subjects));
      data.set("location", JSON.stringify(formData.location));
      data.set("tutoringType", JSON.stringify(formData.tutoringType));
      data.set("rate", JSON.stringify(formData.rate));
      if (file) {
        data.set("educationalQualification", file);
      }
      updateContentType("multipart/form-data");
      dispatch(updateTutor(data));
      updateContentType("application/json");
      setOpenSnackbar(true);
      clearTutor();
      setSubmitStatus("success");
    } else {
      setSubmitStatus("error");
      clearTutor();
    }
  };

  const handleTutoringTypeChange = (event) => {
    const { value } = event.target;
    const selectedTypes = [...formData.tutoringType];

    if (event.target.checked) {
      selectedTypes.push(value);
    } else {
      const index = selectedTypes.indexOf(value);
      if (index !== -1) {
        selectedTypes.splice(index, 1);
      }
    }

    setFormData({
      ...formData,
      tutoringType: selectedTypes,
    });
  };

  useEffect(() => { }, []);

  const hundleSubjedcts = (chips) => {
    if (chips !== formData.subjects) {
      setFormData({
        ...formData,
        subjects: chips,
      });
    }
  };


  const handlefileUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 2000000) {
      setErrors({
        ...errors,
        educationalQualification: "File size should be less than 2MB",
      });
      return;
    } else {
      setErrors({
        ...errors,
        educationalQualification: "",
      });
    }
    setFormData({
      ...formData,
      education: {
        ...formData.education,
        graduated: isGraduated,
      },
    });



    setFile(e.target.files[0]);
    setUploadedFile(e.target.files[0]);
  };

  useEffect(() => {
    if (tutorState.tutor) {
      setIsGraduated(tutorState.tutor.education.graduated);
      setIsStudent(!tutorState.tutor.education.graduated);
    }
  }, [tutorState.tutor]);

  const [isGraduated, setIsGraduated] = useState(false);
  const [isStudent, setIsStudent] = useState(false);
  const [uploadedFile, setUploadedFile] = React.useState(null);



  return (
    <Box sx={{ minHeight: "90vh" }}>
      {!tutorState.loading && tutorState.tutor && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          message={
            <Typography fontWeight={600} fontSize={20} color="secondary">
              {submitStatus === "success"
                ? "Profile updated successfully"
                : "Profile update failed"}
            </Typography>
          }
        />
      )}

      <Card
        elevation={10}
        sx={(theme) => ({
          borderRadius: 4,
          mb: 3,
          [theme.breakpoints.up("md")]: {
            p: 5,
            mx: 10,
            mt: 3,
          },
          [theme.breakpoints.up("xs")]: {
            p: 2,
            mx: 2,
            mt: 3,
          },
        })}
      >
        <Stack mx={{ lg: 25, md: 15, xs: 1 }} justifyContent="start" rowGap={3}>
          <Box display="flex" alignItems="center">
            <Box flexGrow={1} textAlign="center">
              <Typography fontWeight={900} fontSize={50} ml={1}>
                Tutor Profile
              </Typography>
            </Box>
          </Box>

          <Box display="flex" alignItems="center">

            <Box flexGrow={1} display={"flex"} justifyContent={"end"}>
              <Link to="/profile" style={{ textDecoration: "none" }}>
                <Button>

                  <Typography
                    component={"span"}
                    textAlign="right"
                    fontWeight={900}
                    ml={1}
                    color="primary"
                    textTransform={"capitalize"}
                  >
                    Basic profile
                  </Typography>
                </Button>
              </Link>
            </Box>
          </Box>

          <Typography fontWeight={900} fontSize={30} ml={1} color={"primary"}>
            Education
          </Typography>

          <TextField
            label="University Name"
            name="universityName"
            value={formData.education.universityName}
            onChange={handleEducationChange}
            error={Boolean(errors["universityName"])}
            helperText={errors["universityName"]}
            fullWidth
          />
          <TextField
            label="Department"
            name="department"
            value={formData.education.department}
            onChange={handleEducationChange}
            error={Boolean(errors["department"])}
            helperText={errors["department"]}
            fullWidth
          />

          <FormControl component="fieldset">
            <FormLabel color="primary">
              {errors["graduated"] || "Level of Education"}
            </FormLabel>
            <RadioGroup row>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="student"
                    control={<Radio checked={isStudent} />}
                    label="Student"
                    onChange={() => {
                      setIsStudent(true);
                      setIsGraduated(false);
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControlLabel
                    value="graduate"
                    control={<Radio checked={isGraduated} />}
                    label="Graduate"
                    onChange={() => {
                      setIsGraduated(true);
                      setIsStudent(false);
                    }}
                  />
                </Grid>
              </Grid>
            </RadioGroup>
          </FormControl>

          {isGraduated && (
            <Typography fontWeight={900} fontSize={20} ml={1} color={"primary"}>
              Upload a scanned copy of your degree/diploma
            </Typography>
          )}

          {isStudent && (
            <>
              <TextField
                label="Year"
                name="year"
                value={formData.education.year}
                onChange={handleEducationChange}
                error={Boolean(errors["year"])}
                helperText={errors["year"]}

                fullWidth
              />
              <Typography
                fontWeight={900}
                fontSize={20}
                ml={1}
                color={"primary"}
              >
                Upload your student ID
              </Typography>
            </>
          )}

          <div>
            <Typography fontWeight={300} fontSize={20} ml={1} color={"primary"}>
              file size should be less than 2MB
            </Typography>
            <Input
              type="file"
              onChange={handlefileUpload}
              style={{ display: "none" }}
              id="file-upload-button"
            />
            {errors["educationalQualification"] && (
              <Alert severity="error">
                {errors["educationalQualification"]}
              </Alert>
            )}
            <label htmlFor="file-upload-button">
              <Button variant="contained" component="span">
                Upload File
              </Button>
            </label>
            {uploadedFile && (
              <div>
                <p>Uploaded File: {uploadedFile.name}</p>
                <p>File Size: {uploadedFile.size} bytes</p>
              </div>
            )}
          </div>
          {console.log(tutorState.tutor)}
          {tutorState.tutor && tutorState.tutor.educationalQualification && (
            <Box justifyContent={"start"} display="flex">
              <Button
                size="large"
                onClick={() => { window.open(tutorState.tutor.educationalQualification, '_blank') }}
                disabled={tutorState.loading}
                sx={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.primary.main,
                  textTransform: "capitalize",
                  minWidth: "150px",
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                  },
                }}
                disableElevation
                variant="contained"
              >
                {tutorState.loading ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography fontWeight={900}>DownLoad Uploaded File</Typography>
                )}
              </Button>
            </Box>)
          }

          <Typography fontWeight={900} fontSize={30} ml={1} color={"primary"}>
            Experience and Availability
          </Typography>

          <TextField
            select
            label="Current Status"
            name="currentStatus"
            value={formData.currentStatus}
            error={Boolean(errors["currentStatus"])}
            helperText={errors["currentStatus"]}
            onChange={handleChange}
          >
            {ACTIVE_STATUS.map((status) => (
              <MenuItem key={status} value={status}>
                {status}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            select
            label="Availability"
            name="availability"
            value={formData.availability}
            error={Boolean(errors["availability"])}
            helperText={errors["availability"]}
            onChange={handleChange}
          >
            {STARTING_TIME.map((time) => (
              <MenuItem key={time} value={time}>
                {time}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            rows={4}
            multiline
            label="Experience"
            name="experience"
            value={formData.experience}
            onChange={handleChange}
            error={Boolean(errors["experience"])}
            helperText={errors["experience"]}
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.tutoringType.includes("REMOTE")}
                    onChange={handleTutoringTypeChange}
                    value="REMOTE"
                    color="primary"
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.tutoringType.includes("IN_PERSON")}
                    onChange={handleTutoringTypeChange}
                    value="IN_PERSON"
                    color="primary"
                  />
                }
                label="In Person"
              />
            </Grid>
          </Grid>

          <Typography fontWeight={900} fontSize={30} ml={1} color={"primary"}>
            Subjects
          </Typography>

          <TagsInput
            onChips={hundleSubjedcts}
            label={"Subjects"}
            chipss={formData.subjects}
            error={errors["subjects"]}
          />



          <Typography fontWeight={900} fontSize={30} ml={1} color={"primary"}>
            Location and Rate
          </Typography>

          <Grid container alignItems={'center'}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Location"
                name="location"
                value={formData.location}
                onChange={handleChange}
                error={Boolean(errors["location"])}
                helperText={errors["location"]}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <IconButton sx={{ borderRadius: 2 }} onClick={() => { getCoordintes() }}>
                <LocationOn color='primary'></LocationOn>
                <Typography>Use Current Location</Typography>
              </IconButton>
            </Grid>

          </Grid>


          <TextField
            label="Rate Per Hour(ETB)"
            type="number"
            name="rate"
            value={formData.rate}
            onChange={handleChange}
            error={Boolean(errors["rate"] || formData.rate < 100)}
            helperText={errors["rate"] || formData.rate < 100 ? "Rate should be greater than 100" : ""}
          />
          <Box justifyContent={"center"} display="flex">
            <Button
              size="large"
              onClick={handleUpdateProfile}
              disabled={tutorState.loading}
              sx={{
                background: theme.palette.secondary.main,
                color: theme.palette.primary.main,
                textTransform: "capitalize",
                minWidth: "150px",
                "&:hover": {
                  background: theme.palette.secondary.dark,
                },
              }}
              disableElevation
              variant="contained"
            >
              {tutorState.loading ? (
                <CircularProgress size={25} />
              ) : (
                <Typography fontWeight={900}>Update Profile</Typography>
              )}
            </Button>
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default TutorProfile;
