import { Alert, Box, Button, Card, CircularProgress, Grid, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/button/button'
import CenteredBox from '../../components/centeredBox/centeredBox'
import Loading from '../../components/loading/loading'
import { clearUserState, login } from '../../state/slices/user'

function Login() {
  let theme = useTheme()
  let userState = useSelector(state => state.user)
  let navigate = useNavigate()
  let dispatch = useDispatch()

  let [submitStatus, setSubmitStatus] = useState('')

  const [formData, setFormData] = useState({
    email: '',
    password: '',
  });

  const [errors, setErrors] = useState({});

  if (userState.successMsg) {
    dispatch(clearUserState())
    console.log(userState.user)
    if(userState.user && (userState.user.roles).includes('TUTOR')){
      navigate('/profile')
      
    }else   navigate('/')

  }


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Perform validation on the individual field as the user types
    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const validationErrors = { ...errors };

    switch (fieldName) {
      case 'email':
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        validationErrors.email = value.trim() && emailRegex.test(value) ? '' : 'Invalid email address';
        break;
      case 'password':
        validationErrors.password = value.trim() ? String(value).length < 8 ? 'Password must be at least 8 characters' : '' : 'Password is required';
        break;
      default:
        break;
    }

    setErrors((prev) => { return { ...prev, ...validationErrors } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    Object.keys(formData).forEach((fieldName) => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName]) {
        validationErrors[fieldName] = errors[fieldName];
      }
    });

    setSubmitStatus(prev => prev + 'x')

  };

  useEffect(() => {
    if (submitStatus && Object.keys(errors).filter(key => errors[key]).length === 0) {
      dispatch(login({ ...formData }));
      console.log('Form submitted:', formData);
    } else {
      dispatch(clearUserState())
    }
  }, [submitStatus])

  return (
    <Box
      sx={{
        mb: 3
      }}>

      <Card
        elevation={10}
        sx={theme => ({
          borderRadius: 4,
          minHeight: '70vh',
          [theme.breakpoints.up('md')]: {
            p: 0,
            mx: 10,
            mt: 3,
          },
          [theme.breakpoints.up('xs')]: {
            p: 2,
            mx: 2,
            mt: 3
          },
          display: 'flex', // Added to enable flex layout
          alignItems: 'center', // Added to vertically center the content
        })}
      >
        {/* Left Image */}
        <Box
          sx={{
            // if it is mobile, then don't show the image
            display: { xs: 'none', md: 'flex' },
            width: { xs: 'none', md: '50%' }, // Adjust the width as needed
            justifyContent: 'center',
            height: "80vh"
            // display: 'flex',
          }}
        >
          <img src="/STUDENT.jpeg" alt="Left Image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: "cover" }} />
        </Box>

        {/* Right Content */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' }, // Adjust the width as needed
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 16px', // Added some padding for better spaci
          }}
        >
          <Box
            sx={{
              color: theme.palette.primary.main,
              borderRadius: 2,
              mb: 5,

            }} >
            <Typography fontWeight={900} fontSize={40} textAlign={'center'}>
              Login
            </Typography>
          </Box>
          <Box sx={{
            mb: 3
          }}>
            <Typography textAlign={'center'} fontWeight={600} fontSize={20} color='secondary'>
              Enter your email and password to login
            </Typography>
          </Box>


          <Stack mx={{ lg: 15, md: 15, xs: 1 }} justifyContent='start' rowGap={3}>
            <TextField
              label='Email'
              variant='outlined'
              name='email'
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              label='Password'
              variant='outlined'
              name='password'
              type='password'
              value={formData.password}
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}
            />
            <CenteredBox >
              <Stack direction={'column'} justifyContent='center' rowGap={2}>
                {
                  userState.errorMsg && <Alert severity="error">{userState.errorMsg}</Alert>
                }
                <Button
                  onClick={handleSubmit}
                  disabled={userState.loading}
                  sx={{
                    background: theme.palette.secondary.main,
                    minWidth: '120px',
                    color: theme.palette.primary.main,
                    textTransform: 'capitalize',
                    "&:hover": {
                      background: theme.palette.secondary.dark,
                    }
                  }} disableElevation variant='contained'>
                  {
                    userState.loading ? <CircularProgress size={25}></CircularProgress> :
                      <Typography fontWeight={600}>
                        Login
                      </Typography>
                  }
                </Button>
              </Stack>
            </CenteredBox>
            <Stack justifyContent='end' >
              {/* <Typography textAlign="end" fontWeight={600} fontSize={14} color='primary' onClick={() => navigate("/forgot-password")}>
                Don't have an account?
              </Typography> */}
              <Typography textAlign="end" fontWeight={600} fontSize={14} color='primary' onClick={() => navigate("/forgot-password")}>
                Forgot password?
              </Typography>
            </Stack>
          </Stack>
        </Box>
      </Card>

    </Box>
  )
}

export default Login
