import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import TextField from '@mui/material/TextField';
import { Add } from '@mui/icons-material';
import { Box } from '@mui/material';


const AddSubjectDialog = ({setValue}) => {
    const [open, setOpen] = useState(false);
    const [subjectName, setSubjectName] = useState('');

    const handleClickOpen = () => {
        setSubjectName('')
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddSubject = () => {
        // Here you can handle adding the subject to your state or perform any other action
        console.log('Adding subject:', subjectName);
        setValue(subjectName)
        handleClose();
    };

    const handleSubjectNameChange = (event) => {
        setSubjectName(event.target.value);
    };

    return (
        <div>
            <Button variant="contained" color='primary' onClick={handleClickOpen}>
                <Box display={'flex'}>
                    <Add /> Add
                </Box>

            </Button>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Add Subject</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="subject-name"
                        label="Subject Name"
                        type="text"
                        fullWidth
                        value={subjectName}
                        onChange={handleSubjectNameChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancel</Button>
                    <Button onClick={handleAddSubject} variant="contained" color="primary">
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default AddSubjectDialog;
