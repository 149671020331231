import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { makeReviewAPI , getTutorsAPI } from "../../services/student";
import { deleteRequestAPI } from "../../services/request";

export const makeReview = createAsyncThunk(
    "student/makeReview",
    async (reviewData, thunkAPI) => {
        
        try {
            let response = await makeReviewAPI(reviewData);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }

);

export const getTutors = createAsyncThunk(
    "student/getTutors",
    async (reviewData, thunkAPI) => {
        
        try {
            let response = await getTutorsAPI();
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);
export const deleteRequest = createAsyncThunk(
    "request/deleteRequest",
    async (requestId, thunkAPI) => {
        try {
            let response = await deleteRequestAPI(requestId);
            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);



const studentSlice = createSlice({
    name: "student",
    initialState: {
        student: null,
        error: "",
        loading: false,
        success: "",
        tutors: []
    },

    reducers: {
        clearStudent: (state, action) => {
            state.error = null;
            state.loading = false;
            state.success = null;
        }
    },

    extraReducers: {
        [makeReview.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [makeReview.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "Review made successfully";
        },
        [makeReview.rejected]: (state, action) => {
            state.loading = false;
            state.error = "Review could not be made"
            state.success = null;
        },
        [getTutors.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [getTutors.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "Tutors fetched successfully";
            state.tutors = action.payload;
        },
        [getTutors.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.msg;
            state.success = null;
        },
        [deleteRequest.pending]: (state, action) => {
            state.loading = true;
            state.error = null;
            state.success = null;
        },
        [deleteRequest.fulfilled]: (state, action) => {
            state.loading = false;
            state.error = null;
            state.success = "Request deleted successfully";
        },
        [deleteRequest.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload.msg;
            state.success = null;
        }
    }
});

export const { clearStudent } = studentSlice.actions;

export default studentSlice.reducer;