import { createTheme } from '@mui/material';

const theme = createTheme({
    palette: {
        primary: {
            main: '#011C40',
            light: '#012d69',
            dark: '#012d69',
        },
        secondary: {
            main: '#FACD9D',
            dark: '#FBAA60'
        },
    },
    typography: {
        fontFamily: 'Roboto, Arial, sans-serif', // Set your desired font family here
    },
    overrides: {
        MuiButton: {
            root: {
                '&:hover': {
                    backgroundColor: '#FF5722', // Set your primary color here
                },
            },
        },
    },
}
);

export default theme;
