import {
  Approval,
  CalendarMonth,
  Explicit,
  Lightbulb,
  LocationOn,
  Money,
  PendingActions,
  RateReview,
  RequestPage,
  School,
  Science,
} from "@mui/icons-material";
import {
  Avatar,
  Box,
  Button,
  Card,
  Chip,
  Grid,
  Stack,
  Typography,
  useTheme,
  Modal,
  TextField,
  MenuItem,
  Snackbar,
  Rating,
  Autocomplete,
} from "@mui/material";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { createRequest, clearState } from "../../state/slices/request";
import { CircleLoader } from "react-spinners";
import { grey } from "@mui/material/colors";
import { extractId } from "../../utils/imageIdExtractor";

const DetailResult = () => {
  const location = useLocation();
  const person = location.state.person;
  console.log(person);
  const theme = useTheme();
  const userState = useSelector((state) => state.user);
  const [open, setOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const requestState = useSelector((state) => state.request);
  const [displayComments, setDisplayComments] = React.useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const RequestModal = () => {
    const dispatch = useDispatch();
    const [startTime, setStartTime] = React.useState("");
    const [tutoringType, setTutoringType] = React.useState([]);
    const [message, setMessage] = React.useState("");
    const [errors, setErrors] = React.useState({});

    const STARTING_TIME = [
      "Right away",
      "Within a few days",
      "Within a few weeks",
      "Not sure",
    ];

    const TUTORING_TYPE = {
      IN_PERSON: "In Person",
      ONLINE: "Online",
    }

    if (requestState.error || requestState.success) {
      setSnackbarOpen(true);
    }

    const handleFormSubmit = (event) => {
      event.preventDefault();
      const isValid = validate();
      if (isValid) {
        dispatch(
          createRequest({
            tutorId: person.id,
            startingTime: startTime,
            tutoringType: tutoringType,
            message: message,
          })
        );

        console.log("Form submitted:", {
          startTime,
          tutoringType,
          message,
        });
        handleClose();
      }
    };

    const validate = () => {
      let errors = {};
      if (!tutoringType) {
        errors["tutoringType"] = "Please select a tutoring type";
      }
      if (!startTime) {
        errors["startingTime"] = "Please select a starting time";
      }
      if (!message) {
        errors["message"] = "Please enter a message";
      }

      setErrors(errors);
      return Object.keys(errors).length === 0;
    };



    return (
      <Box>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={3000}
          onClose={() => {
            setSnackbarOpen(false);
            dispatch(clearState());
          }}
          message={
            <Typography fontWeight={600} fontSize={20} color="secondary">
              {requestState.error
                ? requestState.error.msg
                : requestState.success
                  ? requestState.success.msg
                  : ""}
            </Typography>
          }
        ></Snackbar>
        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              p: 3,
              backgroundColor: "white",
              borderRadius: 4,
              maxWidth: 600,
              mx: "auto",
              mt: "10vh",
              maxHeight: "150vh",
              overflowY: "auto",
            }}
          >
            <form onSubmit={handleFormSubmit}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Typography fontWeight={600} fontSize={30}>
                  <RequestPage /> Request Tutoring
                </Typography>
              </Box>
              <Stack spacing={2}>
                <TextField
                  id="startingTime"
                  select
                  label="starting time"
                  name="starting time"
                  value={startTime}
                  error={Boolean(errors["startingTime"])}
                  helperText={errors["startingTime"]}
                  onChange={(event) => setStartTime(event.target.value)}
                >
                  {STARTING_TIME.map((time) => (
                    <MenuItem key={time} value={time}>
                      {time}
                    </MenuItem>
                  ))}
                </TextField>
                <Autocomplete
                  fullWidth
                  multiple
                  id="tutoringType"
                  options={Object.values(TUTORING_TYPE)}
                  getOptionLabel={(option) => option}
                  defaultValue={[]}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tutoring Type"
                      placeholder="Tutoring Type"
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                  )}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => (
                      <Chip
                        key={index}
                        label={option}
                        {...getTagProps({ index })}
                        sx={{
                          my: 1,
                          background: theme.palette.secondary.main,
                        }}
                      />
                    ))

                  }
                  onChange={(event, value) => {
                    value = value.map((type) => {
                      if (type === "In Person") {
                        return "IN_PERSON";
                      } else {
                        return "REMOTE";
                      }
                    }
                    );
                    setTutoringType(value);
                  }}
                  error={Boolean(errors["tutoringType"])}
                  helperText={errors["tutoringType"]}
                />

                <TextField
                  fullWidth
                  margin="normal"
                  label="Message"
                  value={message}
                  onChange={(event) => setMessage(event.target.value)}
                  error={!!errors.message}
                  helperText={errors.message}
                  multiline
                  rows={4}
                />

                {requestState.loading ? (
                  <Button variant="contained" color="primary" disabled>
                    <CircleLoader size={20} />
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleFormSubmit}
                  >
                    Submit Request
                  </Button>
                )}
              </Stack>
            </form>
          </Box>
        </Modal>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        minHeight: "90vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: 5,
      }}
    >
      <RequestModal />
      <Card
        elevation={10}
        sx={{
          borderRadius: 4,
          p: 5,
          mt: 3,
          maxWidth: 1300,
          width: "100%",
        }}
      >
        <Stack spacing={3}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <Box
              sx={{
                borderRadius: "100%",
                overflow: 'hidden',
                width: '200px',
                height: '200px',
              }}>

              <img
                crossOrigin="anonymous"
                src={person.profileImage?.includes('drive.google.com') ? `https://drive.lienuc.com/uc?id=${extractId(person.profileImage)}` : person.profileImage}
                alt="profile"
                style={{ width: "200px", height: "200px", objectFit: "cover" }}
              />
            </Box>
            <Typography variant="h4" fontWeight={600} my={2}>
              {person.firstName} {person.lastName}
            </Typography>
          </Box>

          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={5}

              >
                <Typography variant="h5" fontWeight={600} s>
                  <School /> Education
                </Typography>

                <Typography variant="body1" fontWeight={600} mt={1}>
                  University: {person.education.universityName}
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  Department: {person.education.department}
                </Typography>
                {person.education.graduated ?
                  <Typography variant="body1" fontWeight={600}>
                    Graduated: Yes
                  </Typography>
                  :
                  <Typography variant="body1" fontWeight={600}>
                    Year: {person.education.year}
                  </Typography>
                }
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={5}
              >
                <Typography variant="h5" fontWeight={600}>
                  <Explicit /> Experience
                </Typography>
                <Box width="100%" mt={1}>
                  <Typography
                    variant="body1"
                    textAlign="justify"
                    color="text.secondary"
                    style={{ wordWrap: "break-word" }}
                  >
                    {person.experience}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={5}

              >
                <Typography variant="h5" fontWeight={600}>
                  <Science /> Subjects
                </Typography>
                {person.subjects.map((subject) => (
                  <Chip
                    key={subject}
                    label={subject}
                    sx={{
                      my: 1,
                      background: theme.palette.secondary.main,
                      color: theme.palette.primary.main,
                      fontWeight: 600,
                      fontSize: 18,
                    }}
                    variant="outlined"
                  />
                ))}
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={5}
              >
                <Typography variant="h5" fontWeight={600}>
                  <LocationOn /> Location
                </Typography>
                <Typography variant="body1" fontWeight={600} mt={1}>
                  {person.location}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={5}

              >
                <Typography variant="h5" fontWeight={600} >
                  <Lightbulb /> Tutoring Type
                </Typography>

                {person.tutoringType.map((type) => (
                  <Chip
                    key={type === "IN_PERSON" ? "In Person" : "Online"}
                    label={type === "IN_PERSON" ? "In Person" : "Online"}
                    sx={{
                      my: 1,
                      background: theme.palette.primary.main,
                      color: theme.palette.secondary.main,
                      fontWeight: 600,
                      fontSize: 15,
                    }}
                    variant="outlined"
                  />
                ))}
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="start"
                >

                  <Typography variant="h5" fontWeight={600} mt={2}>
                    <PendingActions /> Verification Status
                  </Typography>
                  <Typography variant="body1" fontWeight={600}>
                    {person.approved.charAt(0).toUpperCase() + person.approved.slice(1).toLowerCase()}
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                px={5}
              >
                <Typography variant="h5" fontWeight={600}>
                  <CalendarMonth /> Availability
                </Typography>
                <Typography variant="body1" fontWeight={600} mt={1}>
                  {person.availability}
                </Typography>
                <Typography variant="h5" fontWeight={600} mt={2}>
                  <Money /> Rate
                </Typography>
                <Typography variant="body1" fontWeight={600}>
                  {person.rate} ETB / Hour
                </Typography>
              </Box>
            </Grid>
          </Grid>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          // px={5}
          >
            <Typography variant="h5" fontWeight={600}>
              <RateReview /> Reviews
            </Typography>
            <Typography
              variant="body1"
              fontWeight={600}
              mt={1}
              sx={{
                display: 'flex', // Make the content a flex container
                alignItems: 'center', // Align items vertically in the center
              }}
            >
              <Rating name="read-only" value={person.ratings} readOnly />
              <span style={{ marginLeft: '8px' }}> ({(person.ratings).toFixed(2)}) {person.reviews.length} reviews</span>
            </Typography>
            <Button onClick={() => setDisplayComments(!displayComments)} mt={2}>
              {displayComments ? "Hide Comments" : "Show Comments"}
            </Button>
            {displayComments && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {person.reviews.map((review) => (
                  <Card
                    key={review.id}
                    sx={{
                      display: "flex",
                      backgroundColor: grey[200],
                      borderRadius: 4,
                      p: 2,
                      m: 2,
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        width: "50%",
                      }}
                    >
                      <Typography variant="h6" fontWeight={600}>
                        {review.userId.firstName} {review.userId.lastName}
                      </Typography>
                      <Typography variant="body1" fontWeight={300}>
                        Comment: {review.comment}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-end",
                        width: "50%",
                      }}
                    >
                      <Rating name="read-only" value={review.rating} readOnly />
                    </Box>
                  </Card>
                ))}
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            {person.approved === "APPROVED" && userState.user ? (
              requestState.loading ? (
                <Button variant="contained" color="primary" disabled>
                  <CircleLoader size={20} />
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleOpen}
                >
                  <RequestPage />
                  <Typography variant="h6" fontWeight={600}>
                    Request Tutoring
                  </Typography>
                </Button>
              )
            ) : userState.user ? (
              <Typography variant="h6" fontWeight={600} color="secondary">
                This tutor is waiting for approval please check back later
              </Typography>
            ) : (
              <Typography variant="h6" fontWeight={600} color="secondary">
                Please login to request tutoring
              </Typography>
            )}
          </Box>
        </Stack>
      </Card>
    </Box>
  );
};

export default DetailResult;
