// import {Box,Typography} from '@mui/material'

import { Facebook, Instagram, Twitter } from "@mui/icons-material"
import { Alert, Button, Grid, Link, Stack, TextField, Typography } from "@mui/material"
import { border, Container } from "@mui/system"
import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addToWaitlist } from "../../state/slices/waitlist"
import validateEmail from "../../utils/emailValidator"



function Waitlist() {

    let waitlistState = useSelector(state => state.waitlist)
    let dispatch = useDispatch()

    let [email, setEmail] = useState('')
    let [error, setError] = useState('')

    let emailChangeHandler = (e) => {
        setEmail(e.target.value)

        if (!e.target.value) {
            setError('Email can not be blank')
        }
        else if (!validateEmail(e.target.value)) {
            setError('Invalid Email')
        } else {
            setError('')
        }
    }

    let addToWaitlistHandler = () => {
        if (!email) {
            setError('Email can not be blank')
        }
        else if (!validateEmail(email)) {
            setError('Invalid email')
        } else {
            setError('')
            dispatch(addToWaitlist({ email }))
            // setEmail('')
        }
    }
    return (
        <div>

            <Stack ml={2} direction={'row'} justifyContent='start'>
                {/* <Typography color='rgb(66, 135, 245)' fontWeight={600} fontSize={40}>Astegne</Typography> */}
            </Stack>
            <Container >

                <Grid justifyContent={'center'} container spacing={2}>
                    <Grid item order={{ xs: 2, md: 1 }} xs={12} lg={5} md={6}>
                        <Stack height={'100%'} spacing={2} justifyContent='center'>
                            {/* <Typography textAlign={{ xs: 'center', md: 'left' }} variant="h3" fontWeight={600} color={'rgb(66, 135, 245)'}>See what's next</Typography> */}
                            <Typography textAlign={{ xs: 'center', md: 'left' }} color='primary' variant="h3" fontWeight={800}>Astenge</Typography>
                            <Typography textAlign={{ xs: 'center', md: 'left' }} variant='h6' color='grey'>Stay ahead of the curve with our cutting-edge tutoring service - reserve your spot on our exclusive waitlist.</Typography>
                            {
                                !waitlistState.successMsg &&
                                <Stack direction={{ sx: 'column', md: 'row' }} gap={2}>
                                    <TextField
                                        sx={{ borderBottom: 'none' }}
                                        variant="outlined"
                                        placeholder="email"
                                        inputProps={{
                                            style: {
                                                height: "5px",
                                                border: 'none',
                                                // background: 'rgb(235, 232, 232)'
                                            },
                                        }}

                                        onChange={emailChangeHandler}
                                        value={email}
                                        error={Boolean(error)}
                                        helperText={error}
                                    />
                                    <Button onClick={addToWaitlistHandler} disableElevation={true}
                                        sx={{ textTransform: 'capitalize', height: '37px', width: 'auto', bgcolor: "primary" }}
                                        disabled={waitlistState.loading}
                                        size='' variant="contained"
                                    >
                                        {waitlistState.loading ? "Joining" : "Join Waitlist"}
                                    </Button>
                                </Stack>
                            }
                            {
                                waitlistState.errorMsg ?
                                    <Alert severity="info">{waitlistState.errorMsg}</Alert>
                                    : waitlistState.successMsg ?
                                        <Alert severity="success">{waitlistState.successMsg}</Alert>
                                        : <></>

                            }
                        </Stack>
                    </Grid>
                    <Grid item order={{ xs: 1, md: 2 }} xs={12} md={4}>
                        <Stack height={{ xs: '40vh', md: '80vh' }} direction={'row'} sx={{ my: 1, justifyContent: 'center', overflow: 'hidden' }}>
                            <img style={{ height: '80vh', width: '100vw', objectFit: 'contain' }} src={'/pFrame.png'} ></img>
                        </Stack>
                    </Grid>
                </Grid>
                <Stack mt={2} rowGap={2} direction='column' justifyContent='center' alignItems='center'>
                    <Stack direction='row' gap={2} >
                        <Link target={'_blank'} href="https://www.instagram.com/astengeapp/?igshid=MzRlODBiNWFlZA==" underline="none">
                            <Instagram style={{ color: 'gray' }}></Instagram>
                        </Link>
                        <Link target={'_blank'} href="https://www.facebook.com/profile.php?id=100092570389153&mibextid=LQQJ4d" underline="none">
                            <Facebook style={{ color: 'gray' }}></Facebook>
                        </Link>
                        <Link target={'_blank'} href="http://Twitter.com/astengeapp" underline="none">
                            <Twitter style={{ color: 'gray' }} ></Twitter>
                        </Link>
                    </Stack>
                    <Typography color='gray'>© 2023 Astenge. All rights reserved. Addis Ababa, Ethiopia. </Typography>
                </Stack>
            </Container>
        </div>
    )
}

export default Waitlist