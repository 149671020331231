import { clientInstance } from "../config/config";

export let createRequestAPI = async (request) => {
    return clientInstance.post(`/request`, request);
}

export let adminApproveRequestAPI = async (requestId) => {
    return clientInstance.put(`/request/admin/approve/${requestId}`);
}

export let adminRejectRequestAPI = async (requestId) => {
    return clientInstance.put(`/request/admin/reject/${requestId}`);
}

export let getStudentsRequestsAPI = async () => {
    return clientInstance.get(`/request/student`);
}

export let getSudentApprovedRequestsAPI = async () => {
    return clientInstance.get(`/request/student/approved`);
}

export let pendingStudentRequestsAPI = async () => {
    return clientInstance.get(`/request/student/admin-approved`);
}

export let pendingStudentRequestsNotApprovedAPI = async () => {
    return clientInstance.get(`/request/student/pending`);
}

export let deleteRequestAPI = async (requestId) => {
    return clientInstance.delete(`/request/${requestId}`);
}