
import { clientInstance } from "../config/config";


export let createStudentAPI = async (userData) => {
    return clientInstance.post(`/student`, userData);
};

export let makeReviewAPI = async (reviewData) => {
    return clientInstance.post(`/review`, reviewData);
}

export let getTutorsAPI = async () => {
    return clientInstance.get(`/tutor/student`);
}
