import { clientInstance } from "../config/config";

export let createSessionAPI = async (sessionData) => {
    return clientInstance.post(`/session`, sessionData);
}

export let getStudentSessionsAPI = async (tutorId) => {
    return clientInstance.get(`/session/student/${tutorId}`);
}

export let getTutorSessionsAPI = async (studentId) => {
    return clientInstance.get(`/session/tutor/${studentId}`);
}

export let cancelSessionAPI = async (sessionId) => {
    return clientInstance.put(`/session/cancel/${sessionId}`);
}
