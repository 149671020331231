import { Facebook, Instagram, Twitter } from '@mui/icons-material'
import { Box, Button, CircularProgress, Grid, Link, Stack, TextField, Typography, useTheme } from '@mui/material'
import React from 'react'
import ContactUs from '../contactUs/contactUs'

function Footer() {
    let theme = useTheme()
    return (
        <Box sx={{
            minHeight: '35vh',
            backgroundColor: theme.palette.primary.main,
            overflow: 'hidden',
            p: 3
        }}>
            <Grid container>
                <Grid md={4} xs={12} p={3} order={{ md: 1, xs: 2 }} >
                    <Box display={'flex'} flexDirection='column' alignItems={'center'} justifyContent='center'>
                        <Box m={0}>
                            <img src="/dark-circular-logo.png" alt='logo' style={{
                                width: '80px',
                                // height: '100px'
                            }}>
                            </img>
                        </Box>
                        <Typography variant='h4' color='white'>Astenge</Typography>
                        {/* <Box> */}
                        <Stack mt={2} rowGap={2} direction='column' height={'100%'} justifyContent='center' alignItems='center'>
                            <Stack direction='row' gap={2} >
                                <Link target={'_blank'} href="https://www.instagram.com/astengeapp/?igshid=MzRlODBiNWFlZA==" underline="none">
                                    <Instagram style={{ color: 'white' }}></Instagram>
                                </Link>
                                <Link target={'_blank'} href="https://www.facebook.com/profile.php?id=100092570389153&mibextid=LQQJ4d" underline="none">
                                    <Facebook style={{ color: 'white' }}></Facebook>
                                </Link>
                                <Link target={'_blank'} href="http://Twitter.com/astengeapp" underline="none">
                                    <Twitter style={{ color: 'white' }} ></Twitter>
                                </Link>
                            </Stack>
                            <Typography textAlign={'center'} color='white'>© 2024 Astenge. All rights reserved. Addis Ababa, Ethiopia. </Typography>
                        </Stack>
                        {/* </Box> */}

                    </Box>

                </Grid>
                <Grid md={8} xs={12} display='flex' order={{ md: 2, xs: 1 }} alignItems={'center'} justifyContent='center'>
                    <Stack>

                        <Typography fontSize={25} color='white' textAlign={'center'}>Contact Us</Typography>
                        <ContactUs></ContactUs>
                    </Stack>
                </Grid>
            </Grid>

        </Box>
    )
}

export default Footer