import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Chip,
  Avatar,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Sidebar from "../sideBar";
import { allRejectedTutors } from "../../../state/slices/admin";
import { useSelector, useDispatch } from "react-redux";
import { GridOverlay } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import Loading from "../../../components/loading/loading";
import TutorDetailPage from "../TutordetailPage";

const RejectedTutorPage = () => {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedTutor, setSelectedTutor] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const {rejectedTutors , loading} = useSelector((state) => state.admin);
  useEffect(() => {
    dispatch(allRejectedTutors());
  }, [dispatch]);

  const columns = [
    { field: "user", headerName: "User", width: 150 },
    { field: "subject", headerName: "Subject", width: 150 },
    { field: "department", headerName: "Department", width: 200 },
    { field: "University_Name", headerName: "University Name", width: 200 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "location", headerName: "Location", width: 150 },
    { field: "availability", headerName: "Availability", width: 150 },
    { field: "rate", headerName: "Rate", width: 120 },
    { field: "student", headerName: "Student", width: 150 },
    { field: "graduate", headerName: "Graduate", width: 150 },
  ];

  const rows = rejectedTutors.map((tutor) => ({
    id: tutor._id,
    user: `${tutor.userId.firstName} ${tutor.userId.lastName}`,
    subject: tutor.subjects,
    department: tutor.education.department,
    University_Name: tutor.education.universityName,
    status: tutor.approved,
    location: tutor.location,
    availability: tutor.availability,
    rate: tutor.rate,
    student: !tutor.education.graduated,
    graduate: tutor.education.graduated,
  }));


  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          color: "secondary",
        }}
      >
        <Typography variant="h6">No Rejected Tutors</Typography>
      </Box>
    </GridOverlay>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box p={3}>
          <Typography variant="h4" mb={3}>
            Tutor Details
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div style={{ height: 600, width: "100%" }}>
              {
                loading ? (
                  <Loading />
                ) : (
                  <DataGrid
                columns={columns}
                rows={rows}
                columnHeaderHeight={60}
                onCellClick={(e) => {
                  const tutor = rejectedTutors.find(
                    (tutor) => tutor._id === e.row.id
                  );
                  setSelectedTutor(tutor);
                  handleOpen();
                }}
                components={{
                  NoRowsOverlay: CustomNoRowsOverlay,
                }}
              />
                )
              }
            </div>
          </Paper>
        </Box>
      </Grid>
      {selectedTutor && <TutorDetailPage open={open} handleClose={handleClose} data={selectedTutor} />}
    </Grid>
  );
};

export default RejectedTutorPage;
