import * as React from "react";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Avatar, Card, Grid, useTheme as Theme } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/loading/loading";
import { Person } from "@mui/icons-material";
import CustomCard from "./customCard";
import {
  getStudentsRequests,
  getSudentApprovedRequests,
  pendingStudentRequests,
  pendingStudentRequestsNotApproved
} from "../../state/slices/request";
import { Button, Modal, TextField, Stack, Rating, Snackbar, Alert } from "@mui/material";
import { clearStudent, makeReview } from "../../state/slices/studnent";
import { Star } from "@mui/icons-material";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    "aria-controls": `full-width-tabpanel-${index}`,
  };
}

export default function StudentRequest() {

  const [openModal, setOpenModal] = React.useState(false);
    const handlModaleOpen = () => setOpenModal(true);
    const handlModalClose = () => setOpenModal(false);
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);

    const review = useSelector((state) => state.student);

    const ReviewModal = ({tutorId}) => {
        const dispatch = useDispatch();
        const [rating , setRating] = React.useState(0);
        const [comment , setComment] = React.useState("");
        const [errors, setErrors] = React.useState({});
      
        
       
      
        const handleFormSubmit = (event) => {
          event.preventDefault();
          const isValid = validate();
          if (isValid) {
            setSnackbarOpen(true);
      
            dispatch(makeReview({
                tutorId,
                rating,
                comment,
            })
              )
            handlModalClose();
            
            
          }
        };

        
      
        const validate = () => {
          
          let errors = {};
          if (rating === 0) errors.rating = "Rating is required";
            
          if (comment === "") errors.comment = "Comment is required";
      
          setErrors(errors);
          return Object.keys(errors).length === 0;
        };
      
        return (
          <Box>
            
            <Modal open={openModal} onClose={handlModalClose}>
                <Box
                sx={{
                  p: 3,
                  backgroundColor: 'white',
                  borderRadius: 4,
                  maxWidth: 600,
                  mx: 'auto',
                  mt: '10vh',
                  maxHeight: '150vh',
                  overflowY: 'auto',
                }}
                >
                <form onSubmit={handleFormSubmit}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    textAlign: "center",
                  }}
                > 
                <Typography fontWeight={600} fontSize={30}>
              <Star /> Add Review
            </Typography>
            <Rating
                name="simple-controlled"
                value={rating}
                onChange={(event, newValue) => {
                setRating(newValue);
                }}
            />
            {errors.rating && (
                <Typography color="red" fontSize={20} fontWeight={400}>
                {errors.rating}
                </Typography>
            )}
            <TextField
                id="outlined-multiline-static"
                label="Comment"
                multiline
                rows={4}
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
                sx={{ mt: 2, width: "100%" }}
            />
            {errors.comment && (
                <Typography color="red" fontSize={20} fontWeight={400}>
                {errors.comment}
                </Typography>
            )}
            
            <Button
                variant="contained"
                sx={{ mt: 2, ml: 2 }}
                color="primary"
                type="submit"
            >
                Submit
            </Button>

          </Box>
          <Stack spacing={2}>
          
                </Stack>
                </form>
                </Box>
            </Modal>
          </Box>
        );
      }

  const theme = useTheme();
  const theme2 = Theme();
  const [value, setValue] = React.useState(0);
  const dispatch = useDispatch();
  const { loading, studentRequests } = useSelector((state) => state.request);
  const { success , error } = useSelector((state) => state.student);
  console.log(success , "success" , error , "error");

  React.useEffect(() => {
    dispatch(pendingStudentRequestsNotApproved());
  }, [dispatch]);


  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue);
    if (newValue === 0) {
      dispatch(pendingStudentRequestsNotApproved());
    }
    // else if (newValue === 1) {
    //   dispatch(pendingStudentRequests());
    // }
     else if (newValue === 1) {
      dispatch(getSudentApprovedRequests());
    }
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
    <>
    <Snackbar
              open={Boolean(success || error)}
              autoHideDuration={3000}
              onClose={() => {
                dispatch(clearStudent());
                if (!success.includes("Review")) {
                  dispatch(pendingStudentRequestsNotApproved());
                }
              }}
            
              message ={<Typography fontWeight={600} fontSize={20} color="secondary">
                {success || error}
        </Typography>}
        ></Snackbar>
    <Box
      mr={5}
      sx={{
        width: "100%",
        minHeight: "100vh",
      }}
    >
      
      <Box
        sx={{
          borderBottom: 1,
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          marginRight: 5,
        }}
      >
        <AppBar position="static">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="secondary"
            textColor="grey"
            variant="fullWidth"
            aria-label="full width tabs example"
          >
            <Tab label="Pending" {...a11yProps(0)} />
            {/* <Tab label="Approved by admin" {...a11yProps(1)} /> */}
            <Tab label="Approved" {...a11yProps(1)} />
          </Tabs>
        </AppBar>
      </Box>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <CustomCard
            requests={studentRequests}
            loading={loading}
            theme2={theme2}
            value={value}
            ReviewModal={ReviewModal}
            handlModaleOpen={handlModaleOpen}
          />
        </TabPanel>

        {/* <TabPanel value={value} index={1} dir={theme.direction}>
          <CustomCard
            requests={studentRequests}
            loading={loading}
            theme2={theme2}
            value={value}
            ReviewModal={ReviewModal}
            handlModaleOpen={handlModaleOpen}
          />
        </TabPanel> */}

        <TabPanel value={value} index={1} dir={theme.direction}>
          <CustomCard
            requests={studentRequests}
            loading={loading}
            theme2={theme2}
            value={value}
            ReviewModal={ReviewModal}
            handlModaleOpen={handlModaleOpen}
          />
        </TabPanel>
      </SwipeableViews>
    </Box>
    </>
  );
}
