
import axios from "axios";
import { LOCATION_API_TOKEN, clientInstance } from "../config/config";


export let signupAPI = async (userData) => {
    return clientInstance.post(`/user/signup`, userData);
};
export let loginAPI = async (userData) => {
    return clientInstance.post(`/user/login`, userData);
}
export let updateProfileAPI = async (userData) => {
    return clientInstance.put(`/user/update`, userData);
}
export let updatePasswordAPI = async (userData) => {
    return clientInstance.put(`/user/update-password`, userData);
}
export let forgotPasswordAPI = async (userData) => {
    return clientInstance.post(`/user/forgot-password`, userData);
}
export let resetPasswordAPI = async (token,userData) => {
    return clientInstance.post(`/user/reset-password/${token}`, userData);
}
export let getCurrentUserLocationNameAPI = async (coordinate) => {
    console.log('in api call',coordinate)
    const latitude = coordinate.latitude;
    const longitude = coordinate.longitude;
    const url = `https://us1.locationiq.com/v1/reverse.php?key=${LOCATION_API_TOKEN}&lat=${latitude}&lon=${longitude}&format=json`
    return axios.get(url, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
}