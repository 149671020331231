import { clientInstance } from "../config/config";

export let searchAPI = async (searchData) => {
    let queryParams = constructSearchParams(searchData);

    return clientInstance.get(`/search${queryParams}`);
}

function constructSearchParams(filterData) {
    let queryParams = '';
    for (let key in filterData) {
      if (Array.isArray(filterData[key])) {
        for (let val of filterData[key]) {
          queryParams += `${key}[]=${val}&`;
        }
      }
      else if (filterData[key] === null || filterData[key] === undefined || filterData[key] === '') {
        continue;
        }
       else {
        queryParams += `${key}=${filterData[key]}&`;
      }
    }
  
    if (queryParams !== '') {
      queryParams = '?' + queryParams.substring(0, queryParams.length - 1);
    }
    console.log(queryParams,"queryParams123456789")
    return queryParams;
  }
  