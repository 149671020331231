import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Stack,
  Avatar,
  Drawer,
  Chip,
  useMediaQuery,
  FormControlLabel,
  Switch,
  Pagination,
  PaginationItem,
  Rating
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import PrimaryButton from "../../components/button/button";
import CenteredBox from "../../components/centeredBox/centeredBox";
import { useSelector, useDispatch } from "react-redux";
import { LocationOn } from "@mui/icons-material";
import { searchTutors } from "../../state/slices/search";
import { useNavigate } from "react-router-dom";
import Loading from "../../components/loading/loading";
import { extractId } from "../../utils/imageIdExtractor";

function App() {
  const theme = useTheme();
  const navigate = useNavigate();

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const SearchState = useSelector((state) => state.search);
  console.log(SearchState.tutors);
  const loading = SearchState.loading;

  const handleFilterOpen = () => {
    setIsFilterOpen(true);
  };

  const handleFilterClose = () => {
    setIsFilterOpen(false);
  };

  const dispatch = useDispatch();
  console.log(SearchState);
  const [formData, setFormData] = useState({
    subjects: SearchState.filter ? SearchState.filter.subjects : [],
    minPrice: "",
    maxPrice: "",
    universities: [],
    locations: [],
    tutoringOptions: SearchState.filter
      ? SearchState.filter.tutoringOptions
      : [],
    pageSize: 10,
    pageNumber: 1,
  });

  const handleTutoringTypeChange = (event) => {
    const { value } = event.target;
    const selectedTypes = [...formData.tutoringOptions];

    if (event.target.checked) {
      selectedTypes.push(value);
    } else {
      const index = selectedTypes.indexOf(value);
      if (index !== -1) {
        selectedTypes.splice(index, 1);
      }
    }
    setFormData({
      ...formData,
      tutoringOptions: selectedTypes,
    });
  };

  const handleFilterSubmit = () => {
    dispatch(searchTutors(formData));
    handleFilterClose();
  };

  const handlePageChange = (event, page) => {
    setFormData({
      ...formData,
      pageNumber: page,
    });
    dispatch(searchTutors({ ...formData, pageNumber: page }));
  };

  return (
    <>
      {
        loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <Loading />
          </Box>
        ) : (
          <Stack width={"100%"}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 5,
                mb: 5,
              }}
            >
              <Typography variant="h4" component="h1">
                {SearchState.totalCount} Results
              </Typography>
            </Box>
            <Box>
              <Grid container>
                <Grid item xs={12} md={3}>
                  <Box sx={{ display: { xs: "none", md: "block" } }}>
                    <Filter
                      handleFilterSubmit={handleFilterSubmit}
                      handleTutoringTypeChange={handleTutoringTypeChange}
                      formData={formData}
                      setFormData={setFormData}
                    />
                  </Box>
                  <Box
                    justifyContent={"end"}
                    sx={{ display: { xs: "flex", md: "none" }, margin: "16px" }}
                  >
                    <Button
                      onClick={handleFilterOpen}
                      sx={{
                        background: theme.palette.primary.main,
                        minWidth: "120px",
                        color: theme.palette.secondary.main,
                        "&:hover": {
                          background: theme.palette.primary.dark,
                        },
                      }}
                    >
                      <Typography fontWeight={600}>Filter</Typography>
                    </Button>
                    <Drawer
                      anchor="right"
                      open={isFilterOpen}
                      onClose={handleFilterClose}
                    >
                      <Filter
                        handleFilterSubmit={handleFilterSubmit}
                        handleTutoringTypeChange={handleTutoringTypeChange}
                        formData={formData}
                        setFormData={setFormData}
                      />
                    </Drawer>
                  </Box>
                </Grid>
                <Grid item xs={12} md={9}>
                  <Grid container rowGap={2}>
                    {SearchState.tutors.map((person) => (
                      <Card
                        sx={{ width: "100%", mx: { xs: 4, md: 6 }, mb: 2 }}
                        onClick={() =>
                          navigate("/detail-result", { state: { person } })
                        }
                      >
                        <CardContent>
                          <Grid container spacing={2}>
                            <Grid item xs={12} md={2}>
                              <Box
                                sx={{
                                  borderRadius: "100%",
                                  overflow: 'hidden',
                                  width: 100,
                                  height: 100,
                                }}>

                                <img
                                  crossOrigin="anonymous"
                                  src={person.profileImage?.includes('drive.google.com') ? `https://drive.lienuc.com/uc?id=${extractId(person.profileImage)}` : person.profileImage}
                                  alt="profile"
                                  style={{ width: "100px", height: "100px", objectFit: "cover" }}
                                />
                              </Box>


                            </Grid>
                            <Grid item xs={12} md={10}>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  gutterBottom
                                  variant="h5"
                                  component="div"
                                  fontWeight={600}
                                  color={"primary"}
                                >
                                  {person.firstName} {person.lastName}
                                </Typography>
                                <Typography
                                  variant="h6"
                                  component="div"
                                  color={"primary"}
                                  fontWeight={600}
                                >
                                  {person.rate} ETB / Hour
                                </Typography>
                              </Box>
                              <Box width={"50%"}>
                                <Typography
                                  textAlign={"justify"}
                                  variant="body2"
                                  color="text.secondary"
                                  style={{ wordWrap: "break-word" }}
                                >
                                  {person.experience.slice(
                                    0,
                                    Math.min(
                                      isMobile ? 50 : 100,
                                      person.experience.length
                                    )
                                  ) + (person.experience.length > 100 ? "..." : "")}
                                </Typography>
                              </Box>
                              {person.subjects.map((subject) => (
                                <Chip
                                  label={subject}
                                  sx={{
                                    margin: "2px",
                                    background: theme.palette.secondary.main,
                                    color: theme.palette.primary.main,
                                    fontWeight: 600,
                                  }}
                                  variant="outlined"
                                />
                              ))}
                              <Typography
                                variant="body2"
                                color="primary"
                                fontWeight={600}
                              >
                                <LocationOn />
                                {person.location}
                              </Typography>
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  fontWeight={600}
                                >
                                  <Rating name="read-only" value={person.ratings} readOnly />
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="primary"
                                  fontWeight={600}
                                >
                                  {person.totalReviews} Reviews
                                </Typography>

                              </Box>
                            </Grid>
                          </Grid>
                        </CardContent>
                      </Card>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center', margin: '32px' }}>
              <Pagination
                count={Math.ceil(SearchState.totalCount / formData.pageSize)}
                page={formData.pageNumber}
                onChange={handlePageChange}
                color="primary"
                sx={{
                  '& .MuiPaginationItem-root': {
                    fontSize: '1.25rem',
                  },
                  '& .Mui-selected': {
                    color: 'red',
                  },
                }}
                renderItem={(item) => (
                  <PaginationItem
                    component="button"
                    {...item}
                    sx={{
                      '&.Mui-selected': {
                        color: 'red',
                      },
                    }}
                  />
                )}
                boundaryCount={1}
                showFirstButton
                showLastButton
                siblingCount={1}
                hidePrevButton={formData.pageNumber === 1}
                hideNextButton={formData.pageNumber === Math.ceil(SearchState.totalCount / formData.pageSize)}
              />
            </Box>
          </Stack>)
      }
    </>
  );
}

const Filter = ({
  setFormData,
  formData,
  handleTutoringTypeChange,
  handleFilterSubmit,
}) => {
  const theme = useTheme();

  return (
    <Card sx={{ p: 2, margin: "16px" }}>
      <CardContent>
        <Typography
          textAlign={"center"}
          fontWeight={600}
          gutterBottom
          variant="h5"
          component="div"
          color={"primary"}
        >
          Filter
        </Typography>
        <Box>
          <Typography variant="body2" color="primary" fontWeight={600}>
            Subject
          </Typography>
          <TextField
            id="outlined-basic"
            label="Subject"
            variant="outlined"
            onChange={(event) =>
              setFormData({ ...formData, subjects: [event.target.value] })
            }
            fullWidth
            margin="normal"
            value={formData.subjects}
          />
        </Box>
        <Box>
          <Typography variant="body2" color="primary" fontWeight={600}>
            Price
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Min"
                variant="outlined"
                onChange={(event) =>
                  setFormData({ ...formData, minPrice: event.target.value })
                }
                margin="normal"
                value={formData.minPrice}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="outlined-basic"
                label="Max"
                variant="outlined"
                margin="normal"
                onChange={(event) =>
                  setFormData({ ...formData, maxPrice: event.target.value })
                }
                value={formData.maxPrice}
              />
            </Grid>
          </Grid>

          <Typography variant="body2" color="primary" fontWeight={600}>
            <LocationOn />
            Location
          </Typography>
          <TextField
            id="outlined-select-currency"
            label="Location"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(event) =>
              setFormData({ ...formData, locations: [event.target.value] })
            }
            value={formData.locations}
          />

          <Typography variant="body2" color="primary" fontWeight={600}>
            Tutoring Type
          </Typography>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.tutoringOptions.includes("REMOTE")}
                    onChange={handleTutoringTypeChange}
                    value="REMOTE"
                    color="primary"
                  />
                }
                label="Online"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={formData.tutoringOptions.includes("IN_PERSON")}
                    onChange={handleTutoringTypeChange}
                    value="IN_PERSON"
                    color="primary"
                  />
                }
                label="In Person"
              />
            </Grid>
          </Grid>

          <Typography variant="body2" color="primary" fontWeight={600}>
            University
          </Typography>
          <TextField
            id="outlined-select-currency"
            label="University"
            variant="outlined"
            fullWidth
            margin="normal"
            onChange={(event) =>
              setFormData({ ...formData, universities: [event.target.value] })
            }
            value={formData.universities}
          />
        </Box>
        <CenteredBox>
          <Stack justifyContent={"center"}>
            <Button
              onClick={handleFilterSubmit}
              sx={{
                background: theme.palette.primary.main,
                minWidth: "120px",
                color: theme.palette.secondary.main,
                "&:hover": {
                  background: theme.palette.primary.dark,
                },
              }}
            >
              <Typography fontWeight={600}> Apply Filter</Typography>
            </Button>
          </Stack>
        </CenteredBox>
      </CardContent>
    </Card>
  );
};

export default App;
