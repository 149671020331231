import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Modal,
  Backdrop,
  Fade,
  Stack,
  Autocomplete,
  Avatar,
  MenuItem,
  Snackbar,
} from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Add } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { Laptop, Home } from "@mui/icons-material";
import {
  createSession,
  clearSession,
  getTutorSessions,
  cancelSession,
} from "../../state/slices/session";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/loading/loading";

//list of students name

const TUTORING_TYPE = {
  IN_PERSON: "IN_PERSON",
  ONLINE: "REMOTE",
};

const Detail_class_room = () => {
  const location = useLocation();
  const student = location.state.student;
  const [open, setOpen] = React.useState(false);


  const { sessions, loading, error, success , session} = useSelector(
    (state) => state.session
  );

  console.log(session , "session", loading , "loading" );

  

  const dispatch = useDispatch();

  const handleSessionsFetch = () => {
    dispatch(getTutorSessions(student.student.id));
  };


  React.useEffect(() => {
    handleSessionsFetch();
  }, [dispatch]);

  

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const CreateMeetingButton = () => {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
        }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={handleOpen}
          sx={{
            width: 200,
            height: 40,
            borderRadius: 5,
            boxShadow: 24,
            bgcolor: "primary.main",
            color: "white",
            "&:hover": {
              backgroundColor: "primary.dark",
            },
          }}
        >
          <Add />{" "}
          <Typography variant="body1" component="p">
            Create Session
          </Typography>
        </Button>
      </Box>
    );
  };

  const CreateMeetingModalPage = ({ open, handleClose }) => {
    const [meeting, setMeeting] = React.useState({
      name: student.name,
      date: dayjs(),
      startTime: dayjs(),
      endTime: dayjs().add(1, "hour"),
      tutoring_type: "",
      note: "",
    });
    const [errors, setErrors] = React.useState({});

    const validate = () => {
      let temp = {};
      temp.tutoringType = meeting.tutoring_type
        ? ""
        : "Tutoring Type is required";
      temp.note = meeting.note ? "" : "Note is required";
      setErrors({ ...temp });
      return Object.values(temp).every((x) => x === "");
    };

    const handleSubmit = () => {
      if (!validate()) return;
      const newMeeting = {
        studentId: student.student.id,
        tutoringType: meeting.tutoring_type,
        note: meeting.note,
        startingTime: combineDateAndTime(meeting.date, meeting.startTime),
        endingTime: combineDateAndTime(meeting.date, meeting.endTime),
      };
      dispatch(createSession(newMeeting));
      handleClose();
      
    };

    return (
      <Modal open={open} onClose={handleClose}>
        <Box
          sx={{
            borderRadius: 5,
            bgcolor: "background.paper",
            boxShadow: 24,
            maxWidth: 1100,
            mx: "auto",
            mt: "5vh",
            minHeight: "40vh",
          }}
        >
          <Typography
            id="transition-modal-title"
            variant="h6"
            component="h2"
            gutterBottom
            color={"primary"}
            sx={{ textAlign: "center" }}
          >
            Create Session
          </Typography>
          <Divider />
          <Box sx={{ flexGrow: 1 }} />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Grid container >
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      justifyContent: "start",
                      height: 400,
                      m: 2,
                    }}
                    >
                      <DemoContainer
                    sx={{
              
                      border: "1px solid #ddd",
                      borderRadius: 1,
                    }}
                    components={["StaticDatePicker"]}
                  >
                    <DemoItem label="choose date">
                      <StaticDatePicker
                      orientation="landscape"
                        defaultValue={meeting.date}
                        onChange={(newValue) => {
                          setMeeting({ ...meeting, date: newValue });
                        }}
                      />
                    </DemoItem>
                  </DemoContainer>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box
                    sx={{
                      justifyContent: "start",
                      width: "auto",
                      mx: "auto",
                      my: 4,
                      p: 2,
                    }}
                  >
                    <Grid container spacing={3}>
                      <DemoContainer
                        sx={{
                          width: "auto",
                          mx: "auto",
                          my: 4,
                          p: 2,
                          border: "1px solid #ddd",
                          borderRadius: 1,
                        }}
                        components={["TimePicker"]}
                      >
                        <Grid item xs={12} md={6}>
                          <TimePicker
                            label="Start time"
                            value={meeting.startTime}
                            onChange={(newValue) => {
                              setMeeting({ ...meeting, startTime: newValue });
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <TimePicker
                            label="End time"
                            value={meeting.endTime}
                            onChange={(newValue) => {
                              setMeeting({ ...meeting, endTime: newValue });
                            }}
                          />
                        </Grid>
                      </DemoContainer>
                    </Grid>

                    <TextField
                      fullWidth
                      id="tutoringType"
                      label="Tutoring Type"
                      name="Tutoring Type"
                      value={meeting.tutoring_type}
                      error={Boolean(errors["tutoringType"])}
                      helperText={errors["tutoringType"]}
                      onChange={(event) => {
                        setMeeting({
                          ...meeting,
                          tutoring_type: event.target.value,
                        });
                      }}
                      select
                    >
                      <MenuItem value={TUTORING_TYPE.IN_PERSON}>
                        In Person
                      </MenuItem>
                      <MenuItem value={TUTORING_TYPE.ONLINE}>Online</MenuItem>
                    </TextField>

                    <TextField
                      multiline
                      minRows={4}
                      fullWidth
                      margin="normal"
                      id="outlined-basic"
                      label="Note"
                      value={meeting.note}
                      onChange={(event) => {
                        setMeeting({
                          ...meeting,
                          note: event.target.value,
                        });
                      }}
                      error={Boolean(errors["note"])}
                      helperText={errors["note"]}
                    />
                  </Box>
                </Grid>
              </Grid>
              <Box sx={{ flexGrow: 1 }} />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  p: 2,
                }}
              >
                <Button
                  sx={{
                    width: 200,
                    height: 40,
                    borderRadius: 5,
                    boxShadow: 24,
                    bgcolor: "primary.main",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  <Typography variant="body1" component="p">
                    Create session
                  </Typography>
                </Button>
              </Box>
            </LocalizationProvider>
          </Stack>
        </Box>
      </Modal>
    );
  };
  return (
    <>
      <Snackbar
        open={success == "Session created successfully" || success == "Session canceled successfully" || error}
        autoHideDuration={3000}
        onClose={() => {
          handleSessionsFetch();
          dispatch(clearSession());
        }}
        message={
          <Typography fontWeight={600} fontSize={20} color="secondary">
            {success || error}
          </Typography>
        }
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          p: 2,
        }}
      >
        <Card
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            alignSelf: "center",
            boxShadow: 24,
          }}
        >
          <Typography
            variant="h4"
            color={"primary"}
            sx={{
              textAlign: "center",
              margin: 2,
            }}
          >
            {student.student.firstName} {student.student.lastName} Class Room
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Avatar
              alt={student.student.firstName + " " + student.student.lastName}
              src={student.student.profileImage}
              sx={{ width: 100, height: 100 }}
            />
          </Box>

          <CreateMeetingButton />
        </Card>

        <CreateMeetingModalPage open={open} handleClose={handleClose} />
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          {loading || session ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Loading />
            </Box>
          ) : (
            <>
              {sessions.map((meeting) => (
                <Grid item xs={12} sm={6} md={4} lg={3} key={meeting._id}>
                  <Card
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                      height: "100%",
                      borderRadius: 5,
                      boxShadow: 24,
                      bgcolor: "background.paper",
                    }}
                  >
                    <CardContent>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                          color="primary"
                          fontWeight={600}
                          mr={1}
                          variant="h5"
                          component="h5"
                        >
                          Date:
                        </Typography>
                        <Typography variant="h6" component="h6">
                          {dayjs(meeting.startingTime).format("DD/MM/YYYY")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                          color="primary"
                          fontWeight={600}
                          mr={1}
                          variant="h5"
                          component="h5"
                        >
                          Time:
                        </Typography>
                        <Typography variant="h6" component="h6">
                          {dayjs(meeting.startingTime).format("hh:mm a")} -{" "}
                          {dayjs(meeting.endingTime).format("hh:mm a")}
                        </Typography>
                      </Box>
                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                          color="primary"
                          fontWeight={600}
                          mr={1}
                          variant="h5"
                          component={"h5"}
                        >
                          Tutoring Type:
                        </Typography>
                        <Typography variant="h6" component="h6">
                          {meeting.tutoringType === "REMOTE" ? (
                            <>
                              <Laptop
                                style={{
                                  verticalAlign: "middle",
                                  marginRight: "8px",
                                }}
                              />{" "}
                              online
                            </>
                          ) : (
                            <>
                              <Home
                                style={{
                                  verticalAlign: "middle",
                                  marginRight: "8px",
                                }}
                              />{" "}
                              in person
                            </>
                          )}
                        </Typography>
                      </Box>

                      <Box sx={{ display: "flex", alignItems: "baseline" }}>
                        <Typography
                          color="primary"
                          fontWeight={600}
                          mr={1}
                          variant="h5"
                          component="h5"
                        >
                          Note:
                        </Typography>
                        <Typography variant="h6" component="h6">
                          {meeting.note}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {meeting.tutoringType === "REMOTE" && (
                          <Button
                            disabled={
                              meeting.startingTime > dayjs() ||
                              meeting.endingTime < dayjs()
                            }
                            onClick={() => {
                              window.open(meeting.sessionLink);
                            }}
                            variant="contained"
                            color="primary"
                            sx={{
                              margin: 2,
                              width: 150,
                              height: 40,
                              borderRadius: 5,
                              boxShadow: 24,
                              bgcolor: "primary.main",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "primary.dark",
                              },
                            }}
                          >
                            <Typography variant="body1" component="p">
                              Join Session
                            </Typography>
                          </Button>
                        )}

                        <Button
                          disabled={meeting.startingTime < dayjs()}
                          onClick={() => {
                            dispatch(cancelSession(meeting._id));
                          }}
                          variant="contained"
                          color="error"
                          sx={{
                            margin: 2,
                            width: 100,
                            height: 40,
                            borderRadius: 5,
                            boxShadow: 24,
                            bgcolor: "error.main",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "error.dark",
                            },
                          }}
                        >
                          <Typography variant="body1" component="p">
                            Cancel
                          </Typography>
                        </Button>
                      </Box>
                    </CardContent>
                  </Card>
                </Grid>
              ))}
              {sessions.length === 0 && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    p: 2,
                    minHeight: "50vh",
                  }}
                >
                  <Typography variant="h1" component="h1">
                    No session
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Grid>
      </Box>
    </>
  );
};

const combineDateAndTime = (date, time) => {
  const combinedDateTime = dayjs(date)
    .set("hour", time.hour())
    .set("minute", time.minute());
  return combinedDateTime;
};

export default Detail_class_room;
