import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { searchAPI } from "../../services/search";

let initialState = {
    loading: false,
    error: null,
    successMsg: '',
    errorMsg: '',
    tutors: [],
    totalCount: 0,
    pageSize: 0,
    pageNumber: 0,
    filter: null
}

export let searchTutors = createAsyncThunk(
    'search',
    async (searchData, thunkApi) => {
        try {
            console.log(searchData,"searchData")
            let response = await searchAPI(searchData)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

let searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        clearSearch: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.tutors = []
            state.totalCount = 0
            state.pageSize = 0
            state.pageNumber = 0
        },
        setFilter:(state , action) => {
            state.filter = action.payload
        }
    },
    extraReducers: {
        [searchTutors.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.tutors = []
            state.totalCount = 0
            state.pageSize = 0
            state.pageNumber = 0
        }
        ,
        [searchTutors.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.tutors = action.payload.tutors
            state.totalCount = action.payload.totalCount
            state.pageSize = action.payload.pageSize
            state.pageNumber = action.payload.pageNumber
        }
        ,
        [searchTutors.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.tutors = []
            state.totalCount = 0
            state.pageSize = 0
            state.pageNumber = 0
        }
    }
})

export let { clearSearch,setFilter } = searchSlice.actions
let searchTutor = searchSlice.reducer
export default searchTutor