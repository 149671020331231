import React from "react";

import {
  Card,
  Grid,
  Avatar,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Person, Star } from "@mui/icons-material";
import Loading from "../../components/loading/loading";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { deleteRequest } from "../../state/slices/studnent";
import { makeReview } from "../../state/slices/studnent";
import Modal from "@mui/material/Modal";


const val = {
  0: "Pending",
  // 1: "Approved by Admin",
  1: "Approved by Tutor",
};

const CustomCard = ({
  requests,
  loading,
  theme2,
  value,
  ReviewModal,
  handlModaleOpen,
}) => {
  const [modal, setModal] = React.useState(false);
  const [selectedRequest, setSelecteRequest] = React.useState('');


  const handleCloseModal = () => setModal(false);

  const ModalPage = ({ modal, handleCloseModal }) => {
    let request = selectedRequest
    return (
      <Modal open={modal} onClose={handleCloseModal}>
        <Box
          sx={{
            p: 3,
            backgroundColor: "#f5f5f5",
            borderRadius: 4,
            maxWidth: 500,
            mx: "auto",
            mt: "10vh",
            overflowY: "auto",
            maxHeight: "80vh",

          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Avatar
              sx={{
                width: 100,
                height: 100,
                backgroundColor: theme2.palette.primary.main,
              }}
            >
              {request.tutorImageUrl ? (
                <img
                  src={request.tutorImageUrl}
                  alt="tutor"
                  width="100%"
                  height="100%"
                />
              ) : (
                <Person sx={{ width: 100, height: 100 }} />
              )}
            </Avatar>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Tutor Name
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.studentName}
            </Typography>

            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Tutoring Type
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.tutoringType?.includes("REMOTE") && 'Online'}
              {request.tutoringType?.includes("IN_PERSON") && ' In Person'}

            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Starting Time
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.startingTime}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Message
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.message}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Request Date
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {/* yy/mm/dd hr/min/sec */}
              {new Date(request.date).toLocaleString(undefined,{ weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Admin Status
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.adminApproved}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Tutor Status
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.status}
            </Typography>
          </Box>
        </Box>
      </Modal>
    );
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { success, error } = useSelector((state) => state.student);
  

 

  // Function to handle the Card onClick event
  const handleCardClick = (event,request) => {
    // Check if the click originated from the "add Review" button
    if (event.target.tagName === 'BUTTON' && (event.target.textContent === 'add Review' || event.target.textContent === 'Delete')) {
      return;
    }

    // Otherwise, set the modal state to true
    setSelecteRequest(request)
    setModal(true);
  };
  return (
    <Box>
      <Box
        sx={{
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          marginRight: 5,
          borderRadius: 2,
          padding: 2,
          minHeight: "80vh",
        }}
      >
        {loading || success || error ? (
          <Loading />
        ) : requests.length === 0 ? (
          <Typography
            variant="h4"
            color="primary"
            sx={{ textAlign: "center" }}
            fontSize={50}
            fontWeight={600}
          >
            No {val[value]} Requests
          </Typography>
        ) : (
          <Grid container>
            {requests.map((request) => (
              <>
                <ModalPage
                  request={request}
                  modal={modal}
                  handleCloseModal={handleCloseModal}
                />
                <Grid item xs={12} sm={6} md={4} key={request._id}>
                  <ReviewModal tutorId={request.tutorId} />
                  <Card
                    onClick={(e)=>{handleCardClick(e,request)}}
                    sx={{
                      marginTop: 5,
                      marginBottom: 5,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 2,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 100,
                          height: 100,
                          backgroundColor: theme2.palette.primary.main,
                        }}
                      >
                        {request.tutorImageUrl ? (
                          <img
                            src={request.tutorImageUrl}
                            alt="tutor"
                            width="100%"
                            height="100%"
                          />
                        ) : (
                          <Person sx={{ width: 100, height: 100 }} />
                        )}
                      </Avatar>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Typography
                        color={"primary"}
                        fontSize={30}
                        fontWeight={600}
                      >
                        Tutor Name
                      </Typography>
                      <Typography
                        color={"primary"}
                        fontSize={20}
                        fontWeight={400}
                      >
                        {request.tutorName}
                      </Typography>

                      <Typography
                        color={"primary"}
                        fontSize={30}
                        fontWeight={600}
                      >
                        Date
                      </Typography>
                      <Typography
                        color={"primary"}
                        fontSize={20}
                        fontWeight={400}
                      >
                        {new Date(request.date).toLocaleString(undefined,{ weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
                      </Typography>
                      {value === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 2 }}
                            color="error"
                            onClick={() => {
                              dispatch(deleteRequest(request.requestId));
                              
                            }}
                          >
                            Delete
                          </Button>
                        </Box>
                      )}
                      {value === 1 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 2 }}
                            color="primary"
                            onClick={() => {
                              console.log("Review this tutor");
                              setModal(false)
                              handlModaleOpen();
                            }}
                          >
                            <Star sx={{ mr: 1 }} />
                            add Review
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        )}
      </Box>
      </Box>
  );
};

export default CustomCard;
