import { Box } from '@mui/material'
import React from 'react'

function CenteredBox({children}) {
  return (
    <Box display={'flex'} justifyContent={'center'}>
        {children}
    </Box>
  )
}

export default CenteredBox