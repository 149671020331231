import { Alert, Box, Button, Card, CircularProgress, Dialog, Grid, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CenteredBox from '../../components/centeredBox/centeredBox'
import { clearUserState, forgotPassword } from '../../state/slices/user'

function ForgotPassword() {
  let theme = useTheme()
  let userState = useSelector(state => state.user)
  let navigate = useNavigate()
  let dispatch = useDispatch()

  let [snackbarOpen, setSnackbarOpen] = useState(false)

  let [submitStatus, setSubmitStatus] = useState('')

  const [formData, setFormData] = useState({
    email: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Perform validation on the individual field as the user types
    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const validationErrors = { ...errors };

    if (fieldName === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      validationErrors.email = value.trim() && emailRegex.test(value) ? '' : 'Invalid email address';
    }

    setErrors((prev) => { return { ...prev, ...validationErrors } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    Object.keys(formData).forEach((fieldName) => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName]) {
        validationErrors[fieldName] = errors[fieldName];
      }
    });

    setSubmitStatus(prev => prev + 'x')

  };

  useEffect(() => {
    if (submitStatus && Object.keys(errors).filter(key => errors[key]).length === 0) {
      dispatch(forgotPassword({ ...formData }));
      console.log('Form submitted:', formData);
      setSnackbarOpen(true)
    } else {
      dispatch(clearUserState())
    }
  }, [submitStatus])

  return (
    <Box
      sx={{
        mb: 3
      }}>
      {!userState.loading && 
      <Snackbar
      open={snackbarOpen}
      autoHideDuration={6000}
      onClose={() => setSnackbarOpen(false)}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      message={(<Typography fontWeight={600} fontSize={20} color='secondary'>
        {userState.successMsg ? "Email sent successfully. Please check your email." : "Email not found"}
        </Typography>)} 
    />}
      <Card 
        elevation={10}
        sx={theme => ({
          borderRadius: 4,
          minHeight: '70vh',
          [theme.breakpoints.up('md')]: {
            p: 5,
            mx: 10,
            mt: 3,
          },
          [theme.breakpoints.up('xs')]: {
            p: 2,
            mx: 2,
            mt: 3
          },
          display: 'flex',

        })}
      >
        <Box sx={{
          width: { xs: 'none', md: '50%' },
          display: { xs: 'none', md: 'flex' },
          justifyContent: 'center',
        }}
        >
          <img src='/3227472.jpg' alt='forgot password' width='100%' height='100%' />
        </Box>

        <Box sx={{
          width: { xs: '100%', md: '50%' },
          display: 'flex',
          justifyContent: 'center',
          padding: '0 1rem',
          flexDirection: 'column',
        }}
        >
          <Box
            sx={{
              color: theme.palette.primary.main,
              borderRadius: 2,
              mb: 6,

            }} >
            <Typography fontWeight={900} fontSize={40} textAlign={'center'}>
              Forgot Password
            </Typography>
          </Box>
          <Box sx={{ mb: 5 }}>
            <Typography textAlign={'center'} fontWeight={600} fontSize={20} color='secondary'>
              Enter your email to reset your password
            </Typography>
          </Box>

          <Stack mx={{ lg: 15, md: 15, xs: 1 }} justifyContent='start' rowGap={3}>
            <TextField
              label='Email'
              variant='outlined'
              name='email'
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <CenteredBox >
              <Stack direction={'column'} justifyContent='center' rowGap={2}>
                {
                  userState.errorMsg && <Alert severity="error">{userState.errorMsg}</Alert>
                }
                <Button
                  onClick={handleSubmit}
                  disabled={userState.loading}
                  sx={{
                    background: theme.palette.secondary.main,
                    minWidth: '120px',
                    color: theme.palette.primary.main,
                    textTransform: 'capitalize',
                    "&:hover": {
                      background: theme.palette.secondary.dark,
                    }
                  }} disableElevation variant='contained'>
                  {
                    userState.loading ? <CircularProgress size={25}></CircularProgress> :
                      <Typography fontWeight={600}>
                        Forgot Password
                      </Typography>
                  }
                </Button>
              </Stack>
            </CenteredBox>
          </Stack>
        </Box>
      </Card>
      <Dialog
  open={userState.successMsg}
  onClose={() => navigate('/login')}
  aria-labelledby="alert-dialog-title"
  aria-describedby="alert-dialog-description"
>
  <Box sx={{ p: 5 }}>
    <Typography variant="h4" color="primary" fontWeight={600} textAlign="center">
    Email sent successfully. Please check your email.
    </Typography>
    <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: 5 }}>
      <Button onClick={() => navigate('/login')} variant="contained" color="secondary" disableElevation>
        <Typography fontWeight={600}>
          OK
        </Typography>
      </Button>
    </Box>
  </Box>
</Dialog>

    </Box>
  )
}

export default ForgotPassword


