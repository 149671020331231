import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Button,
  Grid,
  Chip,
  Avatar,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Sidebar from "../sideBar";
import {
  allPendingTutors,
  approveTutor,
  clearAdmin,
  rejectTutor,
} from "../../../state/slices/admin";
import { useSelector, useDispatch } from "react-redux";
import { GridOverlay } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import Loading from "../../../components/loading/loading";
import TutorDetailPage from "../TutordetailPage";
import { useNavigate } from "react-router-dom";

const PendingTutorPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const dispatch = useDispatch();
  const { pendingTutors, loading, successMsg, errorMsg } = useSelector(
    (state) => state.admin
  );
  if (successMsg) {
    dispatch(clearAdmin());
    dispatch(allPendingTutors());
  }
  useEffect(() => {
    dispatch(allPendingTutors());
  }, [dispatch]);

  const columns = [
    { field: "user", headerName: "User", width: 150 },
    { field: "subject", headerName: "Subject", width: 150 },
    { field: "department", headerName: "Department", width: 200 },
    { field: "University_Name", headerName: "University Name", width: 200 },
    { field: "status", headerName: "Status", width: 120 },
    { field: "location", headerName: "Location", width: 150 },
    { field: "availability", headerName: "Availability", width: 150 },
    { field: "rate", headerName: "Rate", width: 120 },
    {
      field: "actions",
      headerName: "Actions",
      width: 250,
      sortable: false,
      renderCell: (params) => (
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="success"
                onClick={() => {
                  dispatch(approveTutor(params.row.id));
                }}
                disabled={!selectedRow || selectedRow.id !== params.row.id}
              >
                Approve
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  console.log("Reject");
                  dispatch(rejectTutor(params.row.id));
                }}
                disabled={!selectedRow || selectedRow.id !== params.row.id}
              >
                Reject
              </Button>
            </Grid>
          </Grid>
        </Box>
      ),
    },
  ];

  const rows = pendingTutors.map((tutor) => ({
    id: tutor._id,
    user: `${tutor.userId.firstName} ${tutor.userId.lastName}`,
    subject: tutor.subjects,
    department: tutor.education.department,
    University_Name: tutor.education.universityName,
    status: tutor.approved,
    location: tutor.location,
    availability: tutor.availability,
    rate: tutor.rate,
  }));

  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          color: "secondary",
        }}
      >
        <Typography variant="h6">No Pending Tutors</Typography>
      </Box>
    </GridOverlay>
  );

  return (
    <Grid container>
      <Grid item xs={12} md={2}>
        <Sidebar />
      </Grid>
      <Grid item xs={12} md={10}>
        <Box p={3}>
          <Typography variant="h4" mb={3}>
            Tutor Details
          </Typography>
          <Paper elevation={3} sx={{ p: 2 }}>
            <div style={{ height: 600, width: "100%" }}>
              {loading ? (
                <Loading />
              ) : (
                <DataGrid
                  columns={columns}
                  rows={rows}
                  columnHeaderHeight={60}
                  onCellClick={(params) => {
                    const disabledColumns = ["actions"];
                    if (disabledColumns.includes(params.field)) return;
                    
                    setSelectedRow(params.row);
                    handleOpen();

                  }}
                  components={{
                    NoRowsOverlay: CustomNoRowsOverlay,
                  }}
                />
              )}
            </div>
          </Paper>
        </Box>
      </Grid>
      {selectedRow && (
        <TutorDetailPage
          open={open}
          handleClose={handleClose}
          data={pendingTutors.find((tutor) => tutor._id === selectedRow.id)}
        />
      )}
    </Grid>
  );
};

export default PendingTutorPage;
