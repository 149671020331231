import {
  Typography,
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  colors,
  Avatar,
  Chip,
  Autocomplete,
  TextField,
  InputAdornment,
  IconButton,
} from "@mui/material";
import React,{useEffect} from "react";
import { useNavigate } from "react-router-dom";
import { getStudents, clearTutor } from "../../state/slices/tutor";
import { useSelector, useDispatch } from "react-redux";
import Loading from "../../components/loading/loading";
import { Search } from "@mui/icons-material";
import dayjs from "dayjs";

const TutorClassRoom = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { students, loading, errorMsg, successMsg } = useSelector(
    (state) => state.tutor
  );
  const [searchStudent, setSearchStudent] = React.useState([]);

  const handleStudentsFetch = () => {
    dispatch(getStudents());
  };

  useEffect(() => {
    handleStudentsFetch();
  }, [dispatch]);

  useEffect(() => {
    if (successMsg) {
      setSearchStudent(students);
      dispatch(clearTutor());
    }
  }, [successMsg, students, dispatch]);

  return loading ? (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <Loading />
    </Box>
  ) : (
    <Box
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          p: 2,
        }}
      >
        <Typography variant="h3" color={"primary"}>
          Tutor Class Room
        </Typography>
        <Autocomplete
          onChange={(event, newValue) => {
            if (newValue === null) {
              setSearchStudent(students);
            } else {
              setSearchStudent(
                students.filter(
                  (student) =>
                    student.student.firstName +
                      " " +
                      student.student.lastName ===
                    newValue
                )
              );
            }
          }}
          disablePortal
          id="combo-box-demo"
          options={students.map(
            (student) =>
              student.student.firstName + " " + student.student.lastName
          )}
          sx={{
            borderRadius: 54,
            width: 500,
            margin: 2,
            "& .MuiAutocomplete-inputRoot": {
              borderRadius: 54,
              backgroundColor: "white", // You can change the background color here
            },
            "& .MuiInputBase-root": {
              borderRadius: 54,
              backgroundColor: "white", // You can change the background color here
            },
            "& .MuiAutocomplete-paper": {
              borderRadius: 54,
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)", // Add a box shadow for the dropdown container
            },
          }}
          renderInput={(params) => (
            <TextField {...params} label="Student Name" 
            InputProps={{
              ...params.InputProps,

              startAdornment: (
                <InputAdornment position="start">
                          <IconButton>
                            <Search />
                          </IconButton>
                        </InputAdornment>
              ),
            }}
            />
          )}
        />
      </Box>
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          justifyContent: "center",
          p: 2,
        }}
      >
        {searchStudent.map((student) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={student.id}>
            <Card
              onClick={() => {
                navigate("/detail-class-room", { state: { student } });
              }}
              sx={{
                minWidth: 275,
                borderRadius: 5,
                boxShadow: 3,
                bgcolor: "background.paper",
                "&:hover": {
                  backgroundColor: colors.grey[300],
                },
              }}
            >
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    mb: 2,
                  }}
                >
                  <Avatar
                    alt={student.student.firstName}
                    src={student.student.profileImage}
                    sx={{ width: 100, height: 100 }}
                  />
                </Box>
                <Typography variant="h5" component="div" gutterBottom>
                  Student Name: {student.student.firstName}{" "}
                  {student.student.lastName}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  Phone Number: {student.student.phoneNumber}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  Email : {student.student.email}
                </Typography>
                <Typography variant="body1" component="div" gutterBottom>
                  Upcoming Class :{" "}
                  {student.upcomingSession
                    ? dayjs(student.upcomingSession.startingTime).format("DD/MM/YYYY hh:mm a") + " - " + dayjs(student.upcomingSession.endingTime).format("hh:mm a")
                    : "No Upcoming Class"}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      {searchStudent.length === 0 && (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Typography variant="h3" color={"primary"}>
            No Student Found
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default TutorClassRoom;
