import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import PrimaryButton from "../../components/button/button";
import CenteredBox from "../../components/centeredBox/centeredBox";
import Loading from "../../components/loading/loading";
import { clearUserState, signup } from "../../state/slices/user";
import validatePassword from "../../utils/passwordValidator";
import { updateContentType } from "../../config/config";

function Signup() {
  let theme = useTheme();
  let userState = useSelector((state) => state.user);
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let params = useParams();

  let roles = {
    Student: "STUDENT",
    Parent: "PARENT",
    Tutor: "TUTOR",
  };

  let [submitStatus, setSubmitStatus] = useState("");

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    checked: false,
  });

  const [errors, setErrors] = useState({});

  if (userState.successMsg) {
    dispatch(clearUserState());
    navigate("/confirmation");
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Perform validation on the individual field as the user types
    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const validationErrors = { ...errors };

    switch (fieldName) {
      case "firstName":
        validationErrors.firstName = value.trim()
          ? String(value).length < 3
            ? "First name too short"
            : ""
          : "First name is required";
        break;
      case "lastName":
        validationErrors.lastName = value.trim()
          ? String(value).length < 3
            ? "Last name too short"
            : ""
          : "Last name is required";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        validationErrors.email =
          value.trim() && emailRegex.test(value) ? "" : "Invalid email address";
        break;
      case "phoneNumber":
        const phoneRegex = /^09\d{8}$/;
        validationErrors.phoneNumber =
          value.trim() && phoneRegex.test(value) ? "" : "Invalid phone number";
        break;
      case "password":
        validationErrors.password = value.trim()
          ? validatePassword(String(value))
          : "Password is required";
        break;
      case "confirmPassword":
        validationErrors.confirmPassword =
          value === formData.password ? "" : "Passwords do not match";
        break;
      case "checked":
        validationErrors.checked = value
          ? ""
          : "Please agree to the terms and conditions";
        break;
      default:
        break;
    }

    setErrors((prev) => {
      return { ...prev, ...validationErrors };
    });
    // console.log(errors, 'in validation', validationErrors)
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    Object.keys(formData).forEach((fieldName) => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName]) {
        validationErrors[fieldName] = errors[fieldName];
      }
    });

    setSubmitStatus((prev) => prev + "x");
  };

  useEffect(() => {
    console.log("in effect", errors, submitStatus);
    if (
      submitStatus &&
      Object.keys(errors).filter((key) => errors[key]).length === 0
    ) {
      let userData = new FormData();
      userData.set("firstName", JSON.stringify(formData.firstName));
      userData.set("lastName", JSON.stringify(formData.lastName));
      userData.set("email", JSON.stringify(formData.email));
      userData.set("phoneNumber", JSON.stringify(formData.phoneNumber));
      userData.set("password", JSON.stringify(formData.password));
      userData.set("roles", JSON.stringify([roles[params.role]]));

      updateContentType("multipart/form-data")
      dispatch(signup(userData));
      updateContentType("application/json")
      console.log("Form submitted:", formData);
    } else {
      dispatch(clearUserState());
    }
  }, [params.role, submitStatus]);

  return (
    <Box
      sx={{
        mb: 3,
        minHeight: "90vh",
      }}
    >
      <Card
        elevation={10}
        sx={(theme) => ({
          borderRadius: 4,
          [theme.breakpoints.up("md")]: {
            p: 0,
            mx: 10,
            mt: 3,
          },
          [theme.breakpoints.up("xs")]: {
            p: 2,
            mx: 2,
            mt: 3,
          },
          display: "flex",
          minHeight: "80vh", // Added to enable flex layout
        })}
      >
        {/* Left Image */}
        <Box
          sx={{
            // if it is mobile, then don't show the image
            display: { xs: "none", md: "flex" },
            width: { xs: "none", md: "50%" }, // Adjust the width as needed
            justifyContent: "center",
            // display: 'flex',
          }}
        >
          {console.log(`/${roles[params.role]}.jpg`)}
          <img
            src={
              roles[[params.role]] === "STUDENT"
                ? `/STUDENT.jpeg`
                : (roles[[params.role]] === "PARENT" && `/PARENT.jpg`) || `/TUTOR.png`
            }
            alt="Left Image"
            style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }}
          />
        </Box>

        {/* Right Content */}
        <Box
          sx={{
            width: { xs: "100%", md: "50%" }, // Adjust the width as needed
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            padding: 2,
          }}
        >
          <Grid container p={2} alignItems="center" rowGap={2}>
            <Grid md={12} xs={6}>
              <Box
                px={2}
                py={1}
                mb={{ md: 0, xs: 2 }}
                sx={{
                  bgcolor: theme.palette.primary.main,
                  color: theme.palette.secondary.main,
                  width: "fit-content",
                  borderRadius: 2,
                }}
              >
                <Typography fontWeight={600}>
                  {params.role == "Student"
                    ? "For Students"
                    : params.role == "Tutor"
                    ? "For Tutors"
                    : "For Parents"}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={12}></Grid>
            <Grid md={12} xs={12}>
              <Typography
                textAlign={"center"}
                fontWeight={600}
                fontSize={20}
                color="secondary"
              >
                {params.role === "Student"
                  ? "Get access to expert tutors right at your finger tips.   "
                  : params.role === "Tutor"
                  ? "Make extra cash tutoring! Monetize your knowledge gained."
                  : "Get the best of Ethiopian knowledge talent for your student! "}
              </Typography>
            </Grid>
          </Grid>

          <Stack
            mx={{ lg: 15, md: 15, xs: 1 }}
            justifyContent="start"
            rowGap={2}
          >
            <TextField
              label="First name"
              variant="outlined"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              error={!!errors.firstName}
              helperText={errors.firstName}
            />
            <TextField
              label="Last name"
              variant="outlined"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              error={!!errors.lastName}
              helperText={errors.lastName}
            />
            <TextField
              label="Email"
              variant="outlined"
              name="email"
              value={formData.email}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.email}
            />
            <TextField
              label="Phone number"
              variant="outlined"
              name="phoneNumber"
              placeholder="09XXXXXXXX"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!errors.phoneNumber}
              helperText={errors.phoneNumber}
            />
            <TextField
              label="Password"
              variant="outlined"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              error={!!errors.password}
              helperText={errors.password}
            />
            <TextField
              label="Confirm password"
              variant="outlined"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword :formData.confirmPassword ?"Password matched":"" }
              FormHelperTextProps={{
                sx: {
                  color: 'green',
                }
              }}
            />
            {errors.checked && (
              <Typography color="error">{errors.checked}</Typography>
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "left",
                justifyContent: "left",
              }}
            >
              <Checkbox
                checked={formData.checked}
                onChange={(e) =>
                  setFormData({ ...formData, checked: e.target.checked })
                }
                inputProps={{ "aria-label": "controlled" }}
              />
              <Typography
                fontWeight={600}
                fontSize={18}
                color="primary"
                component="span"
              >
                I agree to the{" "}
                <Typography
                  sx={{ 
                    cursor: "pointer",
                    "&:hover": {
                      textDecoration: "underline",
                    },
                  }}
                  fontWeight={600}
                  fontSize={18}
                  color="secondary"
                  component="span"
                  onClickCapture={() =>
                    window.open(
                      "https://www.termsandconditionsgenerator.com/live.php?token=5NSM3TKiT2CGYMYqdEw1fkKfONkXj8FF"
                    )
                  }
                >
                  Terms and Conditions
                </Typography>
              </Typography>
            </Box>
            <CenteredBox>
              <Stack direction={"column"} justifyContent="center" rowGap={2}>
                {userState.errorMsg && (
                  <Alert severity="error">{userState.errorMsg}</Alert>
                )}
                <Button
                  onClick={handleSubmit}
                  disabled={userState.loading}
                  sx={{
                    background: theme.palette.secondary.main,
                    minWidth: "120px",
                    color: theme.palette.primary.main,
                    textTransform: "capitalize",
                    "&:hover": {
                      background: theme.palette.secondary.dark,
                    },
                  }}
                  disableElevation
                  variant="contained"
                >
                  {userState.loading ? (
                    <CircularProgress size={25}></CircularProgress>
                  ) : (
                    <Typography fontWeight={600}>Sign-up!</Typography>
                  )}
                </Button>
              </Stack>
            </CenteredBox>
            <Box>
              <Typography
                textAlign={"end"}
                fontWeight={600}
                fontSize={18}
                color="primary"
              >
                Already have an account?{" "}
                <Typography
                  fontWeight={600}
                  fontSize={18}
                  color="secondary"
                  component="span"
                  onClick={() => navigate("/login")}
                >
                  Login
                </Typography>
              </Typography>
            </Box>
          </Stack>
        </Box>
      </Card>
    </Box>
  );
}

export default Signup;
