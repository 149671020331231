import { Alert, Box, Button, Card, CircularProgress, Grid, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import PrimaryButton from '../../components/button/button'
import CenteredBox from '../../components/centeredBox/centeredBox'
import Loading from '../../components/loading/loading'
import { clearUserState, resetPassword } from '../../state/slices/user' // Import the resetPassword action
import validatePassword from '../../utils/passwordValidator'

function ResetPassword() { // Change the component name to ResetPassword
  let theme = useTheme()
  let userState = useSelector(state => state.user)
  let { token } = useParams()
  let navigate = useNavigate()
  let dispatch = useDispatch()

  let [submitStatus, setSubmitStatus] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    password: '',
    confirmPassword: '',
  });

  const [errors, setErrors] = useState({});

  if (userState.successMsg) {
    dispatch(clearUserState())
    navigate('/login')
  }

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    // Perform validation on the individual field as the user types
    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const validationErrors = { ...errors };

    switch (fieldName) {
      case 'password':
        validationErrors.password = value.trim() ? validatePassword(String(value)) : 'Password is required';
        break;
      case 'confirmPassword':
        validationErrors.confirmPassword = value.trim() ? value === formData.password ? '' : 'Passwords do not match' : 'Confirmation is required';
      default:
        break;
    }

    setErrors((prev) => { return { ...prev, ...validationErrors } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    Object.keys(formData).forEach((fieldName) => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName]) {
        validationErrors[fieldName] = errors[fieldName];
      }
    });

    setSubmitStatus(prev => prev + 'x')

  };

  useEffect(() => {
    if (submitStatus && Object.keys(errors).filter(key => errors[key]).length === 0) {
      dispatch(resetPassword({ formData, token })); // Dispatch the resetPassword action instead of login
      console.log('Form submitted:', formData);
      setSnackbarOpen(true)

    } else {
      dispatch(clearUserState())
    }
  }, [submitStatus])

  const snackbarHandleClose = () => {
    setSnackbarOpen(false)
  }

  return (
    <Box
      sx={{
        mb: 3
      }}>

      {!userState.loading &&
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={snackbarHandleClose}
          message={userState.successMsg || userState.errorMsg}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        />
      }

      <Card
        elevation={10}
        sx={theme => ({
          borderRadius: 4,
          minHeight: '70vh',
          [theme.breakpoints.up('md')]: {
            p: 0,
            mx: 10,
            mt: 3,
          },
          [theme.breakpoints.up('xs')]: {
            p: 2,
            mx: 2,
            mt: 3
          },
          display: 'flex',

        })}
      >
        {/* Left Image */}
        <Box
          sx={{
            // if it is mobile, then don't show the image
            display: { xs: 'none', md: 'flex' },
            width: { xs: 'none', md: '50%' }, // Adjust the width as needed
            justifyContent: 'center',
            // display: 'flex',
          }}
        >
          <img src="/6321602.jpg" alt="Left Image" style={{ maxWidth: '100%', maxHeight: '100%' }} />
        </Box>

        {/* Right Content */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' }, // Adjust the width as needed
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            padding: '0 16px', // Added some padding for better spaci
          }}
        >
          <Box
            sx={{
              color: theme.palette.primary.main,
              borderRadius: 2,
              mb: 6,

            }} >
            <Typography fontWeight={900} fontSize={40} textAlign={'center'}>
              Reset Password
            </Typography>
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography textAlign={'center'} fontWeight={600} fontSize={20} color='secondary'>
              Enter your new password to reset your password
            </Typography>
          </Box>

          <Stack mx={{ lg: 15, md: 15, xs: 1 }} justifyContent='start' rowGap={3}>
            <TextField
              label='Password'
              variant='outlined'
              name='password'
              type='password'
              value={formData.password}
              onChange={handleChange}
              error={!!errors.email}
              helperText={errors.password}
            />
            <TextField
              label='Confirm Password'
              variant='outlined'
              name='confirmPassword'
              type='password'
              value={formData.confirmPassword}
              onChange={handleChange}
              error={!!errors.confirmPassword}
              helperText={errors.confirmPassword ? errors.confirmPassword :formData.confirmPassword ?"Password matched":"" }
              FormHelperTextProps={{
                sx: {
                  color: 'green',
                }
              }}
            />
            <CenteredBox >
              <Stack direction={'column'} justifyContent='center' rowGap={2}>
                {
                  userState.errorMsg && <Alert severity="error">{userState.errorMsg}</Alert>
                }
                <Button
                  onClick={handleSubmit}
                  disabled={userState.loading}
                  sx={{
                    background: theme.palette.secondary.main,
                    minWidth: '120px',
                    color: theme.palette.primary.main,
                    textTransform: 'capitalize',
                    "&:hover": {
                      background: theme.palette.secondary.dark,
                    }
                  }} disableElevation variant='contained'>
                  {
                    userState.loading ? <CircularProgress size={25}></CircularProgress> :
                      <Typography fontWeight={600}>
                        Reset Password
                      </Typography>
                  }
                </Button>
              </Stack>
            </CenteredBox>
          </Stack>
        </Box>
      </Card>

    </Box>
  )
}

export default ResetPassword
