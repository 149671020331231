import React, { useState } from 'react';
import { TextField, Chip, Box, Button, Typography, Card, colors } from '@mui/material';
import { Add } from '@mui/icons-material';
import AddSubjectDialog from './AddSubjectDialog';

const TextFieldAndChip = ({ onChips, label, chipss, error }) => {
  const [value, setValue] = useState('');
  const [chips, setChips] = useState(chipss);

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      let newValues = value.split(',');
      let newChips = new Set([...chips, ...newValues]);
      console.log(newChips);
      setChips([...newChips]);
      setValue('');
    }
  };

  const handleDelete = (index) => {
    const newChips = [...chips];
    newChips.splice(index, 1);
    setChips(newChips);
  };

  const renderChips = () => {
    return chips.map((chip, index) => (
      <Chip
        key={index}
        label={chip}
        onDelete={() => handleDelete(index)}
        style={{ margin: 5 }}
      />
    ));
  };

  // Call the onChips callback function to return the array of values
  if (onChips && typeof onChips === 'function') {
    onChips(chips);
  }

  let addSubjectTagHandler = (newValue) => {
    if (newValue === '') {
      return;
    }
    let newChips = new Set([...chips, newValue]);
    console.log(newChips);
    setChips([...newChips]);
    setValue('');
  }

  return (
    <div>
      <Box >
        <Box display={'flex'} alignItems={{xs:'start',md:'center'}} gap={2} flexDirection={{xs:'column',md:'row'}}>
          <Box minWidth={{xs:"100%",md:'50%'}} border={'2px solid lightgrey'} py={1} px={1} display={'flex'} flexWrap={'wrap'}  justifyContent={'start'}>
            {
              chips.length > 0 ? renderChips() : <Typography color={"grey"} fontSize={20}> No subjects added yet</Typography>
            }
          </Box>
          <AddSubjectDialog value = {value} setValue={addSubjectTagHandler}></AddSubjectDialog>
        </Box>

      </Box>
      <br />
      {/* {renderChips()} */}
    </div>
  );
};

export default TextFieldAndChip;
