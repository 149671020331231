import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addTutorAPI,
  getTutorByUserAPI,
  updateTutorAPI,
  getApprovedRequestsAPI,
  getPendingRequestsAPI,
  getRejectedRequestsAPI,
  approveRequestAPI,
  rejectRequestAPI,
  getStudentsAPI
} from "../../services/tutor";
import { setProfileCompleted } from "./user";

export let getTutorByUser = createAsyncThunk(
  "tutor/user/:tutorId",
  async (tutorId, thunkApi) => {
    try {
      let response = await getTutorByUserAPI(tutorId);
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let updateTutor = createAsyncThunk(
  "tutor/update",
  async (tutorData, thunkApi) => {
    try {
      let userProfileStatus = thunkApi.getState().user.user?.profileStatus;
      let response = null;
      if (userProfileStatus === "COMPLETED") {
        response = await updateTutorAPI(tutorData);
      } else {
        response = await addTutorAPI(tutorData);
        thunkApi.dispatch(setProfileCompleted());
      }
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let getRequests = createAsyncThunk(
  "tutor/requests",
  async (tutorId, thunkApi) => {
    try {
      let response = await getPendingRequestsAPI();
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let getApprovedRequests = createAsyncThunk(
  "tutor/approvedRequests",
  async (tutorId, thunkApi) => {
    try {
      let response = await getApprovedRequestsAPI();
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let getRejectedRequests = createAsyncThunk(
  "tutor/rejectedRequests",
  async (tutorId, thunkApi) => {
    try {
      let response = await getRejectedRequestsAPI();
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let approveRequest = createAsyncThunk(
  "tutor/approveRequest",
  async (requestId, thunkApi) => {
    try {
      let response = await approveRequestAPI(requestId);
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let rejectRequest = createAsyncThunk(
  "tutor/rejectRequest",
  async (requestId, thunkApi) => {
    try {
      let response = await rejectRequestAPI(requestId);
      return response.data;
    } catch (error) {
      let errorMsg = error.response.data?.msg;
      return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
    }
  }
);

export let getStudents = createAsyncThunk(
    "tutor/students",
    async (tutorId, thunkApi) => {
        try {
            let response = await getStudentsAPI();
            return response.data;
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
);

let initialState = {
  loading: false,
  tutor: localStorage.getItem("tutor")
    ? JSON.parse(localStorage.getItem("tutor"))
    : null,
  error: null,
  successMsg: "",
  errorMsg: "",
  requests: [],
  students: [],
};

let tutorSlice = createSlice({
  name: "tutor",
  initialState,
  reducers: {
    clearTutor: (state, action) => {
      state.loading = false;
      state.error = null;
      state.successMsg = "";
      state.errorMsg = "";
    },
    setTutor: (state, action) => {
      state.tutor = action.payload;
    },
  },
  extraReducers: {
    [getTutorByUser.pending]: (state, action) => {
      state.loading = true;
    },
    [getTutorByUser.fulfilled]: (state, action) => {
      state.loading = false;
      state.tutor = action.payload.tutor;
      localStorage.setItem("tutor", JSON.stringify(action.payload.tutor));
    },
    [getTutorByUser.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [updateTutor.pending]: (state, action) => {
      state.loading = true;
    },
    [updateTutor.fulfilled]: (state, action) => {
      state.loading = false;
      state.tutor = action.payload.tutor;
      state.successMsg = "Tutor updated successfully";
      localStorage.setItem("tutor", JSON.stringify(action.payload.tutor));
    },
    [updateTutor.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
      state.errorMsg = "Tutor update failed";
    },
    [getRequests.pending]: (state, action) => {
      state.loading = true;
    },
    [getRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.requests;
    },
    [getRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getApprovedRequests.pending]: (state, action) => {
      state.loading = true;
    },
    [getApprovedRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.requests;
    },
    [getApprovedRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getRejectedRequests.pending]: (state, action) => {
      state.loading = true;
    },
    [getRejectedRequests.fulfilled]: (state, action) => {
      state.loading = false;
      state.requests = action.payload.requests;
    },
    [getRejectedRequests.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [approveRequest.pending]: (state, action) => {
      state.loading = true;
    },
    [approveRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.successMsg = "Request approved successfully";
    },
    [approveRequest.rejected]: (state, action) => {
      state.loading = false;
      state.errorMsg = "Request approval failed"
    },
    [rejectRequest.pending]: (state, action) => {
      state.loading = true;
    },
    [rejectRequest.fulfilled]: (state, action) => {
      state.loading = false;
      state.successMsg = "Request rejected successfully";
    },
    [rejectRequest.rejected]: (state, action) => {
      state.loading = false;
      state.errorMsg = "Request rejection failed"
    },
    [getStudents.pending]: (state, action) => {
        state.loading = true;
    },
    [getStudents.fulfilled]: (state, action) => {
        state.loading = false;
        state.students = action.payload;
        state.successMsg = "Students fetched successfully";
    },
    [getStudents.rejected]: (state, action) => {
        state.loading = false;
        state.error = action.payload.msg;
    }
  },
});

let tutorReducer = tutorSlice.reducer;
export let { clearTutor, setTutor } = tutorSlice.actions;
export default tutorReducer;
