import {
    Box,
    Typography,
    Button,
    Card,
    CardContent,
    Grid,
    Avatar,
    Snackbar,
  } from "@mui/material";
  import React from "react";
  import dayjs from "dayjs";
  import { Home, Laptop } from "@mui/icons-material";
  import Loading from "../../components/loading/loading"
  import { useLocation } from "react-router-dom";
  import {
    clearSession,
    getStudentSessions,
    cancelSession,
  } from "../../state/slices/session";
  import { useDispatch, useSelector } from "react-redux";
  
  
  const Student_Detail_class_room = () => {
    const location = useLocation();
    const tutor = location.state.tutor;
    console.log(tutor);
    const { sessions, loading, error, success , session } = useSelector(
      (state) => state.session
    );

    const [meetings, setMeetings] = React.useState([]);

    const dispatch = useDispatch();
    const [message, setMessage] = React.useState("");
    const [openSnackbar, setOpenSnackbar] = React.useState(false);

    const handleSessionsFetch = () => {
      dispatch(getStudentSessions(tutor.tutor.id));
    };

    if (session){
      handleSessionsFetch();
      dispatch(clearSession());
  
    }


    React.useEffect(() => {
      
        handleSessionsFetch();
      
    }, [ dispatch]);


    React.useEffect(() => {
      if (success) {
        setMeetings(sessions);
        dispatch(clearSession());
      }
    }, [success, sessions, dispatch]);

  
    return (
      <>
      <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      open={openSnackbar}
      autoHideDuration={3000}
      onClose={() => {
        setOpenSnackbar(false);
      }}
      message={
        <Typography fontWeight={600} fontSize={20} color="secondary">
          {error ? error : message}
        </Typography>
      }
    />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          p: 2,
        }}
      >

<Card
          sx={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            alignSelf: "center",
            boxShadow: 24,
          }}
        >
          <Typography
            variant="h4"
            color={"primary"}
            sx={{
              textAlign: "center",
              margin: 2,
            }}
          >
            {tutor.tutor.firstName} {tutor.tutor.lastName} Class Room
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              mt: 2,
            }}
          >
            <Avatar
              alt={tutor.tutor.firstName + " " + tutor.tutor.lastName}
              src={tutor.tutor.profileImage}
              sx={{ width: 100, height: 100,margin:2 }}
              
            />
          </Box>

        </Card>
        
  
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            p: 2,
          }}
        >
          {loading ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "60vh",
              }}
            >
              <Loading />
            </Box>
          ) : (
            <>
            {meetings.map((meeting) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={meeting._id}>
                <Card
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    borderRadius: 5,
                    boxShadow: 24,
                    bgcolor: "background.paper",
                  }}
                >
                  <CardContent>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Typography
                        color="primary"
                        fontWeight={600}
                        mr={1}
                        variant="h5"
                        component="h5"
                      >
                        Date:
                      </Typography>
                      <Typography variant="h6" component="h6">
                        {dayjs(meeting.startingTime).format("DD/MM/YYYY")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Typography
                        color="primary"
                        fontWeight={600}
                        mr={1}
                        variant="h5"
                        component="h5"
                      >
                        Time:
                      </Typography>
                      <Typography variant="h6" component="h6">
                        {dayjs(meeting.startingTime).format("hh:mm a")} -{" "}
                        {dayjs(meeting.endingTime).format("hh:mm a")}
                      </Typography>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Typography
                        color="primary"
                        fontWeight={600}
                        mr={1}
                        variant="h5"
                        component={"h5"}
                      >
                        Tutoring Type:
                      </Typography>
                      <Typography variant="h6" component="h6">
                        {meeting.tutoringType === "REMOTE" ? (
                          <>
                            <Laptop
                              style={{
                                verticalAlign: "middle",
                                marginRight: "8px",
                              }}
                            />{" "}
                            online
                          </>
                        ) : (
                          <>
                            <Home
                              style={{
                                verticalAlign: "middle",
                                marginRight: "8px",
                              }}
                            />{" "}
                            in person
                          </>
                        )}
                      </Typography>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "baseline" }}>
                      <Typography
                        color="primary"
                        fontWeight={600}
                        mr={1}
                        variant="h5"
                        component="h5"
                      >
                        Note:
                      </Typography>
                      <Typography variant="h6" component="h6">
                        {meeting.note}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      {meeting.tutoringType === "REMOTE" && (
                        <Button
                          disabled={
                            meeting.startingTime > dayjs() ||
                            meeting.endingTime < dayjs()
                          }
                          onClick={() => {
                            window.open(meeting.sessionLink);
                          }}
                          variant="contained"
                          color="primary"
                          sx={{
                            margin: 2,
                            width: 150,
                            height: 40,
                            borderRadius: 5,
                            boxShadow: 24,
                            bgcolor: "primary.main",
                            color: "white",
                            "&:hover": {
                              backgroundColor: "primary.dark",
                            },
                          }}
                        >
                          <Typography variant="body1" component="p">
                            Join Session
                          </Typography>
                        </Button>
                      )}

                      <Button
                        disabled={meeting.startingTime < dayjs()}
                        onClick={() => {
                          dispatch(cancelSession(meeting._id));
                          setMessage("Cancel session successfully");
                          setOpenSnackbar(true);
                          handleSessionsFetch();
                        }}
                        variant="contained"
                        color="error"
                        sx={{
                          margin: 2,
                          width: 100,
                          height: 40,
                          borderRadius: 5,
                          boxShadow: 24,
                          bgcolor: "error.main",
                          color: "white",
                          "&:hover": {
                            backgroundColor: "error.dark",
                          },
                        }}
                      >
                        <Typography variant="body1" component="p">
                          Cancel
                        </Typography>
                      </Button>
                    </Box>
                  </CardContent>
                </Card>
              </Grid>
            ))}
            {meetings.length === 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  p: 2,
                  minHeight: "50vh",
                }}
              >
                <Typography variant="h1" component="h1">
                  No session
                </Typography>
              </Box>
            )}
          </>
        )}
        </Grid>
      </Box>
      </>
    );
  };
  
  export default Student_Detail_class_room;
  
