import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { allCollectionsAPI , 
    allApprovedRequestsAPI , 
    allApprovedTutorsAPI , 
    allPendingRequestsAPI ,
    allPendingTutorsAPI ,
    allRejectedRequestsAPI ,
    allRejectedTutorsAPI,
    approveTutorAPI,
    rejectTutorAPI
} from "../../services/admin";

let initialState = {
    loading: false,
    error: null,
    successMsg: '',
    errorMsg: '',
    collections: [],
    approvedRequests: [],
    pendingRequests: [],
    rejectedRequests: [],
    approvedTutors: [],
    pendingTutors: [],
    rejectedTutors: []

}

export let allCollections = createAsyncThunk(
    'admin/allCollections',
    async (data, thunkApi) => {
        try {
            let response = await allCollectionsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let allApprovedRequests = createAsyncThunk(
    'admin/allApprovedRequests',
    async (data, thunkApi) => {
        try {
            let response = await allApprovedRequestsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let allPendingRequests = createAsyncThunk(
    'admin/allPendingRequests',
    async (data, thunkApi) => {
        try {
            let response = await allPendingRequestsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let allRejectedRequests = createAsyncThunk(
    'admin/allRejectedRequests',
    async (data, thunkApi) => {
        try {
            let response = await allRejectedRequestsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let allApprovedTutors = createAsyncThunk(
    'admin/allApprovedTutors',
    async (data, thunkApi) => {
        try {
            let response = await allApprovedTutorsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let allPendingTutors = createAsyncThunk(
    'admin/allPendingTutors',
    async (data, thunkApi) => {
        try {
            let response = await allPendingTutorsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }
)

export let allRejectedTutors = createAsyncThunk(
    'admin/allRejectedTutors',
    async (data, thunkApi) => {
        try {
            let response = await allRejectedTutorsAPI()
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message);
        }
    }

)

export let approveTutor = createAsyncThunk(
    'admin/approveTutor',
    async (id, thunkApi) => {
        try {
            let response = await approveTutorAPI(id)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message)
        }
    }
)

export let rejectTutor = createAsyncThunk(
    'admin/rejectTutor',
    async (id, thunkApi) => {
        try {
            let response = await rejectTutorAPI(id)
            return response.data
        } catch (error) {
            let errorMsg = error.response.data?.msg;
            return thunkApi.rejectWithValue(errorMsg ? errorMsg : error.message)
        }
    }
)


let adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        clearAdmin: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
        }
    },
    extraReducers: {
        [allCollections.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.collections = []
        },
        [allCollections.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.collections = action.payload.collections
        },
        [allCollections.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.collections = []
        },
        [allApprovedRequests.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.approvedRequests = []
        },
        [allApprovedRequests.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.approvedRequests = action.payload.requests
        },
        [allApprovedRequests.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.approvedRequests = []
        },
        [allPendingRequests.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.pendingRequests = []
        },
        [allPendingRequests.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.pendingRequests = action.payload.requests
        },
        [allPendingRequests.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.pendingRequests = []
        },
        [allRejectedRequests.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.rejectedRequests = []
        },
        [allRejectedRequests.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.rejectedRequests = action.payload.requests
        },
        [allRejectedRequests.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.rejectedRequests = []
        },
        [allApprovedTutors.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.approvedTutors = []
        },
        [allApprovedTutors.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.approvedTutors = action.payload.tutors
        },
        [allApprovedTutors.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.approvedTutors = []
        },
        [allPendingTutors.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.pendingTutors = []
        },
        [allPendingTutors.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.pendingTutors = action.payload.tutors
        },
        [allPendingTutors.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.pendingTutors = []
        },
        [allRejectedTutors.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
            state.rejectedTutors = []
        },
        [allRejectedTutors.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = action.payload.msg
            state.errorMsg = ''
            state.rejectedTutors = action.payload.tutors
        },
        [allRejectedTutors.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = action.payload
            state.rejectedTutors = []
        },
        [approveTutor.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
        },
        [approveTutor.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = "Tutor approved successfully"
            state.errorMsg = ''
        },
        [approveTutor.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = "Failed"
        },
        [rejectTutor.pending]: (state, action) => {
            state.loading = true
            state.error = null
            state.successMsg = ''
            state.errorMsg = ''
        },
        [rejectTutor.fulfilled]: (state, action) => {
            state.loading = false
            state.error = null
            state.successMsg = "Tutor Reject successfully"
            state.errorMsg = ''
        },
        [rejectTutor.rejected]: (state, action) => {
            state.loading = false
            state.error = action.payload
            state.successMsg = ''
            state.errorMsg = "Failed"
        }
    }
})

export let { clearAdmin } = adminSlice.actions

export default adminSlice.reducer