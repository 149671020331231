import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Alert,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Snackbar,
  Stack,
  TextField,
  Typography,
  useTheme,
  Avatar,
  Badge,
  Icon,
  IconButton,
} from "@mui/material";
import { Edit, Person } from "@mui/icons-material";
import { clearUserState, updateProfile, logout } from "../../state/slices/user";
import { Switch } from "@mui/material";
import UpdatePasswordForm from "./UpdatePassword";
import { clearTutor, getTutorByUser, setTutor } from "../../state/slices/tutor";
import { updateContentType } from "../../config/config";
import { extractId } from "../../utils/imageIdExtractor";

function Profile() {
  const theme = useTheme();
  const userState = useSelector((state) => state.user).user;
  let { loading, successMsg } = useSelector((state) => state.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [submitStatus, setSubmitStatus] = useState("");
  const [formData, setFormData] = useState({
    firstName: userState.firstName,
    lastName: userState.lastName,
    email: userState.email,
    phoneNumber: userState.phoneNumber,
    roles: userState.roles,
    profileImage: userState.profileImage ? userState.profileImage : "",
  });
  const [errors, setErrors] = useState({});
  const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const validationErrors = { ...errors };

    switch (fieldName) {
      case "firstName":
        validationErrors.firstName = value.trim()
          ? String(value).length < 3
            ? "First name too short"
            : ""
          : "First name is required";
        break;
      case "lastName":
        validationErrors.lastName = value.trim()
          ? String(value).length < 3
            ? "Last name too short"
            : ""
          : "Last name is required";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        validationErrors.email =
          value.trim() && emailRegex.test(value) ? "" : "Invalid email address";
        break;
      case "phoneNumber":
        const phoneRegex = /^09\d{8}$/;
        validationErrors.phoneNumber =
          value.trim() && phoneRegex.test(value) ? "" : "Invalid phone number";
        break;
      case "password":
      default:
        break;
    }

    setErrors((prev) => {
      return { ...prev, ...validationErrors };
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    Object.keys(formData).forEach((fieldName) => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName]) {
        validationErrors[fieldName] = errors[fieldName];
      }
    });

    setSubmitStatus((prev) => prev + "x");
  };

  const tutorProfile = () => {
    if (userState.roles.includes("TUTOR")) {
      dispatch(getTutorByUser(userState.id));
      navigate("/tutor-profile");
      dispatch(clearTutor());
    }
  };

  const logoutUser = () => {
    dispatch(clearUserState());
    dispatch(setTutor(null));
    dispatch(logout());
    navigate("/login");
  };

  useEffect(() => {
    if (
      submitStatus &&
      Object.keys(errors).filter((key) => errors[key]).length === 0
    ) {
      const data = new FormData();
      data.set("firstName", formData.firstName);
      data.set("lastName", formData.lastName);
      data.set("email", formData.email);
      data.set("phoneNumber", formData.phoneNumber);

      if (image) {
        data.set("profileImage", image);
      }

      console.log("data", data.values());

      updateContentType("multipart/form-data");
      dispatch(updateProfile(data));
      updateContentType("application/json");


      setSnackbarOpen(true);
      console.log("Form submitted:", formData);
    } else {
      dispatch(clearUserState());
    }
  }, [submitStatus]);

  const [image, setImage] = useState(null);
  // const [selectedImage, setSelectedImage] = useState(userState ? userState.profileImage?.replace('&export=download','') : null);
  const [selectedImage, setSelectedImage] = useState(userState ? userState.profileImage : null);


  const handleImageUpload = () => {
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "image/*";
    input.onchange = handleFileInputChange;
    input.click();
  };

  const handleFileInputChange = (event) => {
    const file = event.target.files[0];
    if (file.size > 2000000) {
      alert("File is too big!");
      return;
    }

    setImage(file);
    setSelectedImage(URL.createObjectURL(file));
    handleSubmit(event);
  };

  console.log("selectedImage", selectedImage)
  return (
    <Box
      sx={{
        minHeight: "90vh",
      }}
    >
      {!loading && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={
            <Typography fontWeight={600} fontSize={20} color="secondary">
              {successMsg
                ? "Profile updated successfully"
                : "Profile not updated"}
            </Typography>
          }
        />
      )}
      <Card
        elevation={10}
        sx={(theme) => ({
          borderRadius: 4,
          [theme.breakpoints.up("md")]: {
            p: 5,
            mx: 10,
            mt: 3,
            mb: 3,
          },
          [theme.breakpoints.up("xs")]: {
            p: 2,
            mx: 2,
            mt: 3,
            mb: 3,
          },
        })}
      >
        <Grid container p={2} justifyContent="center">
          <Grid md={2} xs={6}></Grid>
          <Grid md={10} xs={6} sx={{ justifyContent: "flex-start" }}>
            <Box display="flex" justifyContent="right">
              <Stack direction="column" alignItems="right" rowGap={3}>
                {userState.errorMsg && (
                  <Alert severity="error">{userState.errorMsg}</Alert>
                )}
                <Button
                  size="large"
                  onClick={logoutUser}
                  disabled={userState.loading}
                  sx={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    textTransform: "capitalize",
                    "&:hover": {
                      background: theme.palette.secondary.dark,
                    },
                  }}
                  disableElevation
                  variant="contained"
                >
                  {userState.loading ? (
                    <CircularProgress size={25} />
                  ) : (
                    <Typography fontWeight={900}>Logout</Typography>
                  )}
                </Button>
              </Stack>
            </Box>
          </Grid>
        </Grid>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ mb: 3 }}
        >
          <Badge
            overlap="circular"
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            badgeContent={
              <IconButton
                size="small"
                sx={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.primary.main,
                }}
                aria-label="upload picture"
                component="span"
                onClick={handleImageUpload}
              >
                <Edit />
              </IconButton>
            }
          >
            {
              loading ? (
                <CircularProgress size={100} />
              ) : (
                <Avatar
                  alt="Remy Sharp"
                  sx={{
                    width: 200,
                    height: 200,
                  }}
                >
                  {selectedImage ? (
                    <img
                      crossOrigin="anonymous"
                      src={selectedImage.includes('drive.google.com') ? `https://drive.lienuc.com/uc?id=${extractId(selectedImage)}` : selectedImage}
                      alt="profile"
                      style={{ width: "100%", height: "100%", objectFit: "cover" }}
                    />
                  ) : (
                    <Person
                      sx={{
                        width: 200,
                        height: 200,
                      }}
                    />
                  )}
                </Avatar>
              )

            }
          </Badge>
        </Box>

        <Stack mx={{ lg: 25, md: 15, xs: 1 }} justifyContent="start" rowGap={3}>
          <Box display="flex" alignItems="center">
            {userState.roles.includes("TUTOR") && (
              <Box
                flexGrow={1}
                textAlign="right"
                style={{ cursor: "pointer" }}
                onClick={tutorProfile}
              >
                <Button fontWeight={900} ml={1}>
                  <Typography sx={{ textTransform: 'capitalize' }}>
                    {userState.roles.includes("TUTOR") ? "Tutor" : "Student"}{" "}
                    detail profile
                  </Typography>
                </Button>
              </Box>
            )}
          </Box>
          <TextField
            label="First name"
            variant="outlined"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
          />
          <TextField
            label="Last name"
            variant="outlined"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
          />
          <TextField
            label="Email"
            variant="outlined"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={!!errors.email}
            helperText={errors.email}
          />
          <TextField
            label="Phone number"
            variant="outlined"
            name="phoneNumber"
            placeholder="+2519XXXXXXXX"
            value={formData.phoneNumber}
            onChange={handleChange}
            error={!!errors.phoneNumber}
            helperText={errors.phoneNumber}
          />

          <Box display="flex" justifyContent="center">
            <Stack direction="column" alignItems="center" rowGap={3}>
              {userState.errorMsg && (
                <Alert severity="error">{userState.errorMsg}</Alert>
              )}
              <Button
                size="large"
                onClick={handleSubmit}
                disabled={userState.loading}
                sx={{
                  background: theme.palette.secondary.main,
                  color: theme.palette.primary.main,
                  textTransform: "capitalize",
                  minWidth: "150px",
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                  },
                }}
                disableElevation
                variant="contained"
              >
                {loading ? (
                  <CircularProgress size={25} />
                ) : (
                  <Typography fontWeight={900}>Update Profile</Typography>
                )}
              </Button>
            </Stack>
          </Box>
          <Box display="flex" alignItems="center">
            <Switch
              checked={passwordDialogOpen}
              onChange={() => setPasswordDialogOpen(!passwordDialogOpen)}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography fontWeight={900} ml={1}>
              Do you want to change your password?
            </Typography>
          </Box>
          {passwordDialogOpen && <UpdatePasswordForm />}
        </Stack>
      </Card>
    </Box>
  );
}

export default Profile;
