import { Facebook, Telegram, Twitter } from '@mui/icons-material';
import { Box, Button, Link, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

function WelcomePage() {
    const navigate = useNavigate();
  const theme = useTheme();
  const descriptionText = `Welcome to Astenge! Thank you for joining us. Start your learning journey with us and discover new opportunities for growth and development.`;
  const welcomeText = `Welcome to Astenge!`;
  const instructionText = `Welcome to Astenge.com! We’re excited to have you join our community of learners and tutors. Get started Today!`;
  
  return (
    <Box
      px={{ xs: 2, md: 5, lg: 10 }}
      py={10}
      sx={{
        minHeight: '100vh',
      }}
    >
      <Stack height={'100%'} rowGap={10} justifyContent={'center'} alignItems={'center'}>
        <Stack rowGap={4}>
          <Typography textAlign={'center'} variant="h4" color="primary" fontSize={60} fontWeight={600}>
            {welcomeText}
          </Typography>
          <Typography textAlign={'center'} fontSize={20} color="primary">
            {instructionText}
          </Typography>
        </Stack>

        <Stack direction={"row"} spacing={4}>
            <Button
                sx={{
                    background: theme.palette.primary.main,
                    color: theme.palette.secondary.main,
                    textTransform: 'capitalize',
                    borderRadius: 2,
                    px: 3,
                    "&:hover": {}
                }}
                onClick={() => navigate('/login')}
                >
                <Typography fontWeight={600} color={"secondary"} fontSize={20}>
                    Get Started
                </Typography>
                </Button>
                <Button
                sx={{
                    background: theme.palette.secondary.main,
                    color: theme.palette.primary.main,
                    textTransform: 'capitalize',
                    borderRadius: 2,
                    px: 3,
                    "&:hover": {}
                }}
                onClick={() => navigate('/')}
                >
                <Typography fontWeight={600} color={"primary"} fontSize={20}>
                    Home
                </Typography>
                </Button>
        </Stack>

        <Stack direction="row" spacing={4}>
          <Link
            href={`https://www.facebook.com/sharer/sharer.php?u=https://www.astenge.com/&quote=${descriptionText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Facebook fontSize="large" />
          </Link>
          <Link
            href={`https://twitter.com/intent/tweet?url=https://www.astenge.com/&text=${descriptionText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter fontSize="large" />
          </Link>
          <Link
            href={`https://telegram.me/share/url?url=https://www.astenge.com/&text=${descriptionText}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Telegram fontSize="large" />
          </Link>
        </Stack>
      </Stack>
    </Box>
  );
}

export default WelcomePage;
