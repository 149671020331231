import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addToWaitlistAPI } from "../../services/waitlist";
import { EARLY_ACCESS_CODE } from "../../config/constants";




let initialState = {
    accessGranted:localStorage.getItem('earlyAccessPassword') == EARLY_ACCESS_CODE ? true: false,
    errorMessage: '',
    successMessage:''
}

let earlyAcessSlice = createSlice(
    {
        name: 'earlyAcess',
        initialState,
        reducers: {
            activate: (state, action) => {
                if (action.payload.password == EARLY_ACCESS_CODE) {
                    state.accessGranted = true
                    state.successMessage='Access Granted'
                    state.errorMessage=''
                    localStorage.setItem('earlyAccessPassword',EARLY_ACCESS_CODE)
                } else {
                    state.errorMessage = 'Access Code Incorrect'
                    state.successMessage = ''
                }
            }
        },
    }
)


let earlyAcessRedcuer = earlyAcessSlice.reducer
export let { activate } = earlyAcessSlice.actions

export default earlyAcessRedcuer