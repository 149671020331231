import React from 'react'
import Footer from '../footer/footer'
import NavBar from '../navbar/navbar'

export default function Layout({ children }) {
    return (
        <div>
            <NavBar>
            </NavBar>
            {children}
            <Footer></Footer>
        </div>
    )
}
