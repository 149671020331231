import { Alert, Box, Button, Card, CircularProgress, Grid, Link, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/button/button'
import CenteredBox from '../../components/centeredBox/centeredBox'
import Loading from '../../components/loading/loading'
import { clearUserState, login } from '../../state/slices/user'
import { activate } from '../../state/slices/earylyAccess'

function EarlyAccess() {
    let theme = useTheme()
    let earlyAccessState = useSelector(state => state.earlyAccess)
    let navigate = useNavigate()
    let dispatch = useDispatch()

    let [submitStatus, setSubmitStatus] = useState('')

    const [formData, setFormData] = useState({
        accessCode: '',
    });

    const [errors, setErrors] = useState({});

    if (earlyAccessState.accessGranted) {
        dispatch(clearUserState())
        console.log(earlyAccessState)
        navigate('/')
    }


    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        // Perform validation on the individual field as the user types
        validateField(e.target.name, e.target.value);
    };

    const validateField = (fieldName, value) => {
        const validationErrors = { ...errors };

        setErrors((prev) => { return { ...prev, ...validationErrors } });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        dispatch(activate({ password: formData.accessCode }))


    };

    useEffect(() => {

    }, [])

    return (
        <Box
            sx={{
                // mb: 3
            }}>

            <Card
                elevation={10}
                sx={theme => ({
                    borderRadius: 0,
                    minHeight: '100vh',
                    [theme.breakpoints.up('md')]: {
                        p: 0,
                        // mx: 10,
                        // mt: 3,
                    },
                    [theme.breakpoints.up('xs')]: {
                        // p: 2,
                        // mx: 2,
                        // mt: 3
                    },
                    display: 'flex', // Added to enable flex layout
                    alignItems: 'center', // Added to vertically center the content
                })}
            >
                {/* Left Image */}
                <Box
                    sx={{
                        // if it is mobile, then don't show the image
                        display: { xs: 'none', md: 'flex' },
                        width: { xs: 'none', md: '50%' }, // Adjust the width as needed
                        justifyContent: 'center',
                        alignItems:'center',
                        height: "100vh",
                        overflow: 'hidden',
                        background: 'url(/STUDENT.jpeg)',
                        backgroundSize: 'cover',
                        background: "linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(STUDENT.jpeg)"
                    }}
                >
                    <Link
                        to="/"
                        style={{
                            textDecoration: 'none',
                            color: 'white',
                        }}
                    >
                        <Stack direction={'column'} alignItems='center'>
                            <img style={{ height: '100px' }} src='/white_text-modified.png' alt='logo'></img>
                            <Box height={20}></Box>
                            <Typography color="white" variant="h4">
                                Astenge.com
                            </Typography>
                        </Stack>
                    </Link>
                    {/* <img src="/STUDENT.jpeg" alt="Left Image" style={{ Width: '100%', Height: '100%', objectFit: "cover" }} /> */}
                </Box>

                {/* Right Content */}
                <Box
                    sx={{
                        width: { xs: '100%', md: '50%' }, // Adjust the width as needed
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        // padding: '0 16px', // Added some padding for better spaci
                    }}
                >
                    <Box
                        sx={{
                            color: theme.palette.primary.main,
                            borderRadius: 2,
                            //   mb: 5,

                        }} >
                        <Typography fontWeight={900} fontSize={40} textAlign={'center'}>
                            Early Access
                        </Typography>
                    </Box>
                    <Box sx={{
                        mb: 3
                    }}>
                        <Typography textAlign={'center'} fontWeight={600} fontSize={20} color='secondary'>
                            Enter the early access code to acess the website
                        </Typography>
                    </Box>


                    <Stack mx={{ lg: 15, md: 15, xs: 1 }} justifyContent='start' rowGap={3}>
                        <TextField
                            label='Access Code'
                            variant='outlined'
                            name='accessCode'
                            value={formData.accessCode}
                            onChange={handleChange}
                            error={!!errors.accessCode}
                            helperText={errors.accessCode}
                        />
                        <CenteredBox >
                            <Stack direction={'column'} justifyContent='center' rowGap={2}>
                                {
                                    earlyAccessState.errorMessage && <Alert severity="error">{earlyAccessState.errorMessage}</Alert>
                                }
                                <Button
                                    onClick={handleSubmit}
                                    disabled={earlyAccessState.loading}
                                    sx={{
                                        background: theme.palette.secondary.main,
                                        minWidth: '120px',
                                        color: theme.palette.primary.main,
                                        textTransform: 'capitalize',
                                        "&:hover": {
                                            background: theme.palette.secondary.dark,
                                        }
                                    }} disableElevation variant='contained'>
                                    {
                                        earlyAccessState.loading ? <CircularProgress size={25}></CircularProgress> :
                                            <Typography fontWeight={600}>
                                                Enter
                                            </Typography>
                                    }
                                </Button>
                            </Stack>
                        </CenteredBox>
                        <Stack justifyContent='end' >
                            {/* <Typography textAlign="end" fontWeight={600} fontSize={14} color='primary' onClick={() => navigate("/forgot-password")}>
                Don't have an account?
              </Typography> */}
                            <Box height={20}></Box>
                            <Link sx={{ textDecoration: 'none', cursor: 'pointer' }} href='mailto:astengeapp@gmail.com' >
                                <Typography textAlign="center" fontWeight={600} fontSize={18} color='primary'>
                                    Thanks for visiting Astenge.com, if you want early access to the site please email us at astengeapp@gmail.com
                                </Typography>
                            </Link>
                        </Stack>
                    </Stack>
                </Box>
            </Card >

        </Box >
    )
}

export default EarlyAccess
