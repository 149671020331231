import { Alert, Box, Button, Card, CircularProgress, Grid, Snackbar, Stack, TextField, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import PrimaryButton from '../../components/button/button'
import CenteredBox from '../../components/centeredBox/centeredBox'
import Loading from '../../components/loading/loading'
import { clearUserState, updatePassword } from '../../state/slices/user'
import validatePassword from '../../utils/passwordValidator'

function UpdatePasswordForm() {
  let theme = useTheme()
  let userState = useSelector(state => state.user)
  let navigate = useNavigate()
  let dispatch = useDispatch()

  let [submitStatus, setSubmitStatus] = useState('')
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const [formData, setFormData] = useState({
    oldPassword: '',
    newPassword: '',
    confirmNewPassword: '',
  });

  const [errors, setErrors] = useState({});


  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });

    validateField(e.target.name, e.target.value);
  };

  const validateField = (fieldName, value) => {
    const validationErrors = { ...errors };

    switch (fieldName) {
      case 'oldPassword':
        validationErrors.oldPassword = value.trim() ? '' : 'Current password is required';
        break;
      case 'newPassword':
        validationErrors.newPassword = value.trim() ? validatePassword(String(value)): 'New password is required';
        break;
      case 'confirmNewPassword':
        validationErrors.confirmNewPassword = value.trim() ? value === formData.newPassword ? '' : 'Passwords do not match' : 'Confirmation is required';
        break;
      default:
        break;
    }

    setErrors((prev) => { return { ...prev, ...validationErrors } });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const validationErrors = {};

    Object.keys(formData).forEach((fieldName) => {
      validateField(fieldName, formData[fieldName]);
      if (errors[fieldName]) {
        validationErrors[fieldName] = errors[fieldName];
      }
    });

    setSubmitStatus(prev => prev + 'x')

  };

  useEffect(() => {
    if (submitStatus && Object.keys(errors).filter(key => errors[key]).length === 0) {
      // send the form data to the server without the confirmnewpassword field
      dispatch(updatePassword({oldPassword: formData.oldPassword, newPassword: formData.newPassword}))

      console.log('Form submitted:', formData);
      setSnackbarOpen(true)
    } else {
      dispatch(clearUserState())
    }
  }, [submitStatus])

  return (
    <>
    { !userState.loading &&
      <Snackbar
        anchorOrigin={{ vertical: 'botton', horizontal: 'left' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
        message= {<Typography fontWeight={600} fontSize={20} color='secondary'>{
          userState.successMsg ? "Password updated successfully" : userState.errorMsg
        }</Typography>}
      />
  }
    
  <Stack mx={{ lg: 25, md: 15, xs: 1 }} justifyContent='start' rowGap={3}>
          <TextField
            label='Current Password'
            variant='outlined'
            name='oldPassword'
            type='password'
            value={formData.oldPassword}
            onChange={handleChange}
            error={!!errors.oldPassword}
            helperText={errors.oldPassword}
          />
          <TextField
            label='New Password'
            variant='outlined'
            name='newPassword'
            type='password'
            value={formData.newPassword}
            onChange={handleChange}
            error={!!errors.newPassword}
            helperText={errors.newPassword}
          />
          <TextField
            label='Confirm New Password'
            variant='outlined'
            name='confirmNewPassword'
            type='password'
            value={formData.confirmNewPassword}
            onChange={handleChange}
            error={!!errors.confirmNewPassword}
            helperText={errors.confirmNewPassword ? errors.confirmNewPassword :formData.confirmNewPassword ?"Password matched":"" }
            FormHelperTextProps={{
              sx: {
                color: 'green',
              }
            }}
          />
          <CenteredBox >
            <Stack direction={'column'} justifyContent='center' rowGap={2}>
              {
                userState.errorMsg && <Alert severity="error">{userState.errorMsg}</Alert>
              }
              <Button
                onClick={handleSubmit}
                disabled={userState.loading}
                sx={{
                  background: theme.palette.secondary.main,
                  minWidth: '120px',
                  color: theme.palette.primary.main,
                  textTransform: 'capitalize',
                  "&:hover": {
                    background: theme.palette.secondary.dark,
                  }
                }} disableElevation variant='contained'>
                {
                  userState.loading ? <CircularProgress size={25}></CircularProgress> :
                    <Typography fontWeight={600}>
                      Update Password
                    </Typography>
                }
              </Button>
            </Stack>
          </CenteredBox>
        </Stack>
    </>
  )
}

export default UpdatePasswordForm;
