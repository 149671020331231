import React from "react";

import {
  Card,
  Grid,
  Avatar,
  Typography,
  Box,
  Button,
  Snackbar,
  Alert,
  Modal,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import Loading from "../../components/loading/loading";
import { useSelector, useDispatch } from "react-redux";
import { approveRequest, rejectRequest, clearTutor } from "../../state/slices/tutor";
import { useNavigate } from "react-router-dom";


const val = {
  0: "Pending",
  1: "Approved",
  2: "Rejected",
};

const CustomCard = ({ requests, theme2, value }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {loading , successMsg , errorMsg} = useSelector((state) => state.tutor);
  console.log(successMsg , errorMsg)
  const [modal, setModal] = React.useState(false);
  const [selecteRequest, setSelecteRequest] = React.useState('');

  const handleModalClose = () => {
    setModal(false);
  };
  const handleCardClick = (event,request) => {
    // Check if the click originated from the "add Review" button
    if (event.target.tagName === 'BUTTON' && (event.target.textContent === 'Approve' || event.target.textContent === 'Reject')) {
      return;
    }

    // Otherwise, set the modal state to true
    setSelecteRequest(request)
    setModal(true);
  };
  const ModalPage = ({ modal, handleModalClose }) => {
    let request = selecteRequest
    return (
      <Modal open={modal} onClose={handleModalClose}>
        <Box
          sx={{
            p: 3,
            backgroundColor: "#f5f5f5",
            borderRadius: 4,
            maxWidth: 600,
            mx: "auto",
            mt: "10vh",
            maxHeight: "80vh",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              p: 2,
            }}
          >
            <Avatar
              sx={{
                width: 100,
                height: 100,
                backgroundColor: theme2.palette.primary.main,
              }}
            >
              {request.studentImageUrl ? (
                <img
                  src={request.studentImageUrl}
                  alt="student"
                  width="100%"
                  height="100%"
                />
              ) : (
                <Person sx={{ width: 100, height: 100 }} />
              )}
            </Avatar>
          </Box>
          <Box sx={{ p: 2 }}>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Student Name
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.studentName}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Tutor Name
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.tutorName}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Tutoring Type
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
            {request.tutoringType?.includes("REMOTE") && 'Online'}
              {request.tutoringType?.includes("IN_PERSON") && ' In Person'}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Starting Time
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.startingTime}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Message
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.message}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Admin Status
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.adminApproved}
            </Typography>
            <Typography color={"primary"} fontSize={30} fontWeight={600}>
              Tutor Status
            </Typography>
            <Typography color={"primary"} fontSize={20} fontWeight={400}>
              {request.status}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              variant="outlined"
              sx={{ mt: 2, ml: 2 }}
              color="primary"
              onClick={() => {
                setModal(false);
              }}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  
  return (
    <Box>
     


      <Box
        sx={{
          marginTop: 5,
          marginBottom: 5,
          marginLeft: 5,
          marginRight: 5,
          minHeight: "80vh",
        }}
      >
        {loading || successMsg || errorMsg ? (
          <Loading />
        ) : requests.length === 0 ? (
          <Typography
            variant="h4"
            color="primary"
            sx={{ textAlign: "center" }}
            fontSize={50}
            fontWeight={600}
          >
            No {val[value]} Requests
          </Typography>
        ) : (
          <Grid container>
            {requests.map((request) => (
              <>
                <ModalPage
                  request={request}
                  modal={modal}
                  handleModalClose={handleModalClose}
                />
                <Grid item xs={12} sm={6} md={4} key={request._id}>
                  <Card
                    onClick={(e) => {
                      handleCardClick(e,request)
                      // setSelecteRequest(request)
                      // setModal(true);
                    }}
                    sx={{
                      marginTop: 5,
                      marginBottom: 5,
                      marginLeft: 5,
                      marginRight: 5,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        p: 2,
                      }}
                    >
                      <Avatar
                        sx={{
                          width: 100,
                          height: 100,
                          backgroundColor: theme2.palette.primary.main,
                        }}
                      >
                        {request.studentImageUrl ? (
                          <img
                            src={request.studentImageUrl}
                            alt="student"
                            width="100%"
                            height="100%"
                          />
                        ) : (
                          <Person sx={{ width: 100, height: 100 }} />
                        )}
                      </Avatar>
                    </Box>
                    <Box sx={{ p: 2 }}>
                      <Typography
                        color={"primary"}
                        fontSize={30}
                        fontWeight={600}
                      >
                        Student Name
                      </Typography>
                      <Typography
                        color={"primary"}
                        fontSize={20}
                        fontWeight={400}
                      >
                        {request.studentName}
                      </Typography>
                      <Typography
                        color={"primary"}
                        fontSize={30}
                        fontWeight={600}
                      >
                        Date
                      </Typography>
                      <Typography
                        color={"primary"}
                        fontSize={20}
                        fontWeight={400}
                      >
                        {new Date(request.date).toLocaleString(undefined,{ weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' })}
                      </Typography>

                      {value === 0 && (
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <Typography
                            color={"primary"}
                            fontSize={30}
                            fontWeight={600}
                          >
                            Actions
                          </Typography>
                          <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 2 }}
                            color="success"
                            onClick={() => {
                              dispatch(approveRequest(request.requestId));
                            }}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            sx={{ mt: 2, ml: 2 }}
                            color="error"
                            onClick={() => {
                              dispatch(rejectRequest(request.requestId));
                              }}
                          >
                            Reject
                          </Button>
                        </Box>
                      )}
                    </Box>
                  </Card>
                </Grid>
              </>
            ))}
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default CustomCard;
