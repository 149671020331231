import React from 'react'
import { ClipLoader } from 'react-spinners'
import CenteredBox from '../centeredBox/centeredBox'

function Loading() {
    return (
        <CenteredBox>
            <ClipLoader
                color={'primary'}
                loading={true}
                size={150}
                aria-label="Loading Spinner"
                data-testid="loader"
            />
        </CenteredBox >
    )
}

export default Loading