import axios from "axios"

// export const BASE_URL = 'http://localhost:3500'
// export const BASE_URL = 'http://www.abete.com'
// export const BASE_URL = 'https://astenge-test-api.onrender.com'
// export const BASE_URL = 'https://astegni-api.onrender.com'
// export const BASE_URL = 'http://localhost:3500'
export const BASE_URL = 'https:///astegneapi-aad1391d28b4.herokuapp.com'

export const LOCATION_API_TOKEN = 'pk.87daf97862b88928ef4c0d99d7b61897'

let token = localStorage.getItem('token')

export let clientInstance = axios.create({
    headers: {
        "Content-Type": "application/json",
        "Authorization": token ? `Bearer ${token}` : "",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    },
    baseURL: BASE_URL
})

export let updateToken = (token) => {
    clientInstance.defaults.headers = {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${token}`,
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    }
}

export let updateContentType = (contentType) => {
    let token = localStorage.getItem('token')
    console.log(contentType)
    clientInstance.defaults.headers = {
        "Content-Type": contentType,
        "Authorization": token ? `Bearer ${token}` : "",
        // "Access-Control-Allow-Origin": "*",
        // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
    }
}

